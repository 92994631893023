import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import {
  GET_ZOHO_OWNERS,
  SAVE_NEW_ZOHO_COMPANY,
  SEARCH_ZOHO_COMPANY,
  CONNECT_EXISTING_ZOHO_COMPANY,
  CONNECT_ZOHO_CONTACTS,
} from '../../constants/ActionTypes';

import {
  getZohoOwnersError,
  getZohoOwnersSuccess,
  saveNewZohoCompanyError,
  saveNewZohoCompanySuccess,
  searchZohoCompanyError,
  searchZohoCompanySuccess,
  connectExistingZohoCompanyError,
  connectExistingZohoCompanySuccess,
  connectZohoContactsError,
  connectZohoContactsSuccess,
} from '../actions/Zoho';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { updateCountLeads, updateCurrentPage } from '../actions/Leads';

const getZohoOwnersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/integrations/zoho/owner?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const searchZohoCompanyRequest = async (websiteId, searchTerm) =>
  await axios
    .get(`${baseUrl}/admin/integrations/zoho/company?websiteId=${websiteId}&searchTerm=${searchTerm}`)
    .then((response) => response)
    .catch((error) => error);

const saveNewZohoCompanyRequest = async (websiteId, domain, ownerId, leadId, name) =>
  await axios
    .post(`${baseUrl}/admin/integrations/zoho/company`, {
      websiteId,
      domain,
      ownerId,
      leadId,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const connectZohoContactsRequest = async (websiteId, ownerId, email) =>
  await axios
    .post(`${baseUrl}/admin/integrations/zoho/contact`, {
      websiteId,
      ownerId,
      email,
    })
    .then((response) => response)
    .catch((error) => error);

const connectExistingZohoCompanyRequest = async (websiteId, zohoCompanyId, leadId) =>
  await axios
    .post(`${baseUrl}/admin/integrations/zoho/connect`, {
      websiteId,
      zohoCompanyId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

function* getOwners({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getZohoOwnersRequest, websiteId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getZohoOwnersError());
        // yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(getZohoOwnersError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getZohoOwnersSuccess(callResponse.data.data));
      // yield put(generalSuccessMessage(callResponse.data));
    }
  } catch (error) {
    yield put(getZohoOwnersError(error));
  }
}

function* searchZohoCompany({ payload }) {
  const { websiteId, searchTerm } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(searchZohoCompanyRequest, websiteId, searchTerm);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(searchZohoCompanyError());
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(searchZohoCompanyError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(searchZohoCompanySuccess(callResponse.data));
    }
  } catch (error) {
    yield put(searchZohoCompanyError(error));
  }
}

function* saveNewZohoCompany({ payload }) {
  const { websiteId, domain, ownerId, leadId, name } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveNewZohoCompanyRequest, websiteId, domain, ownerId, leadId, name);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(saveNewZohoCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(saveNewZohoCompanyError());
      }
    } else {
      yield put(saveNewZohoCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company save Failed...'));
    yield put(saveNewZohoCompanyError());
  }
}

function* connectZohoContacts({ payload }) {
  const { websiteId, ownerId, email } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectZohoContactsRequest, websiteId, ownerId, email);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectZohoContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectZohoContactsError());
      }
    } else {
      yield put(connectZohoContactsSuccess());
      yield put(generalSuccessMessage('Contact saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(connectZohoContactsError());
  }
}

function* connectExistingZohoCompany({ payload }) {
  const { websiteId, zohoCompanyId, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectExistingZohoCompanyRequest, websiteId, zohoCompanyId, leadId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectExistingZohoCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectExistingZohoCompanyError());
      }
    } else {
      yield put(connectExistingZohoCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company connected successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company connection Failed...'));
    yield put(connectExistingZohoCompanyError());
  }
}

export function* fetchZohoOwners() {
  yield takeEvery(GET_ZOHO_OWNERS, getOwners);
}

export function* postSaveNewCompany() {
  yield takeEvery(SAVE_NEW_ZOHO_COMPANY, saveNewZohoCompany);
}

export function* postConnectContacts() {
  yield takeEvery(CONNECT_ZOHO_CONTACTS, connectZohoContacts);
}

export function* postSearchCompany() {
  yield takeEvery(SEARCH_ZOHO_COMPANY, searchZohoCompany);
}

export function* postConnectExistingCompany() {
  yield takeEvery(CONNECT_EXISTING_ZOHO_COMPANY, connectExistingZohoCompany);
}

export default function* rootSaga() {
  yield all([
    fork(fetchZohoOwners),
    fork(postSaveNewCompany),
    fork(postConnectContacts),
    fork(postSearchCompany),
    fork(postConnectExistingCompany),
  ]);
}
