import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import {
  GET_MEMBERS,
  UPDATE_MEMBER_ROLE,
  DELETE_MEMBER,
  UPDATE_WEBSITES_MEMBERS,
} from '../../constants/ActionTypes'
import { userSignOut } from '../actions/Auth'
import {
  getMembersSuccess,
  membersError,
  updateCallSuccess,
  delete_member_success,
} from '../actions/Members'

const fetchRequestMembers = async (accountId) =>
  await axios
    .get(`${baseUrl}/admin/members?accountId=${accountId}`)
    .then((response) => response)
    .catch((error) => error)

const updateMemberRequest = async (userId, memberRole) =>
  await axios
    .put(`${baseUrl}/admin/members?userId=${userId}&role=${memberRole}`, {
      role: memberRole,
      userId,
    })
    .then((response) => response)
    .catch((error) => error)

const deleteMemberRequest = async (userId) =>
  await axios
    .delete(`${baseUrl}/admin/members?userId=${userId}`)
    .then((response) => response)
    .catch((error) => error)

const updateWebsitesRequestMembers = async (userId, websiteId) =>
  await axios.put(
    `${baseUrl}/admin/members/websites?userId=${userId}&websiteId=${websiteId}`,
    {
      websiteId,
      userId,
    }
  )

function* callMembers({ payload }) {
  const accountId = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(fetchRequestMembers, accountId)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(membersError(callResponse.response.data.status))
      } else {
        yield put(
          membersError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(getMembersSuccess(callResponse.data))
    }
  } catch (error) {
    yield put(membersError(error))
  }
}

function* membersUpdateRequest({ payload }) {
  const { userId, memberRole } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(updateMemberRequest, userId, memberRole)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(membersError(callResponse.response.data.status))
      } else {
        yield put(
          membersError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(updateCallSuccess())
    }
  } catch (error) {
    yield put(membersError(error))
  }
}

function* deleteMemberCall({ payload }) {
  const userId = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(deleteMemberRequest, userId)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(membersError(callResponse.response.data.status))
      } else {
        yield put(
          membersError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(delete_member_success(userId))
    }
  } catch (error) {
    yield put(membersError(error))
  }
}

function* membersUpdateWebsites({ payload }) {
  const { userId, websiteId } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(
      updateWebsitesRequestMembers,
      userId,
      websiteId
    )

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(membersError(callResponse.response.data.status))
      } else {
        yield put(
          membersError('Something went wrong please check your network')
        )
      }
    } else {
      //add what happens on success here
    }
  } catch (error) {
    yield put(membersError(error))
  }
}

export function* get_request_members() {
  yield takeEvery(GET_MEMBERS, callMembers)
}

export function* updateMemberRole() {
  yield takeEvery(UPDATE_MEMBER_ROLE, membersUpdateRequest)
}

export function* memberDeleteCall() {
  yield takeEvery(DELETE_MEMBER, deleteMemberCall)
}

export function* membersUpdateWebsiteCall() {
  yield takeEvery(UPDATE_WEBSITES_MEMBERS, membersUpdateWebsites)
}

export default function* rootSaga() {
  yield all([
    fork(get_request_members),
    fork(updateMemberRole),
    fork(memberDeleteCall),
    fork(membersUpdateWebsiteCall),
  ])
}
