import {
  ADD_CAMPAIGN_TEMPLATE,
  ADD_CAMPAIGN_TEMPLATE_SUCCESS,
  ADD_CAMPAIGN_TEMPLATE_ERROR,
  EDIT_CAMPAIGN_TEMPLATE,
  EDIT_CAMPAIGN_TEMPLATE_ERROR,
  EDIT_CAMPAIGN_TEMPLATE_SUCCESS,
  CLEAR_CAMPAIGN_TEMPLATE_MESSAGE,
  GET_EXISTING_EMAIL_CAMPAIGNS_STATS,
  GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS,
  GET_EXISTING_EMAIL_CAMPAIGNS,
  GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS,
  GET_EXISTING_EMAIL_CAMPAIGNS_ERROR,
  GET_EMAIL_CAMPAIGNS,
  GET_EMAIL_CAMPAIGNS_SUCCESS,
  GET_EMAIL_CAMPAIGNS_ERROR,
  PUT_EMAIL_CAMPAIGNS,
  PUT_EMAIL_CAMPAIGNS_SUCCESS,
  PUT_EMAIL_CAMPAIGNS_ERROR,
  UPDATE_ACTIVE_SWITCH,
  UPDATE_ACTIVE_SWITCH_SUCCESS,
  UPDATE_ACTIVE_SWITCH_ERROR,
  DELETE_EMAIL_CAMPAIGN,
  DELETE_EMAIL_CAMPAIGN_SUCCESS,
  DELETE_EMAIL_CAMPAIGN_ERROR,
  TEST_EMAIL_SETTINGS,
  TEST_EMAIL_SETTINGS_SUCCESS,
  TEST_EMAIL_SETTINGS_ERROR,
  CREATE_NEW_CAMPAIGN,
  CREATE_NEW_CAMPAIGN_SUCCESS,
  CREATE_NEW_CAMPAIGN_ERROR,
  GET_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN_TEMPLATE_SUCCESS,
  GET_CAMPAIGN_TEMPLATE_ERROR,
  CONNECT_CONTACTS_CAMPAIGN,
  CONNECT_CONTACTS_CAMPAIGN_ERROR,
  CONNECT_CONTACTS_CAMPAIGN_SUCCESS,
  PREVIEW_EMAIL_SUCCESS,
  PREVIEW_EMAIL_REQUEST,
  PREVIEW_EMAIL_ERROR,
  STOP_EMAIL_CAMPAIGN,
  STOP_EMAIL_CAMPAIGN_SUCCESS,
  STOP_EMAIL_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN_TEMPLATE,
  DELETE_CAMPAIGN_TEMPLATE_SUCCESS,
  DELETE_CAMPAIGN_TEMPLATE_ERROR,
  ORDER_CAMPAIGN_TEMPLATES,
  ORDER_CAMPAIGN_TEMPLATES_SUCCESS,
  ORDER_CAMPAIGN_TEMPLATES_ERROR,
  SEND_PROSPECTS_TO_CAMPAIGN,
  SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS,
  SEND_PROSPECTS_TO_CAMPAIGN_ERROR,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR,
  CLEAR_SEND_TO_CAMPAIGN,
  CLONE_EMAILCAMPAIGN,
  CLONE_EMAILCAMPAIGN_SUCCESS,
  CLONE_EMAILCAMPAIGN_ERROR,
  CLEAR_EMAILCAMPAIGN_ERROR,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

export const addTemplate = (data) => {
  return {
    type: ADD_CAMPAIGN_TEMPLATE,
    payload: data,
  }
}
export const orderTemplates = (data) => {
  return {
    type: ORDER_CAMPAIGN_TEMPLATES,
    payload: data,
  }
}
export const orderTemplatesSuccess = (data) => {
  return {
    type: ORDER_CAMPAIGN_TEMPLATES_SUCCESS,
    payload: data,
  }
}
export const orderTemplatesError = (data) => {
  return {
    type: ORDER_CAMPAIGN_TEMPLATES_ERROR,
    payload: data,
  }
}
export const previewEmail = (data) => {
  return {
    type: PREVIEW_EMAIL_REQUEST,
    payload: data,
  }
}
export const editTemplate = (data) => {
  return {
    type: EDIT_CAMPAIGN_TEMPLATE,
    payload: data,
  }
}

export const addTemplateSuccess = (data) => {
  return {
    type: ADD_CAMPAIGN_TEMPLATE_SUCCESS,
    payload: data,
  }
}
export const previewEmailSuccess = (data) => {
  return {
    type: PREVIEW_EMAIL_SUCCESS,
    payload: data,
  }
}
export const editTemplateSuccess = (data) => {
  return {
    type: EDIT_CAMPAIGN_TEMPLATE_SUCCESS,
    payload: data,
  }
}

export const addTemplateError = (message) => {
  return {
    type: ADD_CAMPAIGN_TEMPLATE_ERROR,
    payload: message,
  }
}
export const previewEmailError = (message) => {
  return {
    type: PREVIEW_EMAIL_ERROR,
    payload: message,
  }
}
export const editTemplateError = (message) => {
  return {
    type: EDIT_CAMPAIGN_TEMPLATE_ERROR,
    payload: message,
  }
}
export const stopCampaign = (data) => {
  return {
    type: STOP_EMAIL_CAMPAIGN,
    payload: data,
  }
}

export const stopCampaignSuccess = (data) => {
  return {
    type: STOP_EMAIL_CAMPAIGN_SUCCESS,
    payload: data,
  }
}
export const stopCampaignError = () => {
  return {
    type: STOP_EMAIL_CAMPAIGN_ERROR,
  }
}
export const getExistingEmailCampaignsStats = (id) => {
  return {
    type: GET_EXISTING_EMAIL_CAMPAIGNS_STATS,
    payload: id,
  }
}

export const getExistingEmailCampaignsStatsSuccess = (data) => {
  return {
    type: GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS,
    payload: data,
  }
}
export const getExistingEmailCampaigns = (id) => {
  return {
    type: GET_EXISTING_EMAIL_CAMPAIGNS,
    payload: id,
  }
}

export const getExistingEmailCampaignsSuccess = (data) => {
  return {
    type: GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS,
    payload: data,
  }
}

export const getExistingEmailCampaignsError = () => {
  return {
    type: GET_EXISTING_EMAIL_CAMPAIGNS_ERROR,
  }
}

export const getEmailCampaigns = (campaignId) => {
  return {
    type: GET_EMAIL_CAMPAIGNS,
    payload: campaignId,
  }
}

export const getEmailCampaignsSuccess = (data) => {
  return {
    type: GET_EMAIL_CAMPAIGNS_SUCCESS,
    payload: data,
  }
}

export const getEmailCampaignsError = () => {
  return {
    type: GET_EMAIL_CAMPAIGNS_ERROR,
  }
}

export const putEmailCampaigns = (data) => {
  return {
    type: PUT_EMAIL_CAMPAIGNS,
    payload: data,
  }
}

export const putEmailCampaignsSuccess = (data) => {
  return {
    type: PUT_EMAIL_CAMPAIGNS_SUCCESS,
    payload: data,
  }
}

export const putEmailCampaignsError = () => {
  return {
    type: PUT_EMAIL_CAMPAIGNS_ERROR,
  }
}

export const getCampaignTemplate = (data) => {
  return {
    type: GET_CAMPAIGN_TEMPLATE,
    payload: data,
  }
}

export const getCampaignTemplateSuccess = (data) => {
  return {
    type: GET_CAMPAIGN_TEMPLATE_SUCCESS,
    payload: data,
  }
}

export const getCampaignTemplateError = () => {
  return {
    type: GET_CAMPAIGN_TEMPLATE_ERROR,
  }
}
// export const modifyTags = (data) => {
//   return {
//     type: MODIFY_TAGS,
//     payload: data,
//   }
// }

// export const deleteTags = (data) => {
//   return {
//     type: DELETE_TAGS,
//     payload: data,
//   }
// }

export const clearCampaignMessages = () => {
  return {
    type: CLEAR_CAMPAIGN_TEMPLATE_MESSAGE,
  }
}
export const clearCloneCampaign = () => {
  return {
    type: CLEAR_EMAILCAMPAIGN_ERROR,
  }
}
export const clearSendToCampaign = () => {
  return {
    type: CLEAR_SEND_TO_CAMPAIGN,
  }
}

export const updateActiveSwitch = (data) => {
  return {
    type: UPDATE_ACTIVE_SWITCH,
    payload: data,
  }
}

export const updateActiveSwitchSuccess = (data) => {
  return {
    type: UPDATE_ACTIVE_SWITCH_SUCCESS,
    payload: data,
  }
}

export const updateActiveSwitchError = () => {
  return {
    type: UPDATE_ACTIVE_SWITCH_ERROR,
  }
}

export const deleteEmailCampaign = (id) => {
  return {
    type: DELETE_EMAIL_CAMPAIGN,
    payload: id,
  }
}
export const deleteEmailCampaignSuccess = (data) => {
  return {
    type: DELETE_EMAIL_CAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const deleteEmailCampaignError = () => {
  return {
    type: DELETE_EMAIL_CAMPAIGN_ERROR,
  }
}
export const cloneEmailCampaign = (id) => {
  return {
    type: CLONE_EMAILCAMPAIGN,
    payload: id,
  }
}
export const cloneEmailCampaignSuccess = (data) => {
  return {
    type: CLONE_EMAILCAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const cloneEmailCampaignError = () => {
  return {
    type: CLONE_EMAILCAMPAIGN_ERROR,
  }
}
export const exportEmailCampaignContacts = (id) => {
  return {
    type: EXPORT_EMAIL_CAMPAIGN_CONTACTS,
    payload: id,
  }
}
export const exportEmailCampaignContactsSuccess = (data) => {
  return {
    type: EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const exportEmailCampaignContactsError = () => {
  return {
    type: EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR,
  }
}

export const deleteEmailTemplate = (id) => {
  return {
    type: DELETE_CAMPAIGN_TEMPLATE,
    payload: id,
  }
}
export const deleteEmailTemplateSuccess = (data) => {
  return {
    type: DELETE_CAMPAIGN_TEMPLATE_SUCCESS,
    payload: data,
  }
}

export const deleteEmailTemplateError = () => {
  return {
    type: DELETE_CAMPAIGN_TEMPLATE_ERROR,
  }
}

export const testEmailSettings = (data) => {
  return {
    type: TEST_EMAIL_SETTINGS,
    payload: data,
  }
}
export const testEmailSettingsSuccess = (data) => {
  return {
    type: TEST_EMAIL_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const testEmailSettingsError = () => {
  return {
    type: TEST_EMAIL_SETTINGS_ERROR,
  }
}

export const createNewCampaign = (id) => {
  return {
    type: CREATE_NEW_CAMPAIGN,
    payload: id,
  }
}
export const createNewCampaignSuccess = (data) => {
  return {
    type: CREATE_NEW_CAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const createNewCampaignError = () => {
  return {
    type: CREATE_NEW_CAMPAIGN_ERROR,
  }
}
export const connectContactsCampaign = (data) => {
  return {
    type: CONNECT_CONTACTS_CAMPAIGN,
    payload: data,
  }
}

export const connectContactsSuccess = (data) => {
  return {
    type: CONNECT_CONTACTS_CAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const connectContactsError = () => {
  return {
    type: CONNECT_CONTACTS_CAMPAIGN_ERROR,
  }
}
export const sendProspectorToCampaign = (data) => {
  return {
    type: SEND_PROSPECTS_TO_CAMPAIGN,
    payload: data,
  }
}

export const sendProspectorToCampaignSuccess = (data) => {
  return {
    type: SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const sendProspectorToCampaignError = () => {
  return {
    type: SEND_PROSPECTS_TO_CAMPAIGN_ERROR,
  }
}
export const sendPeopleProspectorToCampaign = (data) => {
  return {
    type: SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN,
    payload: data,
  }
}

export const sendPeopleProspectorToCampaignSuccess = (data) => {
  return {
    type: SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS,
    payload: data,
  }
}

export const sendPeopleProspectorToCampaignError = () => {
  return {
    type: SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR,
  }
}
