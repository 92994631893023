import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { SAVE_ICP_FILTERS, GET_ICP_FILTERS } from '../../constants/ActionTypes'

import {
  saveIcpFiltersError,
  saveIcpFiltersSuccess,
  getIcpFiltersSuccess,
  getIcpFiltersError,
  getIcpFilters,
} from '../actions/Icp'

import {
  generalSuccessMessage,
  generalErrorMessage,
} from '../actions/GeneralMessage'

import { userSignOut } from '../actions/Auth'

const updateIcpFiltersRequest = async (
  websiteId,
  employeeUpperBound,
  employeeLowerBound,
  revenueUpperBound,
  revenueLowerBound,
  location,
  industries,
  sector,
  role,
  revenuePriority,
  employeesPriority,
  sectorsPriority,
  industriesPriority,
  locationPriority,
  filter
) =>
  await axios
    .put(`${baseUrl}/admin/icp`, {
      websiteId,
      employeeUpperBound,
      employeeLowerBound,
      revenueUpperBound,
      revenueLowerBound,
      location,
      industries,
      sector,
      role,
      revenuePriority,
      employeesPriority,
      sectorsPriority,
      industriesPriority,
      locationPriority,
      filter,
    })
    .then((response) => response)
    .catch((error) => error)

const fetchIcpFiltersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/icp?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error)

function* updateIcpFilters({ payload }) {
  const {
    websiteId,
    employeeUpperBound,
    employeeLowerBound,
    revenueUpperBound,
    revenueLowerBound,
    location,
    industries,
    sector,
    role,
    revenuePriority,
    employeesPriority,
    sectorsPriority,
    industriesPriority,
    locationPriority,
    filter,
  } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(
      updateIcpFiltersRequest,
      websiteId,
      employeeUpperBound,
      employeeLowerBound,
      revenueUpperBound,
      revenueLowerBound,
      location,
      industries,
      sector,
      role,
      revenuePriority,
      employeesPriority,
      sectorsPriority,
      industriesPriority,
      locationPriority,
      filter
    )

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(saveIcpFiltersError(callResponse.response.data.status))
        yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(
          saveIcpFiltersError('Something went wrong please check your network')
        )
        yield put(
          generalErrorMessage('Something went wrong please check your network')
        )
      }
    } else {
      yield put(saveIcpFiltersSuccess())
      yield put(generalSuccessMessage('ICP filters saved successfully'))
      yield put(getIcpFilters({ websiteId }))
    }
  } catch (error) {
    yield put(saveIcpFiltersError(error))
    yield put(generalErrorMessage(error))
  }
}

function* fetchIcpFilters({ payload }) {
  const { websiteId } = payload
  setAuthorizationHeader()
  try {
    const callResponse = yield call(fetchIcpFiltersRequest, websiteId)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(generalErrorMessage(callResponse.response.data.status))
        yield put(getIcpFiltersError())
      } else {
        yield put(
          generalErrorMessage('Something went wrong please check your network')
        )
        yield put(getIcpFiltersError())
      }
    } else {
      yield put(getIcpFiltersSuccess(callResponse.data))
    }
  } catch (error) {
    yield put(getIcpFiltersError())
    yield put(generalErrorMessage(error))
  }
}

export function* putRequestSaveIcpFilters() {
  yield takeEvery(SAVE_ICP_FILTERS, updateIcpFilters)
}

export function* getRequestFetchIcpFilters() {
  yield takeEvery(GET_ICP_FILTERS, fetchIcpFilters)
}

export default function* rootSaga() {
  yield all([fork(putRequestSaveIcpFilters), fork(getRequestFetchIcpFilters)])
}
