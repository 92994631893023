/* eslint-disable no-unused-vars */
import {
  CREATE_NEW_CONNECTION,
  CREATE_NEW_CONNECTION_SUCCESS,
  CREATE_NEW_CONNECTION_ERROR,
  DELETE_EMAIL_ACCOUNTS,
  DELETE_EMAIL_ACCOUNTS_SUCCESS,
  DELETE_EMAIL_ACCOUNTS_ERROR,
  GET_EMAIL_ACCOUNTS,
  GET_EMAIL_ACCOUNTS_SUCCESS,
  GET_EMAIL_ACCOUNTS_ERROR,
  FETCH_USER_EMAIL_ACCOUNT,
  FETCH_USER_EMAIL_ACCOUNT_SUCCESS,
  FETCH_USER_EMAIL_ACCOUNT_ERROR,
  SET_USER_EMAIL_ACCOUNT,
  SET_USER_EMAIL_ACCOUNT_SUCCESS,
  SET_USER_EMAIL_ACCOUNT_ERROR,
  FIX_SPAMMED_EMAIL_ACCOUNT,
  FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS,
  FIX_SPAMMED_EMAIL_ACCOUNT_ERROR,
  GET_EMAIL_ACCOUNTS_STATS,
  GET_EMAIL_ACCOUNTS_STATS_SUCCESS,
  GET_EMAIL_ACCOUNTS_STATS_ERROR,
  GET_EMAIL_PROVIDER_ID,
  GET_EMAIL_PROVIDER_ID_SUCCESS,
  GET_EMAIL_PROVIDER_ID_ERROR,
  CLEAR_CREATE_NEW_CONNECTION,
  CONNECT_IN_HOUSE_INTEGRATION,
  CONNECT_IN_HOUSE_INTEGRATION_SUCCESS,
  CONNECT_IN_HOUSE_INTEGRATION_ERROR,
  DISCONNECT_INTEGRATIONS,
  DISCONNECT_INTEGRATIONS_ERROR,
  DISCONNECT_INTEGRATIONS_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  createNewConnectionLoading: false,
  createNewConnectionError: false,
  createNewConnectionData: '',
  fixSpammedLoading: false,
  fixSpammedError: false,
  fixSpammedSuccess: false,
  getEmailAccountsLoading: false,
  getEmailAccountsError: false,
  fetchUserDataLoading: false,
  fetchUserDataError: false,
  deleteEmailAccountsLoading: false,
  deleteEmailAccountsError: false,
  deleteIndex: null,
  existingEmailsData: [],
  userEmailData: {},
  setUserDataError: false,
  setUserDataLoading: false,
  setUserDataSuccess: false,
  integrations: false,
  getEmailAccountsWithStatsLoading: false,
  getEmailAccountsWithStatsError: false,
  existingEmailsDataWithStats: [],
  emailProviderId: '',
  getEmailProviderIdError: false,
  getEmailProviderIdSuccess: false,
  getEmailProviderIdLoading: false,
  connectIntegrationLoading: false,
  connectIntegrationError: false,
  connectIntegrationSuccess: false,
  disconnectIntegrationLoading: false,
  disconnectIntegrationError: false,
  disconnectIntegrationSuccess: false,
};

const Integrations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMAIL_ACCOUNTS: {
      return {
        ...state,
        getEmailAccountsLoading: true,
      };
    }
    case GET_EMAIL_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        getEmailAccountsLoading: false,
        existingEmailsData: action.payload,
      };
    }
    case GET_EMAIL_ACCOUNTS_ERROR: {
      return {
        ...state,
        getEmailAccountsLoading: false,
        getEmailAccountsError: true,
      };
    }
    case GET_EMAIL_ACCOUNTS_STATS: {
      return {
        ...state,
        getEmailAccountsWithStatsLoading: true,
      };
    }
    case GET_EMAIL_ACCOUNTS_STATS_SUCCESS: {
      return {
        ...state,
        getEmailAccountsWithStatsLoading: false,
        existingEmailsDataWithStats: action.payload,
      };
    }
    case GET_EMAIL_ACCOUNTS_STATS_ERROR: {
      return {
        ...state,
        getEmailAccountsWithStatsLoading: false,
        getEmailAccountsWithStatsError: true,
      };
    }

    case FETCH_USER_EMAIL_ACCOUNT: {
      return {
        ...state,
        fetchUserDataLoading: true,
      };
    }
    case FETCH_USER_EMAIL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fetchUserDataLoading: false,
        userEmailData: action.payload,
      };
    }
    case FETCH_USER_EMAIL_ACCOUNT_ERROR: {
      return {
        ...state,
        fetchUserDataLoading: false,
        fetchUserDataError: true,
      };
    }

    case SET_USER_EMAIL_ACCOUNT: {
      return {
        ...state,
        setUserDataLoading: true,
      };
    }
    case SET_USER_EMAIL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        setUserDataLoading: false,
        setUserDataSuccess: true,
      };
    }
    case SET_USER_EMAIL_ACCOUNT_ERROR: {
      return {
        ...state,
        setUserDataLoading: false,
        setUserDataSuccess: false,
        setUserDataError: true,
      };
    }

    case CREATE_NEW_CONNECTION: {
      return {
        ...state,
        createNewConnectionLoading: true,
      };
    }
    case CREATE_NEW_CONNECTION_SUCCESS: {
      return {
        ...state,
        createNewConnectionLoading: false,
        createNewConnectionData: action.payload,
      };
    }
    case CREATE_NEW_CONNECTION_ERROR: {
      return {
        ...state,
        createNewConnectionLoading: false,
        createNewConnectionError: true,
      };
    }
    case DISCONNECT_INTEGRATIONS: {
      return {
        ...state,
        disconnectIntegrationLoading: true,
      };
    }
    case DISCONNECT_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        disconnectIntegrationLoading: false,
        disconnectIntegrationSuccess: true,
      };
    }
    case DISCONNECT_INTEGRATIONS_ERROR: {
      return {
        ...state,
        disconnectIntegrationLoading: false,
        disconnectIntegrationError: true,
      };
    }
    case CLEAR_CREATE_NEW_CONNECTION: {
      return {
        ...state,
        createNewConnectionLoading: false,
        createNewConnectionError: false,
        createNewConnectionData: '',
      };
    }
    case FIX_SPAMMED_EMAIL_ACCOUNT: {
      return {
        ...state,
        fixSpammedLoading: true,
      };
    }
    case FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fixSpammedLoading: false,
        fixSpammedSuccess: true,
      };
    }
    case FIX_SPAMMED_EMAIL_ACCOUNT_ERROR: {
      return {
        ...state,
        fixSpammedLoading: false,
        fixSpammedError: true,
      };
    }
    case CONNECT_IN_HOUSE_INTEGRATION: {
      return {
        ...state,
        connectIntegrationLoading: true,
      };
    }
    case CONNECT_IN_HOUSE_INTEGRATION_SUCCESS: {
      return {
        ...state,
        connectIntegrationLoading: false,
        connectIntegrationSuccess: true,
      };
    }
    case CONNECT_IN_HOUSE_INTEGRATION_ERROR: {
      return {
        ...state,
        connectIntegrationLoading: false,
        connectIntegrationError: true,
      };
    }

    case DELETE_EMAIL_ACCOUNTS: {
      return {
        ...state,
        deleteEmailAccountsLoading: true,
        deleteIndex: action.payload.index,
      };
    }

    case DELETE_EMAIL_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        deleteEmailAccountsLoading: false,
      };
    }

    case DELETE_EMAIL_ACCOUNTS_ERROR: {
      return {
        ...state,
        deleteEmailAccountsLoading: false,
        deleteEmailAccountsError: true,
      };
    }
    case GET_EMAIL_PROVIDER_ID: {
      return {
        ...state,
        getEmailProviderIdLoading: true,
      };
    }
    case GET_EMAIL_PROVIDER_ID_SUCCESS: {
      return {
        ...state,
        getEmailProviderIdLoading: false,
        emailProviderId: action.payload,
      };
    }
    case GET_EMAIL_PROVIDER_ID_ERROR: {
      return {
        ...state,
        getEmailProviderIdLoading: false,
        getEmailProviderIdError: true,
      };
    }

    default:
      return state;
  }
};

export default Integrations;
