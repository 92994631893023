import {
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT,
  ADD_CONTACT_ERROR,
  CLEAR_CONTACT_ERROR,
} from '../../constants/ActionTypes'

export const AddContactRequest = (data) => {
  return {
    type: ADD_CONTACT,
    payload: data,
  }
}

export const AddContactSuccess = (message) => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload: message,
  }
}

export const AddContactError = (message) => {
  return {
    type: ADD_CONTACT_ERROR,
    payload: message,
  }
}
export const clearContactError = () => {
  return {
    type: CLEAR_CONTACT_ERROR,
  }
}
