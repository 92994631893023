import {
  ADD_FILTER_HAPPIER_LEADS,
  FETCH_ALL_FILTERS_HAPPIERLEADS,
  CALL_FETCH_ALL_FILTERS_HAPPIERLEADS,
  GET_LEADS_DATA,
  GET_START_AND_END_DATE,
  LOADING_LEADS_TABLE,
  ERROR,
  CLEAR_ERROR,
  DELETE_SAVED_FITER,
  CREATE_SEARCHABLE_LEADS_DATA,
  UPDATE_DISPLAY_LEADS_DATA,
  GET_FILTERED_LEADS_DATA,
  GET_ORIGIN_HAPPIERLEARS,
  REMOVE_FILTER_HAPPIERLEAD_LEADS,
  UPDATE_FILTER_HAPPIERLEAD_LEADS,
  CLEAR_ALL_STATES_LEADS,
  SET_NOTFOUND,
  CANCEL_NOTFOUND,
  ADD_LEADS_TAG,
  DELETE_LEADS_TAG,
  MODIFY_LEADS_TAG,
  SUCCESS_DELETE_TAG,
  SUCCESS_MODIFY_TAG,
  GET_TAGS_LEAD,
  SUCCESS_GET_TAGS_LEADS,
  RESET_LEADS_TAGS,
  CONTROL_RELOAD_SIDEBAR_LEADS,
  CONTROL_RESET_SIDEBAR_LEADS,
  UPDATE_EXISTING_TAGS,
  UPDATE_CURRENT_LEADS_PAGE,
  UPDATE_COUNT_LEADS,
  REFRESH_LEADS_PAGE,
  CREATE_SELECTED_COLUMNS_LEADS,
  UNLOCK_LEADS,
  SUCCESS_UNLOCK_LEADS,
  CREATE_SELECTED_COLUMNS_LEADS_SUCCESS,
  CREATE_SELECTED_COLUMNS_LEADS_ERROR,
  SET_SELECTED_ROW_IDS,
  RESET_LEAD_SCORES,
  RESET_LEAD_SCORES_SUCCESS,
  RESET_LEAD_SCORES_ERROR,
  SET_ALL_PAGES_SELECTED,
  CLEAR_RESET_LEAD_SCORES,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  savedFilters: [],
  leadsData: [],
  loadingTableData: true,
  daterange: {},
  error: false,
  errorMessage: '',
  searchableLeadsData: [],
  searchResultLeadsTableData: [],
  currentLeadsTableData: [],
  urlOrigin: '',
  filterIdToBeDeleted: '',
  addedFilter: {},
  loadingFilters: false,
  savingFilter: false,
  completed: false,
  nothingFound: false,
  addTagsPayload: {},
  modifyTagsPayload: {},
  deleteTagsPayload: {},
  leadsTags: [],
  tagSuccess: false,
  tagStatus: 'idle',
  successMessage: '',
  existingTags: [],
  reloadSideFilters: false,
  totalLeads: 1,
  totalPages: 1,
  currentPage: 1,
  count: 1,
  refresh: 1,
  selectedTableFields: {},
  unlockCompanyId: '',
  responseUnlockedLead: {},
  sampleData: false,
  newFieldsSuccess: false,
  newFieldsError: false,
  selectedRowIds: [],
  resetLeadScore: false,
  resetLeadScoresLoading: false,
  resetLeadScoringError: false,
  allLeadsPagesSelected: false,
};

const Leads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FILTER_HAPPIER_LEADS: {
      const currentFilters = state.savedFilters;
      return {
        ...state,
        savingFilter: false,
        savedFilters: [...currentFilters, action.payload],
      };
    }

    case FETCH_ALL_FILTERS_HAPPIERLEADS: {
      return {
        ...state,
        savedFilters: action.payload,
        loadingFilters: false,
      };
    }

    case CREATE_SEARCHABLE_LEADS_DATA: {
      return {
        ...state,
        searchableLeadsData: action.payload,
      };
    }

    case CALL_FETCH_ALL_FILTERS_HAPPIERLEADS: {
      return {
        ...state,
        loadingFilters: true,
      };
    }

    case GET_LEADS_DATA: {
      return {
        ...state,
        totalLeads: action.payload.totalLeads,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        leadsData: action.payload.leads,
        sampleData: action.payload.sampleData,
        loadingTableData: false,
      };
    }

    case GET_START_AND_END_DATE: {
      return {
        ...state,
        daterange: action.payload,
      };
    }

    case UPDATE_DISPLAY_LEADS_DATA: {
      return {
        ...state,
        searchResultLeadsTableData: action.payload,
      };
    }

    case REMOVE_FILTER_HAPPIERLEAD_LEADS: {
      return {
        ...state,
        filterIdToBeDeleted: action.payload,
        deletingFilter: true,
      };
    }

    case GET_FILTERED_LEADS_DATA: {
      return {
        ...state,
        currentLeadsTableData: action.payload,
        completed: true,
      };
    }

    case CREATE_SELECTED_COLUMNS_LEADS: {
      return {
        ...state,
        selectedTableFields: action.payload,
      };
    }
    case CREATE_SELECTED_COLUMNS_LEADS_SUCCESS: {
      return {
        ...state,
        selectedTableFields: action.payload,
        newFieldsSuccess: true,
      };
    }
    case CREATE_SELECTED_COLUMNS_LEADS_ERROR: {
      return {
        ...state,
        selectedTableFields: action.payload,
        newFieldsSuccess: false,
        newFieldsError: false,
      };
    }

    case GET_ORIGIN_HAPPIERLEARS: {
      return {
        ...state,
        urlOrigin: action.payload,
      };
    }

    case LOADING_LEADS_TABLE: {
      return {
        ...state,
        loadingTableData: true,
        completed: false,
        nothingFound: false,
      };
    }

    case UPDATE_FILTER_HAPPIERLEAD_LEADS: {
      return {
        ...state,
        savingFilter: true,
        addedFilter: action.payload,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loadingTableData: false,
        savingFilter: false,
        completed: true,
        tagStatus: 'idle',
      };
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: '',
        loadingTableData: false,
        tagSuccess: false,
        tagStatus: 'idle',
        successMessage: '',
      };
    }

    case UNLOCK_LEADS: {
      return {
        ...state,
        unlockCompayId: action.payload.companyId,
      };
    }

    case SUCCESS_UNLOCK_LEADS: {
      const indexOfSearchData = state.searchableLeadsData.findIndex((item) => item.compId === action.payload.companyId);
      state.searchableLeadsData[indexOfSearchData].number = action.payload.unlockedData[0]._id;

      return {
        ...state,
        responseUnlockedLead: action.payload,
      };
    }

    case SET_NOTFOUND: {
      return {
        ...state,
        nothingFound: true,
      };
    }

    case CANCEL_NOTFOUND: {
      return {
        ...state,
        nothingFound: false,
      };
    }

    case CLEAR_ALL_STATES_LEADS: {
      return {
        ...state,
        savedFilters: [],
        leadsData: [],
        loadingTableData: false,
        daterange: {},
        error: false,
        errorMessage: '',
        searchableLeadsData: [],
        searchResultLeadsTableData: [],
        currentLeadsTableData: [],
        urlOrigin: '',
        filterIdToBeDeleted: '',
        addedFilter: {},
        loadingFilters: false,
        savingFilter: false,
      };
    }

    case DELETE_SAVED_FITER: {
      const indexOfDelete = state.savedFilters.findIndex((filter) => filter._id === action.payload);
      state.savedFilters.splice(indexOfDelete, 1);
      return {
        ...state,
      };
    }

    case ADD_LEADS_TAG: {
      return {
        ...state,
        addTagsPayload: action.payload,
      };
    }

    case MODIFY_LEADS_TAG: {
      return {
        ...state,
        modifyTagsPayload: action.payload,
      };
    }

    case DELETE_LEADS_TAG: {
      return {
        ...state,
        deleteTagsPayload: action.payload,
      };
    }

    case GET_TAGS_LEAD: {
      return {
        ...state,
        tagStatus: 'loading',
      };
    }

    case SUCCESS_GET_TAGS_LEADS: {
      return {
        ...state,
        leadsTags: action.payload,
        tagStatus: 'completed',
        existingTags: action.payload,
      };
    }

    case SUCCESS_MODIFY_TAG: {
      const indexOfSearchData = state.searchableLeadsData.findIndex((item) => item.number === action.payload.leadId);
      state.searchableLeadsData[indexOfSearchData].searchTag.push(action.payload.text.trim().toLowerCase());
      state.searchableLeadsData[indexOfSearchData].tags.push({
        ...action.payload,
      });

      return {
        ...state,
        successMessage: 'Tag Added',
        tagSuccess: true,
      };
    }

    case SUCCESS_DELETE_TAG: {
      const indexOfLead = state.searchableLeadsData.findIndex((item) => item.number === action.payload.leadId);
      const tags = state.searchableLeadsData[indexOfLead].tags;
      const indexOfDelete = tags.findIndex((tag) => tag._id === action.payload.tagId);
      const currentTags = tags.splice(indexOfDelete, 1);
      state.leadsData[indexOfLead].tags = currentTags;
      const currentSearchTags = state.searchableLeadsData[indexOfLead].searchTag;
      const searchTagIndex = currentSearchTags.findIndex(
        (text) => text.trim().toLowerCase() === action.payload.text.trim().toLowerCase()
      );
      const newSearchTags = currentSearchTags.splice(searchTagIndex, 1);
      state.leadsData[indexOfLead].searchTag = newSearchTags;
      return {
        ...state,
        successMessage: 'Tag Removed',
        tagSuccess: true,
      };
    }

    case RESET_LEADS_TAGS: {
      return {
        ...state,
        tagStatus: 'idle',
      };
    }

    case CONTROL_RELOAD_SIDEBAR_LEADS: {
      return {
        ...state,
        reloadSideFilters: true,
      };
    }

    case CONTROL_RESET_SIDEBAR_LEADS: {
      return {
        ...state,
        reloadSideFilters: false,
      };
    }

    case UPDATE_EXISTING_TAGS: {
      return {
        ...state,
        existingTags: [...state.existingTags, action.payload],
      };
    }

    case UPDATE_CURRENT_LEADS_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case SET_SELECTED_ROW_IDS: {
      return {
        ...state,
        selectedRowIds: action.payload,
      };
    }
    case SET_ALL_PAGES_SELECTED: {
      return {
        ...state,
        allLeadsPagesSelected: action.payload,
      };
    }

    case UPDATE_COUNT_LEADS: {
      const newCount = state.count + 1;
      return {
        ...state,
        count: newCount,
      };
    }

    case REFRESH_LEADS_PAGE: {
      return {
        ...state,
        refresh: action.payload,
      };
    }

    case RESET_LEAD_SCORES: {
      return {
        ...state,
        resetLeadScoresLoading: true,
      };
    }
    case RESET_LEAD_SCORES_SUCCESS: {
      return {
        ...state,
        resetLeadScoresLoading: false,
        resetLeadScoresSuccess: true,
      };
    }
    case RESET_LEAD_SCORES_ERROR: {
      return {
        ...state,
        resetLeadScoresLoading: false,
        resetLeadScoresSuccess: false,
        resetLeadScoresError: true,
      };
    }
    case CLEAR_RESET_LEAD_SCORES: {
      return {
        ...state,
        resetLeadScoresLoading: false,
        resetLeadScoresSuccess: false,
        resetLeadScoresError: false,
      };
    }

    default:
      return state;
  }
};

export default Leads;
