import Router from 'next/router';
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  USER_DETAILS_HAPPIER_LEADS,
  USER_HAPPIER_LEADS,
  SEND_RECOVERY_EMAIL,
  SUCCESS_RECOVERY_EMAIL,
  SEND_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  MOVE_USER_TO_HOME,
  WEBSITE_CLIENT_ID,
  RESET_VERIFIED_WEBSITE,
  SET_WEBSITEURL_SETUPSCRIPT,
  CHECK_TRIAL,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  DELETE_MY_ACCOUNT,
  DELETE_MY_ACCOUNT_SUCCESS,
  DELETE_MY_ACCOUNT_ERROR,
} from '../../constants/ActionTypes';

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const verifyEmail = (data) => {
  return {
    type: VERIFY_EMAIL_REQUEST,
    payload: data,
  };
};
export const verifyEmailSuccess = (data) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payload: data,
  };
};
export const verifyEmailError = (data) => {
  return {
    type: VERIFY_EMAIL_ERROR,
    payload: data,
  };
};
export const userSignIn = (user) => {

  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = (authUser) => {

  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = (data) => {
  return {
    type: SIGNIN_GOOGLE_USER,
    payload: data,
  };
};

export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setuserInfo = (authUser) => {
  return {
    type: USER_HAPPIER_LEADS,
    payload: authUser,
  };
};

export const getUserDetails = () => {
  return {
    type: USER_DETAILS_HAPPIER_LEADS,
  };
};

export const sendRecoveryEmail = (data) => {
  return {
    type: SEND_RECOVERY_EMAIL,
    payload: data,
  };
};

export const successRecoveryEmail = () => {
  return {
    type: SUCCESS_RECOVERY_EMAIL,
  };
};

export const sendResetPassword = (data) => {
  return {
    type: SEND_RESET_PASSWORD,
    payload: data,
  };
};

export const successResetPassword = (data) => {
  return {
    type: SUCCESS_RESET_PASSWORD,
    payload: data,
  };
};

export const moveUserToHome = () => {
  Router.replace('/signin');
  return {
    type: MOVE_USER_TO_HOME,
  };
};

export const setInstallClientId = (id) => {
  return {
    type: WEBSITE_CLIENT_ID,
    payload: id,
  };
};

export const resetVerified = (id) => {
  return {
    type: RESET_VERIFIED_WEBSITE,
    payload: id,
  };
};

export const setSetupScriptUrl = (url) => {
  return {
    type: SET_WEBSITEURL_SETUPSCRIPT,
    payload: url,
  };
};

export const check_trial = () => {
  return {
    type: CHECK_TRIAL,
  };
};

export const deleteMyAccount = (id) => {
  return {
    type: DELETE_MY_ACCOUNT,
    payload: id,
  };
};

export const deleteAccountSuccess = (data) => {
  return {
    type: DELETE_MY_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const deleteAccountError = (message) => {
  return {
    type: DELETE_MY_ACCOUNT_ERROR,
    payload: message,
  };
};
