import {
  LEAD_FETCH_DATA_REQUEST,
  LEAD_FETCH_DATA_SUCCESS,
  LEAD_FETCH_DATA_ERROR,
  CLEAR_ALL_STATES_LEAD,
  ADD_TAGS_LEAD_PROFILE,
  MODIFY_TAGS_LEAD_PROFILE,
  SUCCESS_MODIFY_TAGS_LEAD_PROFILE,
  DELETE_TAGS_LEAD_PROFILE,
  SUCCESS_DELETE_TAGS_LEAD_PROFILE,
  SET_WEBSITE_ID_TAGS_PROFILE,
  SET_LEADS_QUERY_STRING,
  HIDE_LEAD_PROFILE,
  HIDE_LEAD_PROFILE_SUCCESS,
  HIDE_LEAD_PROFILE_FAILED,
  DELETE_LEADS_REQUEST,
  DELETE_LEADS_SUCCESS,
  DELETE_LEADS_ERROR,
  GET_LEAD_AI_SUMMARY,
  GET_LEAD_AI_SUMMARY_SUCCESS,
  GET_LEAD_AI_SUMMARY_FAILED,
} from '../../constants/ActionTypes';

export const leadFetchDataRequest = (id) => {
  return {
    type: LEAD_FETCH_DATA_REQUEST,
    payload: id,
  };
};

export const leadFetchDataSuccess = (data) => {
  return {
    type: LEAD_FETCH_DATA_SUCCESS,
    payload: data,
  };
};

export const leadFetchDataError = (error) => {
  return {
    type: LEAD_FETCH_DATA_ERROR,
    payload: error,
  };
};

export const clearAllStatesLead = () => {
  return {
    type: CLEAR_ALL_STATES_LEAD,
  };
};

export const addTagsLeadProfile = (data) => {
  return {
    type: ADD_TAGS_LEAD_PROFILE,
    payload: data,
  };
};

export const modifyTagsLeadProfile = (data) => {
  return {
    type: MODIFY_TAGS_LEAD_PROFILE,
    payload: data,
  };
};

export const successModifyTagsLeadProfile = (data) => {
  return {
    type: SUCCESS_MODIFY_TAGS_LEAD_PROFILE,
    payload: data,
  };
};

export const deleteTagsLeadProfile = (data) => {
  return {
    type: DELETE_TAGS_LEAD_PROFILE,
    payload: data,
  };
};

export const successDeleteTagsLeadProfile = (data) => {
  return {
    type: SUCCESS_DELETE_TAGS_LEAD_PROFILE,
    payload: data,
  };
};
export const deleteLeadsRequest = (data) => {
  return {
    type: DELETE_LEADS_REQUEST,
    payload: data,
  };
};

export const deleteLeadsSuccess = (data) => {
  return {
    type: DELETE_LEADS_SUCCESS,
    payload: data,
  };
};
export const deleteLeadsError = () => {
  return {
    type: DELETE_LEADS_ERROR,
  };
};

export const setTagWebsiteIdProfile = (id) => {
  return {
    type: SET_WEBSITE_ID_TAGS_PROFILE,
    payload: id,
  };
};

export const saveLeadsQueryString = (newString) => {
  return {
    type: SET_LEADS_QUERY_STRING,
    payload: newString,
  };
};

export const hideLeadProfile = (data) => {
  return {
    type: HIDE_LEAD_PROFILE,
    payload: data,
  };
};

export const hideLeadProfileSuccess = () => {
  return {
    type: HIDE_LEAD_PROFILE_SUCCESS,
  };
};

export const hideLeadProfileFailed = () => {
  return {
    type: HIDE_LEAD_PROFILE_FAILED,
  };
};
export const getLeadAiSummary = (data) => {
  return {
    type: GET_LEAD_AI_SUMMARY,
    payload: data,
  };
};

export const getLeadAiSummarySuccess = (data) => {
  return {
    type: GET_LEAD_AI_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const getLeadAiSummaryFailed = () => {
  return {
    type: GET_LEAD_AI_SUMMARY_FAILED,
  };
};
