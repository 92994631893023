import {
  DOWNLOAD_CSV,
  ERROR_DOWNLOAD_CSV,
  SUCCESS_DOWNLOAD_CSV,
  CLEAR_EXPORT_CSV_ERROR,
  SET_CSV_GET_PARAMS,
} from '../../constants/ActionTypes'

export const csvDownloadRequest = (data) => {
  return {
    type: DOWNLOAD_CSV,
    payload: data,
  }
}

export const downloadCSVSuccess = (data) => {
  return {
    type: SUCCESS_DOWNLOAD_CSV,
    payload: data,
  }
}

export const downloadCSVError = () => {
  return {
    type: ERROR_DOWNLOAD_CSV,
  }
}

export const clearCSVError = () => {
  return {
    type: CLEAR_EXPORT_CSV_ERROR,
  }
}

export const setCSVParams = (queryString) => {
  return {
    type: SET_CSV_GET_PARAMS,
    payload: queryString,
  }
}
