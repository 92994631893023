import {
  GET_HUBSPOT_OWNERS,
  GET_HUBSPOT_OWNERS_SUCCESS,
  GET_HUBSPOT_OWNERS_ERROR,
  SAVE_NEW_COMPANY,
  SAVE_NEW_COMPANY_SUCCESS,
  SAVE_NEW_COMPANY_ERROR,
  SEARCH_COMPANY,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_ERROR,
  CONNECT_EXISTING_COMPANY,
  CONNECT_EXISTING_COMPANY_SUCCESS,
  CONNECT_EXISTING_COMPANY_ERROR,
  CONNECT_CONTACTS,
  CONNECT_CONTACTS_SUCCESS,
  CONNECT_CONTACTS_ERROR,
  CLEAR_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  error: false,
  saveCompanyLoading: false,
  saveCompanyError: false,
  saveCompanySuccess: false,
  searchCompanyLoading: false,
  searchCompanyError: false,
  connectCompanyLoading: false,
  connectCompanyError: false,
  connectCompanySuccess: false,
  connectContactsSuccess: false,
  connectContactsLoading: false,
  connectContactsError: false,
  contactEmail: '',
  hubspotOwners: [],
  contactsArr: [],
  existingCompanies: [],
}

const Hubspot = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HUBSPOT_OWNERS: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_HUBSPOT_OWNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        hubspotOwners: action.payload.data.results,
      }
    }
    case GET_HUBSPOT_OWNERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case SAVE_NEW_COMPANY: {
      return {
        ...state,
        saveCompanyLoading: true,
      }
    }
    case SAVE_NEW_COMPANY_SUCCESS: {
      return {
        ...state,
        saveCompanyLoading: false,
        saveCompanySuccess: true,
      }
    }
    case SAVE_NEW_COMPANY_ERROR: {
      return {
        ...state,
        saveCompanyLoading: false,
        saveCompanyError: true,
      }
    }

    case SEARCH_COMPANY: {
      return {
        ...state,
        searchCompanyLoading: true,
      }
    }
    case SEARCH_COMPANY_SUCCESS: {
      return {
        ...state,
        searchCompanyLoading: false,
        existingCompanies: action.payload.data.results,
      }
    }
    case SEARCH_COMPANY_ERROR: {
      return {
        ...state,
        searchCompanyLoading: false,
        searchCompanyError: true,
      }
    }

    case CONNECT_EXISTING_COMPANY: {
      return {
        ...state,
        connectCompanyLoading: true,
      }
    }
    case CONNECT_EXISTING_COMPANY_SUCCESS: {
      return {
        ...state,
        connectCompanyLoading: false,
        connectCompanySuccess: true,
      }
    }
    case CONNECT_EXISTING_COMPANY_ERROR: {
      return {
        ...state,
        connectCompanyLoading: false,
        connectCompanyError: true,
      }
    }

    case CONNECT_CONTACTS: {
      return {
        ...state,
        connectContactsLoading: true,
        contactEmail: action.payload.email,
        connectContactsSuccess: false,
        connectContactsError: false,
        contactsArr: [...state.contactsArr, action.payload.email],
      }
    }
    case CONNECT_CONTACTS_SUCCESS: {
      return {
        ...state,
        connectContactsLoading: false,
        connectContactsSuccess: true,
        connectContactsError: false,
      }
    }
    case CONNECT_CONTACTS_ERROR: {
      return {
        ...state,
        connectContactsLoading: false,
        connectContactsError: true,
        connectContactsSuccess: false,
      }
    }
    case CLEAR_CONTACTS_ERROR: {
      return {
        ...state,
        connectContactsLoading: false,
        connectContactsError: false,
        // connectContactsSuccess: false,
      }
    }

    default:
      return state
  }
}

export default Hubspot
