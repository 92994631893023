import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import Router from 'next/router';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import {
  CREATE_NEW_CONNECTION,
  GET_EMAIL_ACCOUNTS,
  DELETE_EMAIL_ACCOUNTS,
  FETCH_USER_EMAIL_ACCOUNT,
  SET_USER_EMAIL_ACCOUNT,
  FIX_SPAMMED_EMAIL_ACCOUNT,
  GET_EMAIL_ACCOUNTS_STATS,
  GET_EMAIL_PROVIDER_ID,
  CONNECT_IN_HOUSE_INTEGRATION,
  DISCONNECT_INTEGRATIONS,
} from '../../constants/ActionTypes';

import {
  createNewConnectionError,
  createNewConnectionSuccess,
  getEmailAccountsError,
  getEmailAccounts,
  getEmailAccountsSuccess,
  deleteEmailAccountsError,
  deleteEmailAccountsSuccess,
  fetchUserEmailAccountError,
  fetchUserEmailAccountSuccess,
  setUserEmailAccountError,
  setUserEmailAccountSuccess,
  fixSpammedAccountError,
  fixSpammedAccountSuccess,
  getEmailAccountsWithStatsError,
  getEmailAccountsWithStatsStatsSuccess,
  getEmailProviderIdError,
  getEmailProviderIdSuccess,
  connectIntegrationError,
  connectIntegrationSuccess,
  disconnectIntegrationsSuccess,
  disconnectIntegrationsError,
} from '../actions/Integrations';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { getUserDetails, userSignOut } from '../actions/Auth';

const createNewConnectionRequest = async (
  imapEmail,
  imapPort,
  imapTLS,
  imapPassword,
  smtpEmail,
  smtpPort,
  smtpTLS,
  smtpPassword,
  smtpHost,
  imapHost,
  websiteId,
  fullName,
  email,
  oathProvider,
  isOAth,
  OathToken,
  OathExpiresIn,
  OathScope,
  OathTokenType,
  OathrefreshToken,
  code
) =>
  await axios
    .post(`${baseUrl}/admin/email-accounts`, {
      imapEmail,
      imapPort,
      imapTLS,
      imapPassword,
      smtpEmail,
      smtpPort,
      smtpTLS,
      smtpPassword,
      smtpHost,
      imapHost,
      websiteId,
      fullName,
      email,
      oathProvider,
      isOAth,
      OathToken,
      OathExpiresIn,
      OathScope,
      OathTokenType,
      OathrefreshToken,
      code,
    })
    .then((response) => response)
    .catch((error) => error);
const createConnectIntegrationRequest = async (integration, websiteId, code) =>
  await axios
    .post(`${baseUrl}/admin/websites/connect-integration`, {
      integration,
      websiteId,
      code,
    })
    .then((response) => response)
    .catch((error) => error);
const disconnectIntegrationRequest = async ( integration,websiteId,disconnectIntegration) =>
  await axios
    .post(`${baseUrl}/admin/websites/connect-integration`, {
      integration,
      websiteId,
      integrationDisconnect:disconnectIntegration,
    })
    .then((response) => response)
    .catch((error) => error);
const fixSpammedAccountRequest = async (emailAccountId, websiteId) =>
  await axios
    .post(`${baseUrl}/admin/email-accounts/unmark-spam`, {
      websiteId,
      emailAccountId,
    })
    .then((response) => response)
    .catch((error) => error);

const getEmailAccountsRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/email-accounts?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);
const getEmailAccountsWithStatsRequest = async (accountIds) =>
  await axios
    .get(`${baseUrl}/admin/email-accounts/stats?emailAccountsIds=${accountIds}`)
    .then((response) => response)
    .catch((error) => error);

const setEmailAccountsRequest = async (
  emailAccountId,
  messagesPerDayLimit,
  firstname,
  lastname,
  company,
  position,
  phoneNumber,
  signatureHTML,
  emailWarmUp
) =>
  await axios
    .put(`${baseUrl}/admin/email-accounts`, {
      emailAccountId,
      messagesPerDayLimit,
      firstname,
      lastname,
      company,
      position,
      phoneNumber,
      signatureHTML,
      emailWarmUp,
    })
    .then((response) => response)
    .catch((error) => error);

const fetchEmailAccountRequest = async (accountId) =>
  await axios
    .get(`${baseUrl}/admin/email-accounts/${accountId}`)
    .then((response) => response)
    .catch((error) => error);
const getEmailProviderIdRequest = async (provider) =>
  await axios
    .get(`${baseUrl}/admin/email-provider/${provider}`)
    .then((response) => response)
    .catch((error) => error);

const deleteEmailAccountsRequest = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/email-accounts/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* deleteExistingEmailAccounts({ payload }) {
  const { id, websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteEmailAccountsRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteEmailAccountsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(deleteEmailAccountsError());
      }
    } else {
      yield put(getEmailAccounts({ websiteId }));
      yield put(deleteEmailAccountsSuccess());
      yield put(generalSuccessMessage('Email account deleted successfully'));
    }
  } catch (error) {
    yield put(deleteEmailAccountsError());
    yield put(generalErrorMessage(error));
  }
}

function* createNewConnection({ payload }) {
  const {
    imapEmail,
    imapPort,
    imapTLS,
    imapPassword,
    smtpEmail,
    smtpPort,
    smtpTLS,
    smtpPassword,
    smtpHost,
    imapHost,
    websiteId,
    fullName,
    email,
    oathProvider,
    isOAth,
    OathToken,
    OathExpiresIn,
    OathScope,
    OathTokenType,
    OathrefreshToken,
    code,
  } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      createNewConnectionRequest,
      imapEmail,
      imapPort,
      imapTLS,
      imapPassword,
      smtpEmail,
      smtpPort,
      smtpTLS,
      smtpPassword,
      smtpHost,
      imapHost,
      websiteId,
      fullName,
      email,
      oathProvider,
      isOAth,
      OathToken,
      OathExpiresIn,
      OathScope,
      OathTokenType,
      OathrefreshToken,
      code
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        // yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(createNewConnectionError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(createNewConnectionError());
      }
    } else {
      yield put(createNewConnectionSuccess(callResponse.data));
      window.location.href = `https://${window?.location?.hostname}/email-account/${websiteId}/edit/${callResponse.data._id}`;
      if (!isOAth) {
        yield put(generalSuccessMessage('Email Connected Succesfully'));
      }
    }
  } catch (error) {
    yield put(createNewConnectionError());
    yield put(generalErrorMessage(error));
  }
}
function* fixSpammedAccount({ payload }) {
  const { emailAccountId, websiteId } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(fixSpammedAccountRequest, emailAccountId, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(fixSpammedAccountError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(fixSpammedAccountError());
      }
    } else {
      yield put(fixSpammedAccountSuccess(callResponse.data));
      yield put(getEmailAccounts({ websiteId }));
      yield put(generalSuccessMessage('Fixed successfully'));
    }
  } catch (error) {
    yield put(fixSpammedAccountError());
    yield put(generalErrorMessage(error));
  }
}
function* createConnectIntegration({ payload }) {
  const { code, websiteId, integration } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(createConnectIntegrationRequest, integration, websiteId, code);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectIntegrationError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectIntegrationError());
      }
    } else {
      yield put(connectIntegrationSuccess(callResponse.data));
      yield put(generalSuccessMessage('Account Connected Succesfully'));
    }
  } catch (error) {
    yield put(connectIntegrationError());
    yield put(generalErrorMessage(error));
  }
}
function* disconnectIntegration({ payload }) {
  const { disconnectIntegration, websiteId, integration } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(disconnectIntegrationRequest, integration, websiteId, disconnectIntegration);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(disconnectIntegrationsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(disconnectIntegrationsError());
      }
    } else {
      yield put(disconnectIntegrationsSuccess(callResponse.data));
      yield put(generalSuccessMessage('Account Disconnected Succesfully'));
      yield put(getUserDetails());
    }
  } catch (error) {
    yield put(disconnectIntegrationsError());
    yield put(generalErrorMessage(error));
  }
}

function* getAllEmailAccounts({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getEmailAccountsRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getEmailAccountsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getEmailAccountsError());
      }
    } else {
      yield put(getEmailAccountsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getEmailAccountsError());
    yield put(generalErrorMessage(error));
  }
}
function* getAllEmailAccountsWithStats({ payload }) {
  const { accountIds } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getEmailAccountsWithStatsRequest, accountIds);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getEmailAccountsWithStatsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getEmailAccountsWithStatsError());
      }
    } else {
      yield put(getEmailAccountsWithStatsStatsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getEmailAccountsWithStatsError());
    yield put(generalErrorMessage(error));
  }
}

function* fetchEmailAccount({ payload }) {
  const { accountId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchEmailAccountRequest, accountId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(fetchUserEmailAccountError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(fetchUserEmailAccountError());
      }
    } else {
      yield put(fetchUserEmailAccountSuccess(callResponse.data));
      // yield put(generalSuccessMessage('Existing Emails loaded successfully'))
    }
  } catch (error) {
    yield put(fetchUserEmailAccountError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchEmailProviderId({ payload }) {
  const { providerType } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getEmailProviderIdRequest, providerType);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getEmailProviderIdError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getEmailProviderIdError());
      }
    } else {
      yield put(getEmailProviderIdSuccess(callResponse.data));
      // yield put(generalSuccessMessage('Existing Emails loaded successfully'))
    }
  } catch (error) {
    yield put(getEmailProviderIdError());
    yield put(generalErrorMessage(error));
  }
}

function* emailAccountsRequest({ payload }) {
  const { accountData } = payload;
  const {
    emailAccountId,
    websiteId,
    messagesPerDayLimit,
    firstname,
    lastname,
    company,
    position,
    phoneNumber,
    signatureHTML,
    emailWarmUp,
  } = accountData;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      setEmailAccountsRequest,
      emailAccountId,
      messagesPerDayLimit,
      firstname,
      lastname,
      company,
      position,
      phoneNumber,
      signatureHTML,
      emailWarmUp
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(setUserEmailAccountError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(setUserEmailAccountError());
      }
    } else {
      yield put(setUserEmailAccountSuccess(callResponse.data));
      Router.push(`/integrations/${websiteId}`);
      yield put(generalSuccessMessage('Email data set successfully'));
    }
  } catch (error) {
    yield put(setUserEmailAccountError());
    yield put(generalErrorMessage(error));
  }
}

export function* postCreateConnection() {
  yield takeEvery(CREATE_NEW_CONNECTION, createNewConnection);
}
export function* postFixSpammedAccount() {
  yield takeEvery(FIX_SPAMMED_EMAIL_ACCOUNT, fixSpammedAccount);
}

export function* emailAccountsToDelete() {
  yield takeEvery(DELETE_EMAIL_ACCOUNTS, deleteExistingEmailAccounts);
}

export function* getExistingEmailAccounts() {
  yield takeEvery(GET_EMAIL_ACCOUNTS, getAllEmailAccounts);
}
export function* connectAppsIntegration() {
  yield takeEvery(CONNECT_IN_HOUSE_INTEGRATION, createConnectIntegration);
}
export function* disconnectIntegrationSaga() {
  yield takeEvery(DISCONNECT_INTEGRATIONS, disconnectIntegration);
}
export function* getExistingEmailAccountsWithStats() {
  yield takeEvery(GET_EMAIL_ACCOUNTS_STATS, getAllEmailAccountsWithStats);
}

export function* fetchUserEmailAccount() {
  yield takeEvery(FETCH_USER_EMAIL_ACCOUNT, fetchEmailAccount);
}
export function* fetchUserEmailProviderId() {
  yield takeEvery(GET_EMAIL_PROVIDER_ID, fetchEmailProviderId);
}

export function* putEmailAccountsRequest() {
  yield takeEvery(SET_USER_EMAIL_ACCOUNT, emailAccountsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(postCreateConnection),
    fork(postFixSpammedAccount),
    fork(getExistingEmailAccounts),
    fork(getExistingEmailAccountsWithStats),
    fork(fetchUserEmailAccount),
    fork(emailAccountsToDelete),
    fork(putEmailAccountsRequest),
    fork(fetchUserEmailProviderId),
    fork(connectAppsIntegration),
    fork(disconnectIntegrationSaga),
  ]);
}
