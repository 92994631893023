import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import {
  FORWARD_CSV,
  GET_CSV_BLACKLIST,
  DELETE_CSV_BLACKLIST,
  FORWARD_CSV_EMAIL,
  GET_EMAIL_CSV_BLACKLIST,
  DELETE_EMAIL_CSV_BLACKLIST,
} from '../../constants/ActionTypes';

import {
  forwardCSVError,
  forwardCSVSuccess,
  getCSVBlacklist,
  getCSVBlacklistError,
  getCSVBlacklistSuccess,
  deleteCSVBlacklistError,
  deleteCSVBlacklistSuccess,
  forwardCSVEmailError,
  forwardCSVEmailSuccess,
  getEmailCSVBlacklist,
  getEmailCSVBlacklistError,
  getEmailCSVBlacklistSuccess,
  deleteEmailCSVBlacklistError,
  deleteEmailCSVBlacklistSuccess,
} from '../actions/Blacklist';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

const forwardCSVRequest = async (websiteId, csv, domain) =>
  await axios
    .post(`${baseUrl}/admin/domain-blacklist`, { websiteId, csv, domain })
    .then((response) => response)
    .catch((error) => error);
const forwardEmailCSVRequest = async (websiteId, csv, email) =>
  await axios
    .post(`${baseUrl}/admin/email-blacklist`, { websiteId, csv, email })
    .then((response) => response)
    .catch((error) => error);

const getCSVBlacklistRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/domain-blacklist?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);
const getEmailCSVBlacklistRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/email-blacklist?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const deleteCSVBlacklistRequest = async (websiteId, csv) =>
  await axios
    .delete(`${baseUrl}/admin/domain-blacklist?websiteId=${websiteId}&csv=${csv}`)
    .then((response) => response)
    .catch((error) => error);
const deleteEmailCSVBlacklistRequest = async (websiteId, csv) =>
  await axios
    .delete(`${baseUrl}/admin/email-blacklist?websiteId=${websiteId}&csv=${csv}`)
    .then((response) => response)
    .catch((error) => error);

function* forwardCSV({ payload }) {
  const { websiteId, csv, domain } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(forwardCSVRequest, websiteId, csv, domain);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(forwardCSVError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(forwardCSVError());
      }
    } else {
      yield put(forwardCSVSuccess());
      yield put(getCSVBlacklist({ websiteId }));
      yield put(generalSuccessMessage('CSV Uploaded Successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('CSV Upload Failed...'));
    yield put(forwardCSVError());
  }
}
function* forwardEmailCSV({ payload }) {
  const { websiteId, csv, email } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(forwardEmailCSVRequest, websiteId, csv, email);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(forwardCSVEmailError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(forwardCSVEmailError());
      }
    } else {
      yield put(forwardCSVEmailSuccess());
      yield put(getEmailCSVBlacklist({ websiteId }));
      yield put(generalSuccessMessage('CSV Uploaded Successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('CSV Upload Failed...'));
    yield put(forwardCSVEmailError());
  }
}

function* fetchCSVBlacklist({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getCSVBlacklistRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getCSVBlacklistError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(getCSVBlacklistError());
      }
    } else {
      yield put(getCSVBlacklistSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getCSVBlacklistError());
    yield put(generalErrorMessage(error));
  }
}

function* fetchCSVEmailBlacklist({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getEmailCSVBlacklistRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getEmailCSVBlacklistError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(getEmailCSVBlacklistError());
      }
    } else {
      yield put(getEmailCSVBlacklistSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getEmailCSVBlacklistError());
    yield put(generalErrorMessage(error));
  }
}

function* deleteCSVBlacklist({ payload }) {
  const { websiteId, csv } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteCSVBlacklistRequest, websiteId, csv);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteCSVBlacklistError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(deleteCSVBlacklistError());
      }
    } else {
      yield put(deleteCSVBlacklistSuccess());
      yield put(getCSVBlacklist({ websiteId }));
      yield put(generalSuccessMessage('CSV Blacklist deleted Successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('CSV Blacklist delete Failed...'));
    yield put(deleteCSVBlacklistError());
  }
}
function* deleteEmailCSVBlacklist({ payload }) {
  const { websiteId, csv } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteEmailCSVBlacklistRequest, websiteId, csv);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteEmailCSVBlacklistError());
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
        yield put(deleteEmailCSVBlacklistError());
      }
    } else {
      yield put(deleteEmailCSVBlacklistSuccess());
      yield put(getEmailCSVBlacklist({ websiteId }));
      yield put(generalSuccessMessage('CSV Blacklist deleted Successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('CSV Blacklist delete Failed...'));
    yield put(deleteEmailCSVBlacklistError());
  }
}

export function* postBlacklist() {
  yield takeEvery(FORWARD_CSV, forwardCSV);
}

export function* getBlacklist() {
  yield takeEvery(GET_CSV_BLACKLIST, fetchCSVBlacklist);
}

export function* deleteBlacklist() {
  yield takeEvery(DELETE_CSV_BLACKLIST, deleteCSVBlacklist);
}
export function* deleteEmailBlacklist() {
  yield takeEvery(DELETE_EMAIL_CSV_BLACKLIST, deleteEmailCSVBlacklist);
}
export function* postEmailBlacklist() {
  yield takeEvery(FORWARD_CSV_EMAIL, forwardEmailCSV);
}

export function* getEmailBlacklist() {
  yield takeEvery(GET_EMAIL_CSV_BLACKLIST, fetchCSVEmailBlacklist);
}

export default function* rootSaga() {
  yield all([
    fork(postBlacklist),
    fork(getBlacklist),
    fork(deleteBlacklist),
    fork(postEmailBlacklist),
    fork(getEmailBlacklist),
    fork(deleteEmailBlacklist),
  ]);
}
