import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { ADD_CONTACT } from '../../constants/ActionTypes'

import { userSignOut } from '../actions/Auth'

import { generalSuccessMessage } from '../actions/GeneralMessage'
import { AddContactError, AddContactSuccess } from '../actions'

const addContactRequest = async (
  firstName,
  lastName,
  position,
  domain,
  email
) =>
  await axios
    .post(`${baseUrl}/admin/contact`, {
      firstName,
      lastName,
      position,
      domain,
      email,
    })
    .then((response) => response)
    .catch((error) => error)

// eslint-disable-next-line no-unused-vars
function* CallAddContact({ payload }) {
  setAuthorizationHeader()
  const { firstName, lastName, position, domain, email } = payload

  try {
    const callResponse = yield call(
      addContactRequest,
      firstName,
      lastName,
      position,
      domain,
      email
    )

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(AddContactError(callResponse.response.data.status))
      } else {
        yield put(
          AddContactError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(AddContactSuccess('Added Contacts successfully...'))
      yield put(generalSuccessMessage('Added Contacts successfully...'))
    }
  } catch (error) {
    yield put(AddContactError(error))
  }
}

export function* postRequestContacts() {
  yield takeEvery(ADD_CONTACT, CallAddContact)
}

export default function* rootSaga() {
  yield all([fork(postRequestContacts)])
}
