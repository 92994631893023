import {
  GET_PROSPECTOR,
  GET_PROSPECTOR_SUCCESS,
  GET_PROSPECTOR_ERROR,
  UPDATE_PROSPECTOR_FILTERS,
  GET_FILTERED_PROSPECTOR_DATA,
  CLEAR_ALL_STATES_PROSPECTOR,
  UPDATE_CURRENT_PROSPECTOR_PAGE,
  CREATE_SEARCHABLE_PROSPECTOR_DATA,
  SELECTED_PROSPECTOR,
  SWITCH_PROSPECTOR_TABS,
  GET_PEOPLE_PROSPECTOR,
  GET_PEOPLE_PROSPECTOR_SUCCESS,
  GET_PEOPLE_PROSPECTOR_ERROR,
  UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE,
  SELECTED_LIST_PROSPECTOR,
  GET_PROSPECTOR_COMPANY_INSIGHTS,
  GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS,
  GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR,
  GET_PROSPECTOR_STATUS,
  GET_PROSPECTOR_STATUS_SUCCESS,
  GET_PROSPECTOR_STATUS_ERROR,
  GET_PROSPECTOR_FILTERS,
  GET_PROSPECTOR_FILTERS_SUCCESS,
  GET_PROSPECTOR_FILTERS_ERROR,
  CREATE_PROSPECTOR_FILTER,
  CREATE_PROSPECTOR_FILTER_SUCCESS,
  CREATE_PROSPECTOR_FILTER_ERROR,
  DELETE_PROSPECTOR_FILTER,
  DELETE_PROSPECTOR_FILTER_SUCCESS,
  DELETE_PROSPECTOR_FILTER_ERROR,
  CLEAR_PROSPECTOR_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  prospector: false,
  getProspectorLoading: false,
  getProspectorError: false,
  prospectorData: [],
  getPeopleProspectorLoading: false,
  getPeopleProspectorError: false,
  peopleProspectorData: [],
  selectedProspector: [],
  selectedListProspector: [],
  currentProspectorTableData: [],
  searchProspectorData: [],
  currentPage: 1,
  currentPeoplePage: 1,
  companiesTab: 'people',
  prospectorCompanyInsights: null,
  getProspectorCompanyInsightsLoading: false,
  getProspectorCompanyInsightsError: false,
  prospectorStatus: [],
  getProspectorStatusLoading: false,
  prospectorFilters: [],
  getProspectorFiltersLoading: false,
  getProspectorFiltersError: false,
  createProspectorFilterSuccess: false,
  createProspectorFilterError: false,
  createProspectorFiltersLoading: false,
  deleteProspectorFilterSuccess: false,
  deleteProspectorFilterError: false,
  deleteProspectorFiltersLoading: false,
};

const Prospector = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWITCH_PROSPECTOR_TABS: {
      return {
        ...state,
        companiesTab: action.payload,
      };
    }
    case GET_PROSPECTOR: {
      return {
        ...state,
        getProspectorLoading: true,
      };
    }

    case GET_PROSPECTOR_SUCCESS: {
      return {
        ...state,
        getProspectorLoading: false,
        prospectorData: action.payload,
      };
    }
    case GET_PROSPECTOR_ERROR: {
      return {
        ...state,
        getProspectorLoading: false,
        getProspectorError: true,
      };
    }
    case GET_PROSPECTOR_FILTERS: {
      return {
        ...state,
        getProspectorFiltersLoading: true,
      };
    }

    case GET_PROSPECTOR_FILTERS_SUCCESS: {
      return {
        ...state,
        getProspectorFiltersLoading: false,
        prospectorFilters: action.payload,
      };
    }
    case GET_PROSPECTOR_FILTERS_ERROR: {
      return {
        ...state,
        getProspectorFiltersLoading: false,
        getProspectorFiltersError: true,
      };
    }

    case CREATE_PROSPECTOR_FILTER: {
      return {
        ...state,
        createProspectorFilterSuccess: false,
        createProspectorFiltersLoading: true,
      };
    }

    case CREATE_PROSPECTOR_FILTER_SUCCESS: {
      return {
        ...state,
        createProspectorFilterSuccess: true,
        createProspectorFiltersLoading: false,
      };
    }
    case CREATE_PROSPECTOR_FILTER_ERROR: {
      return {
        ...state,
        createProspectorFilterError: true,
        createProspectorFiltersLoading: false,
      };
    }
    case DELETE_PROSPECTOR_FILTER: {
      return {
        ...state,
        deleteProspectorFilterSuccess: false,
        deleteProspectorFiltersLoading: true,
      };
    }

    case DELETE_PROSPECTOR_FILTER_SUCCESS: {
      return {
        ...state,
        deleteProspectorFilterSuccess: true,
        deleteProspectorFiltersLoading: false,
      };
    }
    case DELETE_PROSPECTOR_FILTER_ERROR: {
      return {
        ...state,
        deleteProspectorFilterError: true,
        deleteProspectorFiltersLoading: false,
      };
    }
    case GET_PEOPLE_PROSPECTOR: {
      return {
        ...state,
        getPeopleProspectorLoading: true,
      };
    }

    case GET_PEOPLE_PROSPECTOR_SUCCESS: {
      return {
        ...state,
        getPeopleProspectorLoading: false,
        peopleProspectorData: action.payload,
      };
    }
    case GET_PEOPLE_PROSPECTOR_ERROR: {
      return {
        ...state,
        getPeopleProspectorLoading: false,
        getPeopleProspectorError: true,
      };
    }

    case SELECTED_PROSPECTOR: {
      return {
        ...state,
        selectedProspector: action.payload,
      };
    }
    case SELECTED_LIST_PROSPECTOR: {
      return {
        ...state,
        selectedListProspector: action.payload,
      };
    }
    case UPDATE_PROSPECTOR_FILTERS: {
      return {
        ...state,
        searchProspectorData: action.payload,
      };
    }
    case CREATE_SEARCHABLE_PROSPECTOR_DATA: {
      return {
        ...state,
        searchProspectorData: action.payload,
      };
    }
    case GET_FILTERED_PROSPECTOR_DATA: {
      return {
        ...state,
        currentProspectorTableData: action.payload,
        completed: true,
      };
    }
    case CLEAR_ALL_STATES_PROSPECTOR: {
      return {
        ...state,
        currentProspectorTableData: [],
      };
    }
    case UPDATE_CURRENT_PROSPECTOR_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE: {
      return {
        ...state,
        currentPeoplePage: action.payload,
      };
    }
    case GET_PROSPECTOR_COMPANY_INSIGHTS: {
      return {
        ...state,
        getProspectorCompanyInsightsLoading: true,
        getProspectorCompanyInsightsError: false,
      };
    }
    case GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS: {
      return {
        ...state,
        getProspectorCompanyInsightsLoading: false,
        prospectorCompanyInsights: action.payload,
      };
    }
    case GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR: {
      return {
        ...state,
        getProspectorCompanyInsightsLoading: false,
        getProspectorCompanyInsightsError: true,
      };
    }
    

    case GET_PROSPECTOR_STATUS: {
      return {
        ...state,
        getProspectorStatusLoading: true,
      };
    }
    case GET_PROSPECTOR_STATUS_SUCCESS: {
      return {
        ...state,
        getProspectorStatusLoading: false,
        prospectorStatus: action.payload,
      };
    }
    case GET_PROSPECTOR_STATUS_ERROR: {
      return {
        ...state,
        getProspectorStatusLoading: false,
        getProspectorStatusError: true,
      };
    }
    case CLEAR_PROSPECTOR_ERROR: {
      return {
        ...state,
        prospector: false,
        getProspectorLoading: false,
        getProspectorError: false,       
        getPeopleProspectorLoading: false,
        getPeopleProspectorError: false,                          
        prospectorCompanyInsights: null,
        getProspectorCompanyInsightsLoading: false,
        getProspectorCompanyInsightsError: false,    
        getProspectorStatusLoading: false,       
        getProspectorFiltersLoading: false,
        getProspectorFiltersError: false,
        createProspectorFilterSuccess: false,
        createProspectorFilterError: false,
        createProspectorFiltersLoading: false,
        deleteProspectorFilterSuccess: false,
        deleteProspectorFilterError: false,
        deleteProspectorFiltersLoading: false,
      };
    }
    default:
      return state;
  }
};

export default Prospector;
