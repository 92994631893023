import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import {
  GET_TAGS,
  MODIFY_TAGS,
  ADD_TAGS,
  DELETE_TAGS,
} from '../../constants/ActionTypes'
import { userSignOut } from '../actions/Auth'
import {
  getTagsSuccess,
  tagErrors,
  modifyTagSuccess,
  deleteTagSuccess,
  addTagsSuccess,
} from '../actions/Tags'

const addTagsAsync = async (websiteId, colour, text) =>
  await axios
    .post(`${baseUrl}/admin/tags`, {
      websiteId,
      colour,
      text: text.toUpperCase(),
    })
    .then((response) => response)
    .catch((error) => error)

const getTagsAsync = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/tags?websiteId=${websiteId}&omitDefault=true`)
    .then((response) => response)
    .catch((error) => error)

const modifyTagsAsync = async (tagId, colour, text) =>
  await axios
    .put(`${baseUrl}/admin/tags`, {
      tagId,
      colour,
      text: text.toUpperCase(),
    })
    .then((response) => response)
    .catch((error) => error)

const deleteTagsAsync = async (tagId, websiteId) =>
  await axios
    .delete(`${baseUrl}/admin/tags?tagId=${tagId}&websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error)

function* addRequestTags({ payload }) {
  const { websiteId, colour, text } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(addTagsAsync, websiteId, colour, text)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(tagErrors(callResponse.response.data.status))
      } else {
        yield put(tagErrors('Something went wrong please check your network'))
      }
    } else {
      const tagId = callResponse.data.tagId
      const textUpperCase = text.toUpperCase()
      yield put(
        addTagsSuccess({
          workspace: websiteId,
          _id: tagId,
          colour,
          text: textUpperCase,
        })
      )
    }
  } catch (error) {
    yield put(tagErrors(error))
  }
}

function* getRequestTags({ payload }) {
  const websiteId = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(getTagsAsync, websiteId)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(tagErrors(callResponse.response.data.status))
      } else {
        yield put(tagErrors('Something went wrong please check your network'))
      }
    } else {
      yield put(getTagsSuccess(callResponse.data))
    }
  } catch (error) {
    yield put(tagErrors(error))
  }
}

function* modifyRequestTags({ payload }) {
  const { tagId, colour, text } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(modifyTagsAsync, tagId, colour, text)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(tagErrors(callResponse.response.data.status))
      } else {
        yield put(tagErrors('Something went wrong please check your network'))
      }
    } else {
      const textUpperCase = text.toUpperCase()
      yield put(modifyTagSuccess({ tagId, text: textUpperCase, colour }))
    }
  } catch (error) {
    yield put(tagErrors(error))
  }
}

function* deleteRequestTags({ payload }) {
  const { tagId, websiteId } = payload
  setAuthorizationHeader()

  try {
    const callResponse = yield call(deleteTagsAsync, tagId, websiteId)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(tagErrors(callResponse.response.data.status))
      } else {
        yield put(tagErrors('Something went wrong please check your network'))
      }
    } else {
      yield put(deleteTagSuccess({ websiteId, tagId }))
    }
  } catch (error) {
    yield put(tagErrors(error))
  }
}

export function* addTagsCall() {
  yield takeEvery(ADD_TAGS, addRequestTags)
}

export function* getTagsCall() {
  yield takeEvery(GET_TAGS, getRequestTags)
}

export function* modifyTagsCall() {
  yield takeEvery(MODIFY_TAGS, modifyRequestTags)
}

export function* deleteTagsCall() {
  yield takeEvery(DELETE_TAGS, deleteRequestTags)
}

export default function* rootSaga() {
  yield all([
    fork(addTagsCall),
    fork(getTagsCall),
    fork(modifyTagsCall),
    fork(deleteTagsCall),
  ])
}
