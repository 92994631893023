import {
  LOADING_ONBOARDING,
  POST_ONBOARDING_DETAILS,
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILED,
  CLEAR_ONBOARDING_ERRORS,
  SET_SUCCESS_ONBOARDING_FALSE,
  CALL_WEBSITE_URL_HTTP,
  CALL_WEBSITE_URL_HTTPS,
  SEND_DEVELOPER_MAIL,
  SEND_DEVELOPER_MAIL_SUCCESS,
  SEND_DEVELOPER_MAIL_CLOSE,
  SEND_ONBOARDING_CREDENTIALS,
  SEND_ONBOARDING_CREDENTIALS_CLOSE,
  SEND_ONBOARDING_CREDENTIALS_SUCCESS,
  UPDATE_ACCOUNT_STATUS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  onboardingLoading: false,
  onboardingDetails: {},
  successDetails: false,
  error: false,
  errorMessage: '',
  websiteURLHttp: '',
  websiteURLHttps: '',
  sendDevSuccess: false,
  sendCredSuccess: false,
}

const Onboarding = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_ONBOARDING: {
      return {
        ...state,
        onboardingLoading: true,
      }
    }

    case POST_ONBOARDING_DETAILS: {
      return {
        ...state,
        onboardingDetails: action.payload,
        onboardingLoading: true,
      }
    }

    case ONBOARDING_SUCCESS: {
      return {
        ...state,
        successDetails: true,
        onboardingLoading: false,
      }
    }

    case ONBOARDING_FAILED: {
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        onboardingLoading: false,
      }
    }

    case CLEAR_ONBOARDING_ERRORS: {
      return {
        ...state,
        error: false,
        errorMessage: '',
        onboardingLoading: false,
      }
    }

    case SET_SUCCESS_ONBOARDING_FALSE: {
      return {
        ...state,
        successDetails: false,
      }
    }

    case CALL_WEBSITE_URL_HTTP: {
      return {
        ...state,
        websiteURLHttp: action.payload,
      }
    }

    case CALL_WEBSITE_URL_HTTPS: {
      return {
        ...state,
        websiteURLHttps: action.payload,
      }
    }

    case SEND_DEVELOPER_MAIL: {
      return {
        ...state,
      }
    }

    case SEND_DEVELOPER_MAIL_SUCCESS: {
      return {
        ...state,
        sendDevSuccess: true,
      }
    }

    case SEND_DEVELOPER_MAIL_CLOSE: {
      return {
        ...state,
        sendDevSuccess: false,
      }
    }

    case SEND_ONBOARDING_CREDENTIALS: {
      return {
        ...state,
      }
    }

    case SEND_ONBOARDING_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        sendCredSuccess: true,
      }
    }

    case SEND_ONBOARDING_CREDENTIALS_CLOSE: {
      return {
        ...state,
        sendCredSuccess: false,
      }
    }

    case UPDATE_ACCOUNT_STATUS: {
      return {
        ...state,
      }
    }

    default:
      return state
  }
}

export default Onboarding
