import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';
import {
  CALL_FETCH_ALL_FILTERS_HAPPIERLEADS,
  GET_START_AND_END_DATE,
  REMOVE_FILTER_HAPPIERLEAD_LEADS,
  UPDATE_FILTER_HAPPIERLEAD_LEADS,
  ADD_LEADS_TAG,
  MODIFY_LEADS_TAG,
  GET_TAGS_LEAD,
  DELETE_LEADS_TAG,
  CREATE_SELECTED_COLUMNS_LEADS,
  UNLOCK_LEADS,
  RESET_LEAD_SCORES,
} from '../../constants/ActionTypes';

import {
  fetchAllFilters,
  getLeadsData,
  tableDataLoading,
  error,
  deleteFilter,
  addFilter,
  modifyLeadsTag,
  successGetTagsLeads,
  successDeleteLeadsTag,
  successModifyLeadsTag,
  updateExistingTags,
  // updateCountLeads,
  // updateCurrentPage,
  successUlockLeads,
  addSelectedFieldsLeadsSuccess,
  resetLeadScoresError,
  resetLeadScoresSuccess,
} from '../actions/Leads';
import { userSignOut, getUserDetails } from '../actions/Auth';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import axios from 'axios';
import { generalErrorMessage, generalSuccessMessage } from '../actions/GeneralMessage';
import { setCSVParams } from '../actions/CSVfile';

const fetchFiltersHappierLeads = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/filters?websiteId=${websiteId}`)
    .then((savedFilters) => savedFilters)
    .catch((error) => error);

const fetchLeadsDataHappierLeads = async (dateFrom, dateTo, websiteId, page, allParams) => {
  allParams.dateFrom = dateFrom;
  allParams.dateTo = dateTo;
  const stringified = queryString.stringify(allParams);
  return await axios
    .get(`${baseUrl}/admin/leads-table?websiteId=${websiteId}&page=${page}&${stringified}`)
    .then((leadsInfo) => leadsInfo)
    .catch((error) => error);
};

const deleteFilterHappierLeads = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/filters/${id}`)
    .then((savedFilters) => savedFilters)
    .catch((error) => error);

const addFilterHappierLeads = async (websiteId, name, url) =>
  await axios
    .post(`${baseUrl}/admin/filters`, { websiteId, name, url })
    .then((leadsInfo) => leadsInfo)
    .catch((error) => error);

const addLeadsTagAsync = async (websiteId, colour, text) =>
  await axios
    .post(`${baseUrl}/admin/tags`, {
      websiteId,
      colour,
      text: text.toUpperCase(),
    })
    .then((response) => response)
    .catch((error) => error);

const modifyLeadsTagAsync = async (tagId, leadId) =>
  await axios
    .put(`${baseUrl}/admin/lead-tags`, {
      tagId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

const resetLeadScoringRequest = async (params) =>
  await axios
    .get(`${baseUrl}/admin/leads/reset-leadscores?${params}`)
    .then((response) => response)
    .catch((error) => error);

const getLeadsTagAsync = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/tags?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const deleteLeadsTagAsync = async (tagId, leadId) =>
  await axios
    .delete(`${baseUrl}/admin/lead-tags?tagId=${tagId}&leadId=${leadId}`)
    .then((response) => response)
    .catch((error) => error);

const createFeildsLeadTable = async (websiteId, column) =>
  await axios
    .post(`${baseUrl}/admin/columns`, {
      websiteId,
      column,
    })
    .then((response) => response)
    .catch((error) => error);

const getUlockLeads = async (companyId) =>
  await axios
    .post(`${baseUrl}/admin/unlock-leads`, { companyId })
    .then((authUser) => authUser)
    .catch((error) => error);

function* currentFiltersHappierLeads({ payload }) {
  if (setAuthorizationHeader()) {
    const websiteId = payload;
    try {
      const currentFilters = yield call(fetchFiltersHappierLeads, websiteId);

      if (currentFilters.message) {
        if (currentFilters.response) {
          const tokenStatus = isTokenExpired(currentFilters.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(error(currentFilters.response.data.status));
        } else {
          yield put(error('Something went wrong please check your network'));
        }
      }

      if (currentFilters.data) {
        yield put(fetchAllFilters(currentFilters.data));
      }
    } catch (error) {
      yield put(error(error));
    }
  }
}

function* getCurrentLeadsData({ payload }) {
  if (setAuthorizationHeader()) {
    const { dateFrom, dateTo, websiteId, page, allParams, enableLoading = true } = payload;
    allParams.dateFrom = dateFrom;
    allParams.dateTo = dateTo;
    const csvquery = queryString.stringify(allParams);

    try {
      if (enableLoading) {
        yield put(tableDataLoading('loading'));
      }
      yield put(setCSVParams(`websiteId=${websiteId}&${csvquery}`));
      const dateRangeLeadsData = yield call(fetchLeadsDataHappierLeads, dateFrom, dateTo, websiteId, page, allParams);

      if (dateRangeLeadsData.message) {
        if (dateRangeLeadsData.response) {
          const tokenStatus = isTokenExpired(dateRangeLeadsData.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(error(dateRangeLeadsData.response.data.status));
        } else {
          yield put(error('Something went wrong please check your network'));
        }
      } else {
        yield put(getLeadsData(dateRangeLeadsData.data));
      }
    } catch (error) {
      yield put(error(error));
    }
  }
}

function* resetLeadScores({ payload }) {
  const params = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(resetLeadScoringRequest, params);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage('Something went wrong. Try again later'));
        yield put(resetLeadScoresError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(resetLeadScoresError());
      }
    } else {
      yield put(generalSuccessMessage('LeadScores reset succesfully'));
      yield put(resetLeadScoresSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(resetLeadScoresError());
    yield put(generalErrorMessage(error));
  }
}

function* removeFilterFromLeads({ payload }) {
  const id = payload;
  setAuthorizationHeader();

  try {
    const response = yield call(deleteFilterHappierLeads, id);

    if (response.message) {
      if (response.response) {
        const tokenStatus = isTokenExpired(response.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(error(response.response.data.status));
      } else {
        yield put(error('Something went wrong, likely server or network error'));
      }
    } else {
      yield put(deleteFilter(id));
    }
  } catch (error) {
    yield put(error(error));
  }
}

function* addNewFiltersLeads({ payload }) {
  if (setAuthorizationHeader()) {
    const { websiteId, name, url } = payload;
    try {
      const addedFilterResponse = yield call(addFilterHappierLeads, websiteId, name, url);

      if (addedFilterResponse.message) {
        if (addedFilterResponse.response) {
          const tokenStatus = isTokenExpired(addedFilterResponse.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(error(addedFilterResponse.response.data.status));
        } else {
          yield put(error('Something went wrong please check your network'));
        }
      } else {
        yield put(addFilter(addedFilterResponse.data));
      }
    } catch (error) {
      yield put(error(error));
    }
  }
}

function* addRequestLeadsTag({ payload }) {
  const { websiteId, colour, text, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(addLeadsTagAsync, websiteId, colour, text);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(error(callResponse.response.data.status));
      } else {
        yield put(error('Something went wrong please check your network'));
      }
    } else {
      const tagId = callResponse.data.tagId;
      const textUpperCase = text.toUpperCase();
      yield put(
        updateExistingTags({
          websiteId,
          _id: tagId,
          tagId,
          leadId,
          colour,
          text: textUpperCase,
        })
      );
      yield put(
        modifyLeadsTag({
          websiteId,
          tagId,
          leadId,
          _id: tagId,
          colour,
          text: textUpperCase,
        })
      );
    }
  } catch (error) {
    yield put(error(error));
  }
}

function* modifyRequestLeadsTag({ payload }) {
  const { tagId, colour, text, leadId, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(modifyLeadsTagAsync, tagId, leadId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(error(callResponse.response.data.status));
      } else {
        yield put(error('Something went wrong please check your network'));
      }
    } else {
      const textUpperCase = text.toUpperCase();
      yield put(
        successModifyLeadsTag({
          _id: tagId,
          text: textUpperCase,
          colour,
          leadId,
          websiteId,
        })
      );
    }
  } catch (error) {
    yield put(error(error));
  }
}

function* getRequestLeadsTag({ payload }) {
  const websiteId = payload;
  if (setAuthorizationHeader()) {
    try {
      const callResponse = yield call(getLeadsTagAsync, websiteId);

      if (callResponse.message) {
        if (callResponse.response) {
          const tokenStatus = isTokenExpired(callResponse.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(generalErrorMessage(callResponse.response.data.status));
        } else {
          // yield put(generalErrorMessage('Something went wrong please check your network'));
        }
      } else {
        yield put(successGetTagsLeads(callResponse.data));
      }
    } catch (error) {
      yield put(generalErrorMessage(error));
    }
  }
}

function* deleteRequestLeadsTag({ payload }) {
  const { tagId, leadId, text } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteLeadsTagAsync, tagId, leadId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(error(callResponse.response.data.status));
      } else {
        yield put(error('Something went wrong please check your network'));
      }
    } else {
      const textUpperCase = text.toUpperCase();
      yield put(successDeleteLeadsTag({ leadId, tagId, text: textUpperCase }));
    }
  } catch (error) {
    yield put(error(error));
  }
}

function* postRequestFieldsProductTable({ payload }) {
  const { websiteId, column } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(createFeildsLeadTable, websiteId, column);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(addSelectedFieldsLeadsSuccess());
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

function* getRequestUlockLeads({ payload }) {
  const { companyId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getUlockLeads, companyId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(generalSuccessMessage('Lead revealed successfully'));
      const unlockedData = callResponse.data;
      yield put(successUlockLeads({ unlockedData, companyId }));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

export function* getLeadsFilters() {
  yield takeEvery(CALL_FETCH_ALL_FILTERS_HAPPIERLEADS, currentFiltersHappierLeads);
}

export function* fetchRangeLeadsData() {
  yield takeEvery(GET_START_AND_END_DATE, getCurrentLeadsData);
}

export function* currentFilterToDelete() {
  yield takeEvery(REMOVE_FILTER_HAPPIERLEAD_LEADS, removeFilterFromLeads);
}

export function* addCurrentFilter() {
  yield takeEvery(UPDATE_FILTER_HAPPIERLEAD_LEADS, addNewFiltersLeads);
}

export function* addLeadsTagCall() {
  yield takeEvery(ADD_LEADS_TAG, addRequestLeadsTag);
}

export function* modifyLeadsTagCall() {
  yield takeEvery(MODIFY_LEADS_TAG, modifyRequestLeadsTag);
}
export function* resetLeadScoresCall() {
  yield takeEvery(RESET_LEAD_SCORES, resetLeadScores);
}

export function* getLeadsTagCall() {
  yield takeEvery(GET_TAGS_LEAD, getRequestLeadsTag);
}

export function* deleteLeadsTagCall() {
  yield takeEvery(DELETE_LEADS_TAG, deleteRequestLeadsTag);
}

export function* postTableFieldsLeads() {
  yield takeEvery(CREATE_SELECTED_COLUMNS_LEADS, postRequestFieldsProductTable);
}

export function* callUlockLead() {
  yield takeEvery(UNLOCK_LEADS, getRequestUlockLeads);
}

export default function* rootSaga() {
  yield all([
    fork(getLeadsFilters),
    fork(fetchRangeLeadsData),
    fork(currentFilterToDelete),
    fork(addCurrentFilter),
    fork(addLeadsTagCall),
    fork(modifyLeadsTagCall),
    fork(getLeadsTagCall),
    fork(deleteLeadsTagCall),
    fork(postTableFieldsLeads),
    fork(callUlockLead),
    fork(resetLeadScoresCall),
  ]);
}

//https://rest.happierleads.com/v3/leads?dateFrom=12/33/2020&dateTo=03/10/2020
