import {
  GET_RECORDED_SESSION,
  GET_RECORDED_SESSION_SUCCESSFUL,
  GET_RECORDED_SESSION_FAILED,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  data: [],
  success: false,
  error: false,
}

const SessionPlayer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECORDED_SESSION: {
      return {
        ...state,
        data: [],
        loading: true,
        success: false,
        error: false,
      }
    }
    case GET_RECORDED_SESSION_SUCCESSFUL: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
      }
    }
    case GET_RECORDED_SESSION_FAILED: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    default:
      return state
  }
}

export default SessionPlayer
