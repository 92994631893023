import {
  GET_SALESFORCE_OWNERS,
  GET_SALESFORCE_OWNERS_SUCCESS,
  GET_SALESFORCE_OWNERS_ERROR,
  SAVE_NEW_SALESFORCE_COMPANY,
  SAVE_NEW_SALESFORCE_COMPANY_SUCCESS,
  SAVE_NEW_SALESFORCE_COMPANY_ERROR,
  SEARCH_SALESFORCE_COMPANY,
  SEARCH_SALESFORCE_COMPANY_SUCCESS,
  SEARCH_SALESFORCE_COMPANY_ERROR,
  CONNECT_EXISTING_SALESFORCE_COMPANY,
  CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS,
  CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR,
  CONNECT_SALESFORCE_CONTACTS,
  CONNECT_SALESFORCE_CONTACTS_SUCCESS,
  CONNECT_SALESFORCE_CONTACTS_ERROR,
  CLEAR_SALESFORCE_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  error: false,
  saveSalesforceCompanyLoading: false,
  saveSalesforceCompanyError: false,
  saveSalesforceCompanySuccess: false,
  searchSalesforceCompanyLoading: false,
  searchSalesforceCompanyError: false,
  connectSalesforceCompanyLoading: false,
  connectSalesforceCompanyError: false,
  connectSalesforceCompanySuccess: false,
  connectSalesforceContactsSuccess: false,
  connectSalesforceContactsLoading: false,
  connectSalesforceContactsError: false,
  salesforceContactEmail: '',
  salesforceOwners: [],
  salesforceContactsArr: [],
  existingSalesforceCompanies: [],
}

const Salesforce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SALESFORCE_OWNERS: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_SALESFORCE_OWNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        salesforceOwners: action.payload.data,
      }
    }
    case GET_SALESFORCE_OWNERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case SAVE_NEW_SALESFORCE_COMPANY: {
      return {
        ...state,
        saveSalesforceCompanyLoading: true,
      }
    }
    case SAVE_NEW_SALESFORCE_COMPANY_SUCCESS: {
      return {
        ...state,
        saveSalesforceCompanyLoading: false,
        saveSalesforceCompanySuccess: true,
      }
    }
    case SAVE_NEW_SALESFORCE_COMPANY_ERROR: {
      return {
        ...state,
        saveSalesforceCompanyLoading: false,
        saveSalesforceCompanyError: true,
      }
    }

    case SEARCH_SALESFORCE_COMPANY: {
      return {
        ...state,
        searchSalesforceCompanyLoading: true,
      }
    }
    case SEARCH_SALESFORCE_COMPANY_SUCCESS: {
      return {
        ...state,
        searchSalesforceCompanyLoading: false,
        existingSalesforceCompanies: action.payload.data.results,
      }
    }
    case SEARCH_SALESFORCE_COMPANY_ERROR: {
      return {
        ...state,
        searchSalesforceCompanyLoading: false,
        searchSalesforceCompanyError: true,
      }
    }

    case CONNECT_EXISTING_SALESFORCE_COMPANY: {
      return {
        ...state,
        connectSalesforceCompanyLoading: true,
      }
    }
    case CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS: {
      return {
        ...state,
        connectSalesforceCompanyLoading: false,
        connectSalesforceCompanySuccess: true,
      }
    }
    case CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR: {
      return {
        ...state,
        connectSalesforceCompanyLoading: false,
        connectSalesforceCompanyError: true,
      }
    }

    case CONNECT_SALESFORCE_CONTACTS: {
      return {
        ...state,
        connectSalesforceContactsLoading: true,
        salesforceContactEmail: action.payload.email,
        connectSalesforceContactsSuccess: false,
        connectSalesforceContactsError: false,
        salesforceContactsArr: [...state.salesforceContactsArr, action.payload.email],
      }
    }
    case CONNECT_SALESFORCE_CONTACTS_SUCCESS: {
      return {
        ...state,
        connectSalesforceContactsLoading: false,
        connectSalesforceContactsSuccess: true,
        connectSalesforceContactsError: false,
      }
    }
    case CONNECT_SALESFORCE_CONTACTS_ERROR: {
      return {
        ...state,
        connectSalesforceContactsLoading: false,
        connectSalesforceContactsError: true,
        connectSalesforceContactsSuccess: false,
      }
    }
    case CLEAR_SALESFORCE_CONTACTS_ERROR: {
      return {
        ...state,
        connectSalesforceContactsLoading: false,
        connectSalesforceContactsError: false,
        // connectSalesforceContactsSuccess: false,
      }
    }

    default:
      return state
  }
}

export default Salesforce
