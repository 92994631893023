import { GET_COUNTRY, GET_COUNTRY_SUCCESS } from '../../constants/ActionTypes'

export const getCountry = () => {
  return {
    type: GET_COUNTRY,
  }
}

export const getCountrySuccess = (data) => {
  return {
    type: GET_COUNTRY_SUCCESS,
    payload: data,
  }
}
