import {
  LEAD_FETCH_DATA_REQUEST,
  LEAD_FETCH_DATA_SUCCESS,
  LEAD_FETCH_DATA_ERROR,
  CLEAR_ALL_STATES_LEAD,
  ADD_TAGS_LEAD_PROFILE,
  MODIFY_TAGS_LEAD_PROFILE,
  SUCCESS_MODIFY_TAGS_LEAD_PROFILE,
  DELETE_TAGS_LEAD_PROFILE,
  SUCCESS_DELETE_TAGS_LEAD_PROFILE,
  SET_WEBSITE_ID_TAGS_PROFILE,
  SET_LEADS_QUERY_STRING,
  HIDE_LEAD_PROFILE,
  HIDE_LEAD_PROFILE_SUCCESS,
  HIDE_LEAD_PROFILE_FAILED,
  DELETE_LEADS_REQUEST,
  DELETE_LEADS_SUCCESS,
  DELETE_LEADS_ERROR,
  GET_LEAD_AI_SUMMARY,
  GET_LEAD_AI_SUMMARY_FAILED,
  GET_LEAD_AI_SUMMARY_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  error: null,
  data: {},
  leadsQueryString: '',
  disableHide: false,
  hideData: {},
  deleteSuccess: false,
  aiSummary: '',
  getAiSummaryLoading: false,
};

const Lead = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAD_FETCH_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        data: {},
        details: {},
        tagWebsiteId: '',
      };
    }
    case LEAD_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case LEAD_FETCH_DATA_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case DELETE_LEADS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_LEADS_SUCCESS: {
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      };
    }
    case DELETE_LEADS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_ALL_STATES_LEAD: {
      return {
        ...state,
        loading: false,
        error: null,
        data: {},
      };
    }

    case ADD_TAGS_LEAD_PROFILE: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case MODIFY_TAGS_LEAD_PROFILE: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case SUCCESS_MODIFY_TAGS_LEAD_PROFILE: {
      state.data.tags.push({ ...action.payload });
      return {
        ...state,
      };
    }

    case DELETE_TAGS_LEAD_PROFILE: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case SUCCESS_DELETE_TAGS_LEAD_PROFILE: {
      const indexOfDelete = state.data.tags.findIndex((tag) => tag._id === action.payload.tagId);
      state.data.tags.splice(indexOfDelete, 1);

      return {
        ...state,
      };
    }

    case SET_WEBSITE_ID_TAGS_PROFILE: {
      return {
        ...state,
        tagWebsiteId: action.payload,
      };
    }

    case SET_LEADS_QUERY_STRING: {
      return {
        ...state,
        leadsQueryString: action.payload,
      };
    }

    case HIDE_LEAD_PROFILE: {
      return {
        ...state,
        disableHide: true,
        hideData: action.payload,
      };
    }

    case HIDE_LEAD_PROFILE_FAILED: {
      return {
        ...state,
        disableHide: false,
      };
    }

    case HIDE_LEAD_PROFILE_SUCCESS: {
      return {
        ...state,
        disableHide: false,
      };
    }
    case GET_LEAD_AI_SUMMARY: {
      return {
        ...state,
        getAiSummaryLoading: true,
      };
    }

    case GET_LEAD_AI_SUMMARY_FAILED: {
      return {
        ...state,
        getAiSummaryLoading: false,
      };
    }

    case GET_LEAD_AI_SUMMARY_SUCCESS: {
      return {
        ...state,
        aiSummary: action.payload?.aISummary,
        getAiSummaryLoading: false,
      };
    }

    default:
      return state;
  }
};

export default Lead;
