import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import { GET_SLACK_CHANNELS } from '../../constants/ActionTypes';

// import { generalErrorMessage } from '../actions/GeneralMessage';
import { getSlackOwnersError, getSlackOwnersSuccess } from '../actions';

const getSlackChannelsRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/integrations/slack/channels?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

function* getSlackChannels({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getSlackChannelsRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getSlackOwnersError());
        // yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(getSlackOwnersError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getSlackOwnersSuccess(callResponse.data));
      // yield put(generalSuccessMessage(callResponse.data));
    }
  } catch (error) {
    yield put(getSlackOwnersError(error));
  }
}

export function* fetchSlackChannels() {
  yield takeEvery(GET_SLACK_CHANNELS, getSlackChannels);
}

export default function* rootSaga() {
  yield all([fork(fetchSlackChannels)]);
}
