import {
  CLEAR_MANAGE_SERVICES,
  MANAGE_SERVICES_ERROR,
  MANAGE_SERVICES_REQUEST,
  MANAGE_SERVICES_SUCCESS,
  UPDATE_WEBSITE_LIMIT_ERROR,
  UPDATE_WEBSITE_LIMIT_REQUEST,
  UPDATE_WEBSITE_LIMIT_SUCCESS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  updateServicesLoading: false,
  updateServicesError: false,
  updateServicesSuccess: false,
  updateWebsiteLimitSuccess: false,
  updateWebsiteLimitError: false,
  updateWebsiteLimitLoading: false,
};

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_SERVICES_REQUEST: {
      return {
        ...state,
        updateServicesLoading: true,
      };
    }

    case MANAGE_SERVICES_SUCCESS: {
      return {
        ...state,
        updateServicesLoading: false,
        updateServicesSuccess: true,
      };
    }
    case MANAGE_SERVICES_ERROR: {
      return {
        ...state,
        updateServicesError: true,
        updateServicesLoading: true,
      };
    }
    case UPDATE_WEBSITE_LIMIT_REQUEST: {
      return {
        ...state,
        updateWebsiteLimitLoading: true,
      };
    }

    case UPDATE_WEBSITE_LIMIT_SUCCESS: {
      return {
        ...state,
        updateWebsiteLimitLoading: false,
        updateWebsiteLimitSuccess: true,
      };
    }
    case UPDATE_WEBSITE_LIMIT_ERROR: {
      return {
        ...state,
        updateWebsiteLimitError: true,
        updateServicesLoading: false,
      };
    }

    case CLEAR_MANAGE_SERVICES: {
      return {
        ...state,
        updateServicesError: false,
        updateServicesSuccess: false,
        updateServicesLoading: false,
        updateWebsiteLimitSuccess: false,
        updateWebsiteLimitError: false,
        updateWebsiteLimitLoading: false,
      };
    }

    default:
      return state;
  }
};

export default Services;
