import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { POST_RATING_REQUEST } from '../../constants/ActionTypes'

import {} from '../actions/Websites'
import { getUserDetails, userSignOut } from '../actions/Auth'

import { generalSuccessMessage } from '../actions/GeneralMessage'
import { addRatingError, addRatingSuccess } from '../actions'

const postRatingRequest = async (accuracy, quality) =>
  await axios
    .put(`${baseUrl}/admin/rating`, { accuracy, quality })
    .then((response) => response)
    .catch((error) => error)

// eslint-disable-next-line no-unused-vars
function* CallPostRating({ payload }) {
  setAuthorizationHeader()
  const { accuracy, quality } = payload

  try {
    const callResponse = yield call(postRatingRequest, accuracy, quality)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(addRatingError(callResponse.response.data.status))
      } else {
        yield put(
          addRatingError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(getUserDetails())
      yield put(addRatingSuccess('Thanks for rating us...'))
      yield put(generalSuccessMessage('Thanks for rating us...'))
    }
  } catch (error) {
    yield put(addRatingError(error))
  }
}

export function* postRating() {
  yield takeEvery(POST_RATING_REQUEST, CallPostRating)
}

export default function* rootSaga() {
  yield all([fork(postRating)])
}
