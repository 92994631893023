import {
  SAVE_LEAD_SCORING_MODEL,
  SAVE_LEAD_SCORING_MODEL_SUCCESS,
  SAVE_LEAD_SCORING_MODEL_ERROR,
  GET_LEAD_SCORING_MODELS,
  GET_LEAD_SCORING_MODELS_SUCCESS,
  GET_LEAD_SCORING_MODELS_ERROR,
  DELETE_LEAD_SCORING_MODEL,
  DELETE_LEAD_SCORING_MODEL_SUCCESS,
  DELETE_LEAD_SCORING_MODEL_ERROR,
  UPDATE_LEAD_SCORING_MODEL,
  UPDATE_LEAD_SCORING_MODEL_SUCCESS,
  UPDATE_LEAD_SCORING_MODEL_ERROR,
  EXPORT_LEAD_SCORING_MODEL,
  EXPORT_LEAD_SCORING_MODEL_SUCCESS,
  EXPORT_LEAD_SCORING_MODEL_ERROR,
  GET_LEAD_SCORING_MODEL,
  GET_LEAD_SCORING_MODEL_SUCCESS,
  GET_LEAD_SCORING_MODEL_ERROR,
  IMPORT_LEAD_SCORING_MODEL,
  IMPORT_LEAD_SCORING_MODEL_SUCCESS,
  IMPORT_LEAD_SCORING_MODEL_ERROR,
  CLEAR_IMPORT_LEAD_SCORING_MODEL_ERROR,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_REQUEST,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_SUCCESS,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_ERROR,
  CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR,
} from '../../constants/ActionTypes';

export const saveLeadScoringModel = (data) => {
  return {
    type: SAVE_LEAD_SCORING_MODEL,
    payload: data,
  };
};

export const saveLeadScoringModelSuccess = (data) => {
  return {
    type: SAVE_LEAD_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const saveLeadScoringModelError = () => {
  return {
    type: SAVE_LEAD_SCORING_MODEL_ERROR,
  };
};

export const saveLeadScoringModelJobTitle = (data) => {
  return {
    type: SAVE_LEAD_JOB_TITLE_SCORING_MODEL,
    payload: data,
  };
};

export const saveLeadScoringModelJobTitleSuccess = (data) => {
  return {
    type: SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const saveLeadScoringModelJobTitleError = () => {
  return {
    type: SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR,
  };
};
export const clearSaveLeadScoringModelData = () => {
  return {
    type: CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR,
  };
};
export const exportLeadScoringModel = (data) => {
  return {
    type: EXPORT_LEAD_SCORING_MODEL,
    payload: data,
  };
};

export const exportLeadScoringModelSuccess = (data) => {
  return {
    type: EXPORT_LEAD_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const exportLeadScoringModelError = () => {
  return {
    type: EXPORT_LEAD_SCORING_MODEL_ERROR,
  };
};

export const deleteLeadScoringModelContactsRequest = (data) => {
  return {
    type: DELETE_LEAD_SCORING_MODEL_CONTACTS_REQUEST,
    payload: data,
  };
};

export const deleteLeadScoringModelContactsSuccess = (data) => {
  return {
    type: DELETE_LEAD_SCORING_MODEL_CONTACTS_SUCCESS,
    payload: data,
  };
};
export const deleteLeadScoringModelContactsError = () => {
  return {
    type: DELETE_LEAD_SCORING_MODEL_CONTACTS_ERROR,
  };
};

export const importLeadScoringModel = (data) => {
  return {
    type: IMPORT_LEAD_SCORING_MODEL,
    payload: data,
  };
};

export const importLeadScoringModelSuccess = (data) => {
  return {
    type: IMPORT_LEAD_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const importLeadScoringModelError = () => {
  return {
    type: IMPORT_LEAD_SCORING_MODEL_ERROR,
  };
};
export const clearImportLeadScoringModelError = () => {
  return {
    type: CLEAR_IMPORT_LEAD_SCORING_MODEL_ERROR,
  };
};

export const updateLeadScoringModel = (data) => {
  return {
    type: UPDATE_LEAD_SCORING_MODEL,
    payload: data,
  };
};

export const updateLeadScoringModelSuccess = (data) => {
  return {
    type: UPDATE_LEAD_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const updateLeadScoringModelError = () => {
  return {
    type: UPDATE_LEAD_SCORING_MODEL_ERROR,
  };
};

export const deleteLeadScoringModel = (data) => {
  return {
    type: DELETE_LEAD_SCORING_MODEL,
    payload: data,
  };
};

export const deleteLeadScoringModelSuccess = (msg) => {
  return {
    type: DELETE_LEAD_SCORING_MODEL_SUCCESS,
    payload: msg,
  };
};

export const deleteLeadScoringModelError = (error) => {
  return {
    type: DELETE_LEAD_SCORING_MODEL_ERROR,
    payload: error,
  };
};

export const getLeadScoringModels = (id) => {
  return {
    type: GET_LEAD_SCORING_MODELS,
    payload: id,
  };
};

export const getLeadScoringModelsSuccess = (data) => {
  return {
    type: GET_LEAD_SCORING_MODELS_SUCCESS,
    payload: data,
  };
};

export const getLeadScoringModelsError = () => {
  return {
    type: GET_LEAD_SCORING_MODELS_ERROR,
  };
};
export const getLeadScoringModel = (id) => {
  return {
    type: GET_LEAD_SCORING_MODEL,
    payload: id,
  };
};

export const getLeadScoringModelSuccess = (data) => {
  return {
    type: GET_LEAD_SCORING_MODEL_SUCCESS,
    payload: data,
  };
};

export const getLeadScoringModelError = () => {
  return {
    type: GET_LEAD_SCORING_MODEL_ERROR,
  };
};
