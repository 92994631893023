import {
  ADD_CAMPAIGN_TEMPLATE,
  ADD_CAMPAIGN_TEMPLATE_SUCCESS,
  ADD_CAMPAIGN_TEMPLATE_ERROR,
  EDIT_CAMPAIGN_TEMPLATE,
  EDIT_CAMPAIGN_TEMPLATE_SUCCESS,
  EDIT_CAMPAIGN_TEMPLATE_ERROR,
  CLEAR_CAMPAIGN_TEMPLATE_MESSAGE,
  GET_EXISTING_EMAIL_CAMPAIGNS_STATS,
  GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS,
  GET_EXISTING_EMAIL_CAMPAIGNS,
  GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS,
  GET_EXISTING_EMAIL_CAMPAIGNS_ERROR,
  GET_EMAIL_CAMPAIGNS,
  GET_EMAIL_CAMPAIGNS_SUCCESS,
  GET_EMAIL_CAMPAIGNS_ERROR,
  UPDATE_ACTIVE_SWITCH,
  UPDATE_ACTIVE_SWITCH_SUCCESS,
  UPDATE_ACTIVE_SWITCH_ERROR,
  PUT_EMAIL_CAMPAIGNS,
  PUT_EMAIL_CAMPAIGNS_SUCCESS,
  PUT_EMAIL_CAMPAIGNS_ERROR,
  TEST_EMAIL_SETTINGS,
  TEST_EMAIL_SETTINGS_SUCCESS,
  TEST_EMAIL_SETTINGS_ERROR,
  CREATE_NEW_CAMPAIGN,
  CREATE_NEW_CAMPAIGN_SUCCESS,
  CREATE_NEW_CAMPAIGN_ERROR,
  GET_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN_TEMPLATE_SUCCESS,
  GET_CAMPAIGN_TEMPLATE_ERROR,
  CONNECT_CONTACTS_CAMPAIGN,
  CONNECT_CONTACTS_CAMPAIGN_SUCCESS,
  CONNECT_CONTACTS_CAMPAIGN_ERROR,
  DELETE_EMAIL_CAMPAIGN,
  DELETE_EMAIL_CAMPAIGN_SUCCESS,
  DELETE_EMAIL_CAMPAIGN_ERROR,
  PREVIEW_EMAIL_REQUEST,
  PREVIEW_EMAIL_SUCCESS,
  PREVIEW_EMAIL_ERROR,
  STOP_EMAIL_CAMPAIGN,
  STOP_EMAIL_CAMPAIGN_SUCCESS,
  STOP_EMAIL_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN_TEMPLATE,
  DELETE_CAMPAIGN_TEMPLATE_SUCCESS,
  DELETE_CAMPAIGN_TEMPLATE_ERROR,
  ORDER_CAMPAIGN_TEMPLATES,
  ORDER_CAMPAIGN_TEMPLATES_SUCCESS,
  ORDER_CAMPAIGN_TEMPLATES_ERROR,
  SEND_PROSPECTS_TO_CAMPAIGN,
  SEND_PROSPECTS_TO_CAMPAIGN_ERROR,
  SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS,
  CLEAR_SEND_TO_CAMPAIGN,
  CLONE_EMAILCAMPAIGN,
  CLONE_EMAILCAMPAIGN_SUCCESS,
  CLONE_EMAILCAMPAIGN_ERROR,
  CLEAR_EMAILCAMPAIGN_ERROR,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  success: false,
  message: '',
  error: false,
  getExistingEmailCampaignsLoading: false,
  getExistingEmailCampaignsError: false,
  existingEmailCampaignsData: [],
  getEmailCampaignsLoading: false,
  getEmailCampaignsError: false,
  putEmailCampaignsLoading: false,
  putEmailCampaignsError: false,
  putEmailCampaignsSuccess: false,
  createNewCampaignsError: false,
  createNewCampaignsSuccess: false,
  createNewCampaignsLoading: false,
  deleteEmailCampaignLoading: false,
  deleteEmailCampaignError: false,
  cloneEmailCampaignLoading: false,
  cloneEmailCampaignError: false,
  cloneEmailCampaignSuccess: false,
  exportEmailCampaignContactsLoading: false,
  exportEmailCampaignContactsError: false,
  exportEmailCampaignContactsSuccess: false,
  deleteCampaignIndex: null,
  emailTemplatesSuccess: false,
  emailTemplatesLoading: false,
  emailTemplates: [],
  emailCampaignsData: {},
  createCampaignData: {},
  campaign: false,
  testLoader: false,
  testError: false,
  testData: {},
  connectContactsSuccess: false,
  sendContactsSuccess: false,
  sendContactsLoading: false,
  sentEmail: '',
  connectContactsLoading: false,
  connectContactsError: false,
  sendContactsError: false,
  sendToProspectorSuccess: false,
  sendToProspectorLoading: false,
  sendToProspectorError: false,
  sendPeopleToProspectorSuccess: false,
  sendPeopleToProspectorLoading: false,
  sendPeopleToProspectorError: false,
  stopCampaignLoading: false,
  previewLoading: false,
  stopCampaignSuccess: false,
  deleteTemplateSuccess: false,
  deleteTemplateError: false,
  deleteTemplateLoading: false,
  orderTemplatesSuccess: false,
  deleteEmailCampaignSuccess: false,
  orderTemplatesError: false,
};

const Campaign = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CAMPAIGN_TEMPLATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case ORDER_CAMPAIGN_TEMPLATES: {
      return {
        ...state,
        orderTemplatesSuccess: false,
      };
    }
    case ORDER_CAMPAIGN_TEMPLATES_SUCCESS: {
      return {
        ...state,
        orderTemplatesSuccess: true,
      };
    }
    case ORDER_CAMPAIGN_TEMPLATES_ERROR: {
      return {
        ...state,
        orderTemplatesSuccess: false,
        orderTemplatesError: true,
      };
    }
    case PREVIEW_EMAIL_REQUEST: {
      return {
        ...state,
        previewLoading: true,
      };
    }
    case EDIT_CAMPAIGN_TEMPLATE: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }
    case PREVIEW_EMAIL_SUCCESS: {
      return {
        ...state,
        previewLoading: false,
      };
    }
    case EDIT_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }

    case ADD_CAMPAIGN_TEMPLATE_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }
    case PREVIEW_EMAIL_ERROR: {
      return {
        ...state,
        previewLoading: false,
      };
    }
    case EDIT_CAMPAIGN_TEMPLATE_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }

    case CLEAR_CAMPAIGN_TEMPLATE_MESSAGE: {
      return {
        ...state,
        loader: false,
        success: false,
        error: false,
        message: '',
      };
    }
    case CLEAR_SEND_TO_CAMPAIGN: {
      return {
        ...state,
        sentEmail: '',
        sendContactsSuccess: false,
        sendToProspectorError: false,
        connectContactsSuccess: false
      };
    }
    case CLEAR_EMAILCAMPAIGN_ERROR: {
      return {
        cloneEmailCampaignSuccess: false,
        cloneEmailCampaignError: false,
        cloneEmailCampaignLoading: false,
        exportEmailCampaignContactsLoading: false
      };
    }

    case GET_EXISTING_EMAIL_CAMPAIGNS: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: true,
      };
    }
    case GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS: {
      return {
        ...state,
        existingEmailCampaignsStats: action.payload,
      };
    }

    case GET_EXISTING_EMAIL_CAMPAIGNS_STATS: {
      return {
        ...state,
      };
    }
    case GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: false,
        existingEmailCampaignsData: action.payload,
      };
    }
    case GET_EXISTING_EMAIL_CAMPAIGNS_ERROR: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: false,
        getExistingEmailCampaignsLoadingError: true,
      };
    }

    case GET_EMAIL_CAMPAIGNS: {
      return {
        ...state,
        getEmailCampaignsLoading: true,
      };
    }
    case GET_EMAIL_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        getEmailCampaignsLoading: false,
        emailCampaignsData: action.payload,
      };
    }
    case GET_EMAIL_CAMPAIGNS_ERROR: {
      return {
        ...state,
        getEmailCampaignsLoading: false,
        getEmailCampaignsLoadingError: true,
      };
    }

    case PUT_EMAIL_CAMPAIGNS: {
      return {
        ...state,
        putEmailCampaignsLoading: true,
      };
    }
    case PUT_EMAIL_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        putEmailCampaignsLoading: false,
        putEmailCampaignsSuccess: true,
        loader: false,
        emailCampaignsData: action.payload,
      };
    }
    case PUT_EMAIL_CAMPAIGNS_ERROR: {
      return {
        ...state,
        putEmailCampaignsLoading: false,
        putEmailCampaignsError: true,
        loader: false,
      };
    }

    case CREATE_NEW_CAMPAIGN: {
      return {
        ...state,
        createNewCampaignsLoading: true,
      };
    }
    case CREATE_NEW_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        createNewCampaignsLoading: false,
        createNewCampaignsSuccess: true,
        createCampaignData: action.payload?.emailCampaign,
      };
    }
    case CREATE_NEW_CAMPAIGN_ERROR: {
      return {
        ...state,
        createNewCampaignsLoading: false,
        createNewCampaignsError: true,
      };
    }

    case UPDATE_ACTIVE_SWITCH: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: true,
        loader: true,
      };
    }

    case DELETE_EMAIL_CAMPAIGN: {
      return {
        ...state,
        deleteEmailCampaignLoading: true,
        deleteCampaignIndex: action.payload.index,
        // loader: true,
      };
    }

    case DELETE_EMAIL_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        deleteEmailCampaignLoading: false,
        deleteEmailCampaignSuccess: true,
      };
    }

    case DELETE_EMAIL_CAMPAIGN_ERROR: {
      return {
        ...state,
        deleteEmailCampaignLoading: false,
        deleteEmailCampaignError: true,
      };
    }
    case CLONE_EMAILCAMPAIGN: {
      return {
        ...state,
        cloneEmailCampaignLoading: true,
        cloneCampaignIndex: action.payload.index,
        // loader: true,
      };
    }

    case CLONE_EMAILCAMPAIGN_SUCCESS: {
      return {
        ...state,
        cloneEmailCampaignLoading: false,
        cloneEmailCampaignSuccess: true,
      };
    }

    case CLONE_EMAILCAMPAIGN_ERROR: {
      return {
        ...state,
        cloneEmailCampaignLoading: false,
        cloneEmailCampaignError: true,
      };
    }
    case EXPORT_EMAIL_CAMPAIGN_CONTACTS: {
      return {
        ...state,
        exportEmailCampaignContactsLoading: true,
        exportEmailCampaignContactsIndex: action.payload.index,
      };
    }

    case EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS: {
      return {
        ...state,
        exportEmailCampaignContactsLoading: false,
        exportEmailCampaignContactsSuccess: true,
      };
    }

    case EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR: {
      return {
        ...state,
        exportEmailCampaignContactsLoading: false,
        exportEmailCampaignContactsError: true,
      };
    }

    case DELETE_CAMPAIGN_TEMPLATE: {
      return {
        ...state,
        deleteTemplateLoading: true,

        // loader: true,
      };
    }

    case DELETE_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deleteTemplateLoading: false,
        deleteTemplateSuccess: true,
      };
    }

    case DELETE_CAMPAIGN_TEMPLATE_ERROR: {
      return {
        ...state,
        deleteTemplateLoading: false,
        deleteTemplateSuccess: false,
        deleteTemplateError: true,
      };
    }

    case STOP_EMAIL_CAMPAIGN: {
      return {
        ...state,
        stopCampaignLoading: true,
        // loader: true,
      };
    }
    case STOP_EMAIL_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        stopCampaignLoading: false,
        stopCampaignSuccess: true,
        // loader: true,
      };
    }
    case STOP_EMAIL_CAMPAIGN_ERROR: {
      return {
        ...state,
        stopCampaignLoading: false,
        stopCampaignSuccess: false,
        // loader: true,
      };
    }

    case UPDATE_ACTIVE_SWITCH_SUCCESS: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: false,
        success: true,
      };
    }
    case UPDATE_ACTIVE_SWITCH_ERROR: {
      return {
        ...state,
        getExistingEmailCampaignsLoading: false,
        getExistingEmailCampaignsLoadingError: true,
      };
    }

    case TEST_EMAIL_SETTINGS: {
      return {
        ...state,
        testLoader: true,
      };
    }
    case TEST_EMAIL_SETTINGS_SUCCESS: {
      return {
        ...state,
        testLoader: false,
        testData: action.payload,
      };
    }
    case TEST_EMAIL_SETTINGS_ERROR: {
      return {
        ...state,
        testLoader: false,
        testError: true,
      };
    }

    case GET_CAMPAIGN_TEMPLATE: {
      return {
        ...state,
        emailTemplatesLoading: true,
      };
    }
    case GET_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        emailTemplatesLoading: false,
        emailTemplates: action.payload,
      };
    }
    case GET_CAMPAIGN_TEMPLATE_ERROR: {
      return {
        ...state,
        emailTemplatesLoading: false,
        emailTemplatesError: true,
      };
    }
    case CONNECT_CONTACTS_CAMPAIGN: {
      return {
        ...state,
        sendContactsLoading: true,
        sentEmail: action.payload.email,
        sendContactsSuccess: false,
        sendContactsError: false,
      };
    }
    case CONNECT_CONTACTS_CAMPAIGN_ERROR: {
      return {
        ...state,
        sendContactsLoading: false,
        sendContactsError: true,
        sendContactsSuccess: false,
      };
    }
    case CONNECT_CONTACTS_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        sendContactsLoading: false,
        connectContactsError: false,
        connectContactsSuccess: true,
      };
    }
    case SEND_PROSPECTS_TO_CAMPAIGN: {
      return {
        ...state,
        sendToProspectorLoading: true,
        sendToProspectorSuccess: false,
        sendToProspectorError: false,
      };
    }
    case SEND_PROSPECTS_TO_CAMPAIGN_ERROR: {
      return {
        ...state,
        sendToProspectorLoading: false,
        sendToProspectorError: true,
        sendToProspectorSuccess: false,
      };
    }
    case SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        sendToProspectorLoading: false,
        sendToProspectorError: false,
        sendToProspectorSuccess: true,
      };
    }
    case SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN: {
      return {
        ...state,
        sendPeopleToProspectorLoading: true,
        sendPeopleToProspectorSuccess: false,
        sendPeopleToProspectorError: false,
      };
    }
    case SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR: {
      return {
        ...state,
        sendPeopleToProspectorLoading: false,
        sendPeopleToProspectorError: true,
        sendPeopleToProspectorSuccess: false,
      };
    }
    case SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        sendPeopleToProspectorLoading: false,
        sendPeopleToProspectorError: false,
        sendPeopleToProspectorSuccess: true,
      };
    }
    default:
      return state;
  }
};

export default Campaign;
