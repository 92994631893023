import {
  GET_COMMENTS,
  CREATE_COMMENT,
  MODIFY_COMMENT,
  DELETE_COMMENT,
  GET_COMMENTS_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  comments: 'loading',
}

const Comments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMENTS: {
      return {
        ...state,
      }
    }
    case CREATE_COMMENT: {
      return {
        ...state,
      }
    }
    case MODIFY_COMMENT: {
      return {
        ...state,
      }
    }

    case DELETE_COMMENT: {
      return {
        ...state,
      }
    }

    case GET_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: action.payload,
      }
    }

    default:
      return state
  }
}

export default Comments
