import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { WEBHOOK_UPDATE_REQUEST, SEND_TEST_URL } from '../../constants/ActionTypes';

import { webhookUpdateError, webhookUpdateSuccess, sendTestSuccessWebhook } from '../actions/Webhooks';
import { userSignOut } from '../actions/Auth';
import { generalErrorMessage, generalSuccessMessage } from '../actions';

const updateWebhookRequest = async (webhook, websiteId) =>
  await axios
    .put(`${baseUrl}/admin/websites/${websiteId}`, { webhook })
    .then((response) => response)
    .catch((error) => error);

const sendTestWebhookRequest = async (url) =>
  await axios
    .post(`${baseUrl}/admin/webhooks/webhook-test-request?webhook=${url}`, {
      url,
    })
    .then((response) => response)
    .catch((error) => error);

function* updateCallWebhook({ payload }) {
  const { webhook, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateWebhookRequest, webhook, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(webhookUpdateError(callResponse.response.data.status));
      } else {
        yield put(webhookUpdateError('Something went wrong please check your network'));
      }
    } else {
      yield put(webhookUpdateSuccess());
    }
  } catch (error) {
    yield put(webhookUpdateError(error));
  }
}

function* postSendTestWebhook({ payload }) {
  const url = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(sendTestWebhookRequest, url);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(webhookUpdateError(callResponse.response.data));
        yield put(generalErrorMessage(callResponse.response.data));
      } else {
        yield put(generalErrorMessage(callResponse.response.data));
        yield put(webhookUpdateError('Something went wrong please check your network'));
      }
    } else {
      yield put(sendTestSuccessWebhook('Succesful'));
      yield put(generalSuccessMessage('Succesful'));
    }
  } catch (error) {
    yield put(webhookUpdateError(error));
  }
}

export function* putRequestWebhook() {
  yield takeEvery(WEBHOOK_UPDATE_REQUEST, updateCallWebhook);
}

export function* sendTestCallWebhook() {
  yield takeEvery(SEND_TEST_URL, postSendTestWebhook);
}

export default function* rootSaga() {
  yield all([fork(putRequestWebhook), fork(sendTestCallWebhook)]);
}
