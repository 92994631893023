import { SET_SELECTED_WEBSITE } from '../../constants/ActionTypes'

const INIT_STATE = {
  selectedWebsite: '',
}

const SelectedWebsite = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_WEBSITE: {
      return {
        ...state,
        selectedWebsite: action.payload,
      }
    }
    default:
      return state
  }
}

export default SelectedWebsite
