/* eslint-disable no-unused-vars */
import {
  CREATE_NEW_CONNECTION,
  CREATE_NEW_CONNECTION_SUCCESS,
  CREATE_NEW_CONNECTION_ERROR,
  GET_EMAIL_ACCOUNTS,
  GET_EMAIL_ACCOUNTS_SUCCESS,
  GET_EMAIL_ACCOUNTS_ERROR,
  DELETE_EMAIL_ACCOUNTS,
  DELETE_EMAIL_ACCOUNTS_SUCCESS,
  DELETE_EMAIL_ACCOUNTS_ERROR,
  FETCH_USER_EMAIL_ACCOUNT,
  FETCH_USER_EMAIL_ACCOUNT_SUCCESS,
  FETCH_USER_EMAIL_ACCOUNT_ERROR,
  SET_USER_EMAIL_ACCOUNT,
  SET_USER_EMAIL_ACCOUNT_SUCCESS,
  SET_USER_EMAIL_ACCOUNT_ERROR,
  FIX_SPAMMED_EMAIL_ACCOUNT,
  FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS,
  FIX_SPAMMED_EMAIL_ACCOUNT_ERROR,
  GET_EMAIL_ACCOUNTS_STATS,
  GET_EMAIL_ACCOUNTS_STATS_SUCCESS,
  GET_EMAIL_ACCOUNTS_STATS_ERROR,
  GET_EMAIL_PROVIDER_ID,
  GET_EMAIL_PROVIDER_ID_SUCCESS,
  GET_EMAIL_PROVIDER_ID_ERROR,
  CLEAR_CREATE_NEW_CONNECTION,
  CONNECT_IN_HOUSE_INTEGRATION,
  CONNECT_IN_HOUSE_INTEGRATION_ERROR,
  CONNECT_IN_HOUSE_INTEGRATION_SUCCESS,
  DISCONNECT_INTEGRATIONS,
  DISCONNECT_INTEGRATIONS_SUCCESS,
  DISCONNECT_INTEGRATIONS_ERROR,
} from '../../constants/ActionTypes';

export const getEmailAccounts = (id) => {
  return {
    type: GET_EMAIL_ACCOUNTS,
    payload: id,
  };
};

export const getEmailAccountsSuccess = (data) => {
  return {
    type: GET_EMAIL_ACCOUNTS_SUCCESS,
    payload: data,
  };
};

export const getEmailAccountsError = () => {
  return {
    type: GET_EMAIL_ACCOUNTS_ERROR,
  };
};
export const getEmailAccountsWithStats = (id) => {
  return {
    type: GET_EMAIL_ACCOUNTS_STATS,
    payload: id,
  };
};

export const getEmailAccountsWithStatsStatsSuccess = (data) => {
  return {
    type: GET_EMAIL_ACCOUNTS_STATS_SUCCESS,
    payload: data,
  };
};

export const getEmailAccountsWithStatsError = () => {
  return {
    type: GET_EMAIL_ACCOUNTS_STATS_ERROR,
  };
};

export const createNewConnection = (id) => {
  return {
    type: CREATE_NEW_CONNECTION,
    payload: id,
  };
};

export const createNewConnectionSuccess = (data) => {
  return {
    type: CREATE_NEW_CONNECTION_SUCCESS,
    payload: data,
  };
};

export const createNewConnectionError = () => {
  return {
    type: CREATE_NEW_CONNECTION_ERROR,
  };
};
export const disconnectIntegrations = (id) => {
  return {
    type: DISCONNECT_INTEGRATIONS,
    payload: id,
  };
};

export const disconnectIntegrationsSuccess = (data) => {
  return {
    type: DISCONNECT_INTEGRATIONS_SUCCESS,
    payload: data,
  };
};

export const disconnectIntegrationsError = () => {
  return {
    type: DISCONNECT_INTEGRATIONS_ERROR,
  };
};
export const clearCreateNewConnection = () => {
  return {
    type: CLEAR_CREATE_NEW_CONNECTION,
  };
};
export const fixSpammedAccount = (id) => {
  return {
    type: FIX_SPAMMED_EMAIL_ACCOUNT,
    payload: id,
  };
};

export const fixSpammedAccountSuccess = (data) => {
  return {
    type: FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const fixSpammedAccountError = () => {
  return {
    type: FIX_SPAMMED_EMAIL_ACCOUNT_ERROR,
  };
};
export const connectIntegration = (data) => {
  return {
    type: CONNECT_IN_HOUSE_INTEGRATION,
    payload: data,
  };
};

export const connectIntegrationSuccess = (data) => {
  return {
    type: CONNECT_IN_HOUSE_INTEGRATION_SUCCESS,
    payload: data,
  };
};

export const connectIntegrationError = () => {
  return {
    type: CONNECT_IN_HOUSE_INTEGRATION_ERROR,
  };
};

export const deleteEmailAccounts = (id) => {
  return {
    type: DELETE_EMAIL_ACCOUNTS,
    payload: id,
  };
};
export const deleteEmailAccountsSuccess = (data) => {
  return {
    type: DELETE_EMAIL_ACCOUNTS_SUCCESS,
    payload: data,
  };
};

export const deleteEmailAccountsError = () => {
  return {
    type: DELETE_EMAIL_ACCOUNTS_ERROR,
  };
};

export const fetchUserEmailAccount = (id) => {
  return {
    type: FETCH_USER_EMAIL_ACCOUNT,
    payload: id,
  };
};
export const fetchUserEmailAccountSuccess = (data) => {
  return {
    type: FETCH_USER_EMAIL_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const fetchUserEmailAccountError = () => {
  return {
    type: FETCH_USER_EMAIL_ACCOUNT_ERROR,
  };
};

export const setUserEmailAccount = (data) => {
  return {
    type: SET_USER_EMAIL_ACCOUNT,
    payload: data,
  };
};

export const setUserEmailAccountSuccess = (data) => {
  return {
    type: SET_USER_EMAIL_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const setUserEmailAccountError = () => {
  return {
    type: SET_USER_EMAIL_ACCOUNT_ERROR,
  };
};

export const getEmailProviderId = (provider) => {
  return {
    type: GET_EMAIL_PROVIDER_ID,
    payload: provider,
  };
};

export const getEmailProviderIdSuccess = (data) => {
  return {
    type: GET_EMAIL_PROVIDER_ID_SUCCESS,
    payload: data,
  };
};

export const getEmailProviderIdError = () => {
  return {
    type: GET_EMAIL_PROVIDER_ID_ERROR,
  };
};
