import {
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  EDIT_PASSWORD_ERROR,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PASSWORD_REQUEST,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  editProfileLoading: false,
  editPasswordLoading: false,
}

const EditProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EDIT_PROFILE_REQUEST: {
      return {
        ...state,
        editProfileLoading: true,
      }
    }

    case EDIT_PROFILE_SUCCESS: {
      return {
        ...state,
        editProfileLoading: false,
      }
    }

    case EDIT_PROFILE_ERROR: {
      return {
        ...state,
        editProfileLoading: false,
      }
    }

    case EDIT_PASSWORD_REQUEST: {
      return {
        ...state,
        editPasswordLoading: true,
      }
    }
    case EDIT_PASSWORD_SUCCESS: {
      return {
        ...state,
        editPasswordLoading: false,
      }
    }
    case EDIT_PASSWORD_ERROR: {
      return {
        ...state,
        editPasswordLoading: false,
      }
    }

    default:
      return state
  }
}

export default EditProfile
