import {
  SAVE_LIST,
  SAVE_LIST_SUCCESS,
  SAVE_LIST_ERROR,
  GET_LISTS_STATS,
  GET_LISTS_STATS_SUCCESS,
  GET_LISTS,
  GET_LISTS_SUCCESS,
  GET_LISTS_ERROR,
  DELETE_LIST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_ERROR,
  UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_ERROR,
  UPDATE_LIST_CONTACT,
  UPDATE_LIST_CONTACT_SUCCESS,
  UPDATE_LIST_CONTACT_ERROR,
  GET_CONTACT_INSIGHT_INFO,
  GET_CONTACT_INSIGHT_INFO_SUCCESS,
  GET_CONTACT_INSIGHT_INFO_ERROR,
  EXPORT_LIST,
  EXPORT_LIST_SUCCESS,
  EXPORT_LIST_ERROR,
  GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_ERROR,
  IMPORT_LIST,
  IMPORT_LIST_SUCCESS,
  IMPORT_LIST_ERROR,
  CLEAR_IMPORT_LIST_ERROR,
  DELETE_LIST_CONTACTS_REQUEST,
  DELETE_LIST_CONTACTS_SUCCESS,
  DELETE_LIST_CONTACTS_ERROR,
  CLEAR_CREATE_LIST_ERROR,
} from '../../constants/ActionTypes';

export const saveList = (data) => {
  return {
    type: SAVE_LIST,
    payload: data,
  };
};

export const saveListSuccess = (data) => {
  return {
    type: SAVE_LIST_SUCCESS,
    payload: data,
  };
};

export const saveListError = () => {
  return {
    type: SAVE_LIST_ERROR,
  };
};
export const clearSaveListData = () => {
  return {
    type: CLEAR_CREATE_LIST_ERROR,
  };
};
export const exportList = (data) => {
  return {
    type: EXPORT_LIST,
    payload: data,
  };
};

export const exportListSuccess = (data) => {
  return {
    type: EXPORT_LIST_SUCCESS,
    payload: data,
  };
};

export const exportListError = () => {
  return {
    type: EXPORT_LIST_ERROR,
  };
};

export const deleteListContactsRequest = (data) => {
  return {
    type: DELETE_LIST_CONTACTS_REQUEST,
    payload: data,
  };
};

export const deleteListContactsSuccess = (data) => {
  return {
    type: DELETE_LIST_CONTACTS_SUCCESS,
    payload: data,
  };
};
export const deleteListContactsError = () => {
  return {
    type: DELETE_LIST_CONTACTS_ERROR,
  };
};

export const importList = (data) => {
  return {
    type: IMPORT_LIST,
    payload: data,
  };
};

export const importListSuccess = (data) => {
  return {
    type: IMPORT_LIST_SUCCESS,
    payload: data,
  };
};

export const importListError = () => {
  return {
    type: IMPORT_LIST_ERROR,
  };
};
export const clearImportListError = () => {
  return {
    type: CLEAR_IMPORT_LIST_ERROR,
  };
};

export const updateListContact = (data) => {
  return {
    type: UPDATE_LIST_CONTACT,
    payload: data,
  };
};

export const updateListContactSuccess = (data) => {
  return {
    type: UPDATE_LIST_CONTACT_SUCCESS,
    payload: data,
  };
};

export const updateListContactError = () => {
  return {
    type: UPDATE_LIST_CONTACT_ERROR,
  };
};

export const getContactInsightInfo = (data) => {
  return {
    type: GET_CONTACT_INSIGHT_INFO,
    payload: data,
  };
};

export const getContactInsightInfoSuccess = (data) => {
  return {
    type: GET_CONTACT_INSIGHT_INFO_SUCCESS,
    payload: data,
  };
};

export const getContactInsightInfoError = () => {
  return {
    type: GET_CONTACT_INSIGHT_INFO_ERROR,
  };
};

export const updateList = (data) => {
  return {
    type: UPDATE_LIST,
    payload: data,
  };
};

export const updateListSuccess = (data) => {
  return {
    type: UPDATE_LIST_SUCCESS,
    payload: data,
  };
};

export const updateListError = () => {
  return {
    type: UPDATE_LIST_ERROR,
  };
};

export const deleteList = (data) => {
  return {
    type: DELETE_LIST,
    payload: data,
  };
};

export const deleteListSuccess = (msg) => {
  return {
    type: DELETE_LIST_SUCCESS,
    payload: msg,
  };
};

export const deleteListError = (error) => {
  return {
    type: DELETE_LIST_ERROR,
    payload: error,
  };
};

export const getListsStats = (id) => {
  return {
    type: GET_LISTS_STATS,
    payload: id,
  };
};

export const getListsStatsSuccess = (data) => {
  return {
    type: GET_LISTS_STATS_SUCCESS,
    payload: data,
  };
};

export const getLists = (id) => {
  return {
    type: GET_LISTS,
    payload: id,
  };
};

export const getListsSuccess = (data) => {
  return {
    type: GET_LISTS_SUCCESS,
    payload: data,
  };
};

export const getListsError = () => {
  return {
    type: GET_LISTS_ERROR,
  };
};
export const getList = (id) => {
  return {
    type: GET_LIST,
    payload: id,
  };
};

export const getListSuccess = (data) => {
  return {
    type: GET_LIST_SUCCESS,
    payload: data,
  };
};

export const getListError = () => {
  return {
    type: GET_LIST_ERROR,
  };
};
