import {
  CLEAR_MANAGE_SERVICES,
  MANAGE_SERVICES_ERROR,
  MANAGE_SERVICES_REQUEST,
  MANAGE_SERVICES_SUCCESS,
  UPDATE_WEBSITE_LIMIT_ERROR,
  UPDATE_WEBSITE_LIMIT_REQUEST,
  UPDATE_WEBSITE_LIMIT_SUCCESS,
} from '../../constants/ActionTypes'

export const updateServices = (data) => {
  return {
    type: MANAGE_SERVICES_REQUEST,
    payload: data,
  }
}

export const updateServicesSuccess = (data) => {
  return {
    type: MANAGE_SERVICES_SUCCESS,
    payload: data,
  }
}

export const updateServicesError = () => {
  return {
    type: MANAGE_SERVICES_ERROR,
  }
}
export const updateWebsiteLimits = (data) => {
  return {
    type: UPDATE_WEBSITE_LIMIT_REQUEST,
    payload: data,
  }
}

export const updateWebsiteLimitsSuccess = (data) => {
  return {
    type: UPDATE_WEBSITE_LIMIT_SUCCESS,
    payload: data,
  }
}

export const updateWebsiteLimitsError = () => {
  return {
    type: UPDATE_WEBSITE_LIMIT_ERROR,
  }
}
export const clearServicesError = () => {
  return {
    type: CLEAR_MANAGE_SERVICES,
  }
}
