import {
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  CLEAR_CONTACT_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  success: false,
  message: '',
  error: false,
}

const Contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CONTACT: {
      return {
        ...state,
        loader: true,
      }
    }

    case ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      }
    }

    case ADD_CONTACT_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      }
    }
    case CLEAR_CONTACT_ERROR: {
      return {
        success: false,
        error: false,
        loader: false,
      }
    }

    default:
      return state
  }
}

export default Contacts
