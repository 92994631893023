import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { SET_PERSONALIZED_DATA } from '../../constants/ActionTypes'

import { getUserDetails, userSignOut } from '../actions/Auth'

import { generalSuccessMessage } from '../actions/GeneralMessage'
import {
  setPersonalizedDataSuccess,
  setPersonalizedDataError,
} from '../actions'

const addPersonalizedDataRequest = async (
  leadQuantity,
  leadAccuracy,
  idealIndustry,
  countries,
  includeRoles,
  excludeRoles
) =>
  await axios
    .put(`${baseUrl}/admin/personalize`, {
      leadQuantity,
      leadAccuracy,
      idealIndustry,
      countries,
      includeRoles,
      excludeRoles,
    })
    .then((response) => response)
    .catch((error) => error)

function* addPersonalizedData({ payload }) {
  setAuthorizationHeader()
  const {
    leadQuantity,
    leadAccuracy,
    idealIndustry,
    countries,
    includeRoles,
    excludeRoles,
  } = payload

  try {
    const callResponse = yield call(
      addPersonalizedDataRequest,
      leadQuantity,
      leadAccuracy,
      idealIndustry,
      countries,
      includeRoles,
      excludeRoles
    )

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(setPersonalizedDataError(callResponse.response.data.status))
      } else {
        yield put(
          setPersonalizedDataError(
            'Something went wrong please check your network'
          )
        )
      }
    } else {
      yield put(getUserDetails())
      yield put(
        setPersonalizedDataSuccess({
          leadQuantity,
          leadAccuracy,
          idealIndustry,
          includeRoles,
          excludeRoles,
        })
      )
      yield put(generalSuccessMessage('Changes saved successfully'))
    }
  } catch (error) {
    yield put(setPersonalizedDataError(error))
  }
}

export function* setPersonalizedDataCall() {
  yield takeEvery(SET_PERSONALIZED_DATA, addPersonalizedData)
}

export default function* rootSaga() {
  yield all([fork(setPersonalizedDataCall)])
}
