import {
  SAVE_ICP_FILTERS,
  SAVE_ICP_FILTERS_SUCCESS,
  SAVE_ICP_FILTERS_ERROR,
  GET_ICP_FILTERS,
  GET_ICP_FILTERS_SUCCESS,
  GET_ICP_FILTERS_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  icpLoading: false,
  icpError: false,
  getIcpLoading: false,
  getIcpError: false,
  icpFilters: {},
}

const Hubspot = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_ICP_FILTERS: {
      return {
        ...state,
        icpLoading: true,
      }
    }
    case SAVE_ICP_FILTERS_SUCCESS: {
      return {
        ...state,
        icpLoading: false,
      }
    }
    case SAVE_ICP_FILTERS_ERROR: {
      return {
        ...state,
        icpLoading: false,
        icpError: true,
      }
    }
    case GET_ICP_FILTERS: {
      return {
        ...state,
        getIcpLoading: true,
      }
    }
    case GET_ICP_FILTERS_SUCCESS: {
      return {
        ...state,
        getIcpLoading: false,
        icpFilters: action.payload,
      }
    }
    case GET_ICP_FILTERS_ERROR: {
      return {
        ...state,
        getIcpLoading: false,
        getIcpError: true,
      }
    }

    default:
      return state
  }
}

export default Hubspot
