export const GenerateStringFromObjArr = (arr) => {
  const result = Object.entries(arr[0])
    ?.map(([key, value]) => `${key}: ${value}`)
    .join('\n ..');
  return result;
};

const timezones = {
  'America/Los_Angeles': 'Pacific Time',
  'America/Denver': 'Mountain Time',
  'America/Chicago': 'Central Time',
  'America/New_York': 'Eastern Time',
  UTC: 'UTC',
  'Europe/London': 'London',
  'Europe/Paris': 'Paris',
  'Europe/Athens': 'Athens',
  'Australia/Perth': 'Australia Western Standard Time',
  'Australia/Adelaide': 'Australia Central Standard Time',
  'Australia/Sydney': 'Australia Eastern Standard Time',
  'Asia/Dubai': 'Middle East Time (Dubai)',
  'Asia/Riyadh': 'Near East Time (Riyadh)',
  'Asia/Karachi': 'Pakistan Lahore Time',
  'Asia/Kolkata': 'India Standard Time',
  'Asia/Dhaka': 'Bangladesh Standard Time',
  'Asia/Ho_Chi_Minh': 'Vietnam Standard Time',
  'Asia/Tokyo': 'Japan Standard Time',
  'Pacific/Guadalcanal': 'Solomon Standard Time',
  'Pacific/Auckland': 'New Zealand Standard Time',
  'Pacific/Midway': 'Midway Islands Time',
  'Pacific/Honolulu': 'Hawaii Standard Time',
  'America/Anchorage': 'Alaska Standard Time',
  'America/Puerto_Rico': 'Puerto Rico and US Virgin Islands Time',
  'America/St_Johns': 'Canada Newfoundland Time',
  'America/Sao_Paulo': 'Brazil Eastern Time',
};

export const ObjectTimezones = Object.entries(timezones).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const getUserRole = (userHappierLeads) => {
  if (
    userHappierLeads.user === null ||
    userHappierLeads.user === undefined ||
    Object.keys(userHappierLeads.user)?.length === 0
  ) {
    return false;
  }
  if (userHappierLeads.user) {
    return userHappierLeads.user.role.toLowerCase();
  }
};
