import {
  WEBHOOK_UPDATE_REQUEST,
  WEBHOOK_UPDATE_SUCCESS,
  WEBHOOK_UPDATE_ERROR,
  SET_WEBHOOK_URL,
  WEBHOOK_CLEAR_MESSAGES,
  PERMIT_SETUP_SCRIPTS,
  REJECT_SETUP_SCRIPTS,
  SEND_TEST_URL,
  SET_WEBHOOK_WEBSITE_ID,
  SUCCESS_SEND_TEST_WEBHOOK,
  CLEAR_SEND_TEST_WEBHOOK,
} from '../../constants/ActionTypes';

export const webhookUpdateRequest = (data) => {
  return {
    type: WEBHOOK_UPDATE_REQUEST,
    payload: data,
  };
};

export const webhookUpdateSuccess = () => {
  return {
    type: WEBHOOK_UPDATE_SUCCESS,
  };
};

export const webhookUpdateError = () => {
  return {
    type: WEBHOOK_UPDATE_ERROR,
  };
};

export const setWebHookUrl = (data) => {
  return {
    type: SET_WEBHOOK_URL,
    payload: data,
  };
};

export const clearMessages = () => {
  return {
    type: WEBHOOK_CLEAR_MESSAGES,
  };
};

export const permitSetUpScript = () => {
  return {
    type: PERMIT_SETUP_SCRIPTS,
  };
};

export const preventSetUpScript = () => {
  return {
    type: REJECT_SETUP_SCRIPTS,
  };
};

export const sendTestURL = (url) => {
  return {
    type: SEND_TEST_URL,
    payload: url,
  };
};

export const setWebhookWebsiteId = (id) => {
  return {
    type: SET_WEBHOOK_WEBSITE_ID,
    payload: id,
  };
};

export const sendTestSuccessWebhook = (text) => {
  return {
    type: SUCCESS_SEND_TEST_WEBHOOK,
    payload: text,
  };
};
export const clearSendTestWebhook = () => {
  return {
    type: CLEAR_SEND_TEST_WEBHOOK,
  };
};
