import {
  SET_PERSONALIZED_DATA,
  SET_PERSONALIZED_DATA_SUCCESS,
  SET_PERSONALIZED_DATA_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  savedPersonalizedData: [],
  loadingPersonalizedData: false,
  setPersonalizedDataSuccess: false,
  setPersonalizedDataError: false,
}

const PersonalizeModal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PERSONALIZED_DATA: {
      return {
        ...state,
        loadingPersonalizedData: true,
      }
    }

    case SET_PERSONALIZED_DATA_SUCCESS: {
      return {
        ...state,
        savedPersonalizedData: action.payload,
        setPersonalizedDataSuccess: true,
        loadingPersonalizedData: false,
      }
    }

    case SET_PERSONALIZED_DATA_ERROR: {
      return {
        ...state,
        setPersonalizedDataError: true,
        loadingPersonalizedData: false,
      }
    }

    default:
      return state
  }
}

export default PersonalizeModal
