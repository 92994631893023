import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import {
  GET_PIPEDRIVE_OWNERS,
  SAVE_NEW_PIPEDRIVE_COMPANY,
  SEARCH_PIPEDRIVE_COMPANY,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY,
  CONNECT_PIPEDRIVE_CONTACTS,
} from '../../constants/ActionTypes';

import {
  getPipedriveOwnersError,
  getPipedriveOwnersSuccess,
  saveNewPipedriveCompanyError,
  saveNewPipedriveCompanySuccess,
  connectExistingPipedriveCompanyError,
  connectExistingPipedriveCompanySuccess,
  connectPipedriveContactsError,
  connectPipedriveContactsSuccess,
} from '../actions/Pipedrive';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { updateCountLeads, updateCurrentPage } from '../actions/Leads';
import { searchPipedriveCompanyError, searchPipedriveCompanySuccess } from '../actions';

const getPipedriveOwnersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/integrations/pipedrive/owner?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const searchPipedriveCompanyRequest = async (websiteId, searchTerm) =>
  await axios
    .get(`${baseUrl}/admin/integrations/pipedrive/company?websiteId=${websiteId}&searchTerm=${searchTerm}`)
    .then((response) => response)
    .catch((error) => error);

const saveNewPipedriveCompanyRequest = async (websiteId, domain, ownerId, leadId, name) =>
  await axios
    .post(`${baseUrl}/admin/integrations/pipedrive/companies`, {
      websiteId,
      domain,
      ownerId,
      leadId,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const connectPipedriveContactsRequest = async (websiteId, ownerId, email, name) =>
  await axios
    .post(`${baseUrl}/admin/integrations/pipedrive/contacts`, {
      websiteId,
      ownerId,
      email,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const connectExistingPipedriveCompanyRequest = async (websiteId, pipedriveCompanyId, leadId) =>
  await axios
    .post(`${baseUrl}/admin/integrations/pipedrive/connect`, {
      websiteId,
      pipedriveCompanyId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

function* getPipedriveOwners({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getPipedriveOwnersRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getPipedriveOwnersError());
        // yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(getPipedriveOwnersError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getPipedriveOwnersSuccess(callResponse.data));
      // yield put(generalSuccessMessage(callResponse.data));
    }
  } catch (error) {
    yield put(getPipedriveOwnersError(error));
  }
}

function* searchPipedriveCompany({ payload }) {
  const { websiteId, searchTerm } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(searchPipedriveCompanyRequest, websiteId, searchTerm);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(searchPipedriveCompanyError());
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(searchPipedriveCompanyError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(searchPipedriveCompanySuccess(callResponse.data));
    }
  } catch (error) {
    yield put(searchPipedriveCompanyError(error));
  }
}

function* saveNewPipedriveCompany({ payload }) {
  const { websiteId, domain, ownerId, leadId, name } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveNewPipedriveCompanyRequest, websiteId, domain, ownerId, leadId, name);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(saveNewPipedriveCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(saveNewPipedriveCompanyError());
      }
    } else {
      yield put(saveNewPipedriveCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company save Failed...'));
    yield put(saveNewPipedriveCompanyError());
  }
}

function* connectPipedriveContacts({ payload }) {
  const { websiteId, ownerId, email, name } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectPipedriveContactsRequest, websiteId, ownerId, email, name);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectPipedriveContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectPipedriveContactsError());
      }
    } else {
      yield put(connectPipedriveContactsSuccess());
      yield put(generalSuccessMessage('Contact saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(connectPipedriveContactsError());
  }
}

function* connectExistingPipedriveCompany({ payload }) {
  const { websiteId, pipedriveCompanyId, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectExistingPipedriveCompanyRequest, websiteId, pipedriveCompanyId, leadId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectExistingPipedriveCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectExistingPipedriveCompanyError());
      }
    } else {
      yield put(connectExistingPipedriveCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company connected successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company connection Failed...'));
    yield put(connectExistingPipedriveCompanyError());
  }
}

export function* fetchPipedriveOwners() {
  yield takeEvery(GET_PIPEDRIVE_OWNERS, getPipedriveOwners);
}

export function* postSaveNewPipedriveCompany() {
  yield takeEvery(SAVE_NEW_PIPEDRIVE_COMPANY, saveNewPipedriveCompany);
}

export function* postConnectPipedriveContacts() {
  yield takeEvery(CONNECT_PIPEDRIVE_CONTACTS, connectPipedriveContacts);
}

export function* postSearchPipedriveCompany() {
  yield takeEvery(SEARCH_PIPEDRIVE_COMPANY, searchPipedriveCompany);
}

export function* postConnectExistingPipedriveCompany() {
  yield takeEvery(CONNECT_EXISTING_PIPEDRIVE_COMPANY, connectExistingPipedriveCompany);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPipedriveOwners),
    fork(postSaveNewPipedriveCompany),
    fork(postConnectPipedriveContacts),
    fork(postSearchPipedriveCompany),
    fork(postConnectExistingPipedriveCompany),
  ]);
}
