import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { INVITE_MEMBERS_REQUEST } from '../../constants/ActionTypes'
import { userSignOut } from '../actions/Auth'
import { inviteMembersSuccess, inviteMembersError } from '../actions/Invite'

const inviteHttpCall = async (payload) => {
  return await axios
    .post(`${baseUrl}/admin/invite`, { invites: payload })
    .then((invite) => invite)
    .catch((error) => error)
}

function* inviteMembers({ payload }) {
  setAuthorizationHeader()

  try {
    const invite = yield call(inviteHttpCall, payload)

    if (invite.message) {
      if (invite.response) {
        const tokenStatus = isTokenExpired(invite.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }

        yield put(inviteMembersError(invite.response.data.status))
        if (invite.response.data.errors[0].msg != 'undefined') {
          yield put(inviteMembersError(invite.response.data.errors[0].msg))
        }
      } else {
        yield put(
          inviteMembersError('Something went wrong please check your network')
        )
      }
    } else {
      yield put(inviteMembersSuccess(invite.data))
    }
  } catch (error) {
    yield put(inviteMembersError(error))
  }
}

export function* inviteHook() {
  yield takeEvery(INVITE_MEMBERS_REQUEST, inviteMembers)
}

export default function* rootSaga() {
  yield all([fork(inviteHook)])
}
