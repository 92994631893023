import {
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_REQUEST,
  EDIT_PASSWORD_ERROR,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PASSWORD_REQUEST,
} from '../../constants/ActionTypes'

export const editProfileRequest = (data) => {
  return {
    type: EDIT_PROFILE_REQUEST,
    payload: data,
  }
}
export const editProfileError = () => {
  return {
    type: EDIT_PROFILE_ERROR,
  }
}

export const editProfileSuccess = () => {
  return {
    type: EDIT_PROFILE_SUCCESS,
  }
}

export const editPasswordRequest = (data) => {
  return {
    type: EDIT_PASSWORD_REQUEST,
    payload: data,
  }
}
export const editPasswordError = () => {
  return {
    type: EDIT_PASSWORD_ERROR,
  }
}

export const editPasswordSuccess = () => {
  return {
    type: EDIT_PASSWORD_SUCCESS,
  }
}
