import {
  GET_SLACK_CHANNELS,
  GET_SLACK_CHANNELS_SUCCESS,
  GET_SLACK_CHANNELS_ERROR,
} from '../../constants/ActionTypes'

export const getSlackOwners = (id) => {
  return {
    type: GET_SLACK_CHANNELS,
    payload: id,
  }
}

export const getSlackOwnersSuccess = (data) => {
  return {
    type: GET_SLACK_CHANNELS_SUCCESS,
    payload: data,
  }
}

export const getSlackOwnersError = () => {
  return {
    type: GET_SLACK_CHANNELS_ERROR,
  }
}
