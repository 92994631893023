import {
  SAVE_LEAD_SCORING_MODEL,
  SAVE_LEAD_SCORING_MODEL_SUCCESS,
  SAVE_LEAD_SCORING_MODEL_ERROR,
  DELETE_LEAD_SCORING_MODEL,
  DELETE_LEAD_SCORING_MODEL_SUCCESS,
  DELETE_LEAD_SCORING_MODEL_ERROR,
  GET_LEAD_SCORING_MODELS,
  GET_LEAD_SCORING_MODELS_SUCCESS,
  GET_LEAD_SCORING_MODELS_ERROR,
  UPDATE_LEAD_SCORING_MODEL,
  UPDATE_LEAD_SCORING_MODEL_SUCCESS,
  UPDATE_LEAD_SCORING_MODEL_ERROR,
  EXPORT_LEAD_SCORING_MODEL,
  EXPORT_LEAD_SCORING_MODEL_SUCCESS,
  EXPORT_LEAD_SCORING_MODEL_ERROR,
  GET_LEAD_SCORING_MODEL,
  GET_LEAD_SCORING_MODEL_SUCCESS,
  GET_LEAD_SCORING_MODEL_ERROR,
  IMPORT_LEAD_SCORING_MODEL,
  IMPORT_LEAD_SCORING_MODEL_SUCCESS,
  IMPORT_LEAD_SCORING_MODEL_ERROR,
  CLEAR_IMPORT_LEAD_SCORING_MODEL_ERROR,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_REQUEST,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_SUCCESS,
  DELETE_LEAD_SCORING_MODEL_CONTACTS_ERROR,
  CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  saveLeadScoringModelLoading: false,
  saveLeadScoringModelSuccess: false,
  saveLeadScoringModelError: false,
  savedLeadScoringModelData: {},
  getLeadScoringModelLoading: false,
  getSingleLeadScoringModelLoading: false,
  singleLeadScoringModel: {},
  getLeadScoringModelError: false,
  getSingleLeadScoringModelError: false,
  deleteLeadScoringModelError: false,
  deleteLeadScoringModelLoading: false,
  updateLeadScoringModelLoading: false,
  updateLeadScoringModelError: false,
  updateLeadScoringModelSuccess: false,
  deleteLeadScoringModelSuccess: false,
  exportLeadScoringModelError: false,
  exportLeadScoringModelSuccess: false,
  exportLeadScoringModelLoading: false,
  importLeadScoringModelError: false,
  importLeadScoringModelSuccess: false,
  importLeadScoringModelLoading: false,
  deleteContactsLoading: false,
  deleteLeadScoringModelContactsSuccess: false,
  leadScoringModel: {},
  saveJobTitleToLeadScoringSuccess: false,
  saveJobTitleToLeadScoringError: false,
  saveJobTitleToLeadScoringLoading: false,
};

const LeadScoringModel = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_LEAD_SCORING_MODEL: {
      return {
        ...state,
        saveLeadScoringModelLoading: true,
      };
    }
    case SAVE_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        saveLeadScoringModelSuccess: true,
        savedLeadScoringModelData: action.payload,
        saveLeadScoringModelLoading: false,
      };
    }
    case SAVE_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        saveLeadScoringModelLoading: false,
        saveLeadScoringModelSuccess: false,
        saveLeadScoringModelError: true,
      };
    }
    case SAVE_LEAD_JOB_TITLE_SCORING_MODEL: {
      return {
        ...state,
        saveJobTitleToLeadScoringLoading: true,
      };
    }
    case SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        saveJobTitleToLeadScoringSuccess: true,
        saveJobTitleToLeadScoringLoading: false,
      };
    }
    case SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR: {
      return {
        ...state,
        saveJobTitleToLeadScoringError: true,
        saveJobTitleToLeadScoringLoading: false,
      };
    }
    case EXPORT_LEAD_SCORING_MODEL: {
      return {
        ...state,
        exportLeadScoringModelLoading: true,
      };
    }
    case EXPORT_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        exportLeadScoringModelSuccess: true,
        exportLeadScoringModelLoading: false,
      };
    }
    case EXPORT_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        exportLeadScoringModelError: true,
        exportLeadScoringModelLoading: false,
      };
    }

    case IMPORT_LEAD_SCORING_MODEL: {
      return {
        ...state,
        importLeadScoringModelLoading: true,
      };
    }
    case IMPORT_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        importLeadScoringModelSuccess: true,
        importLeadScoringModelLoading: false,
      };
    }
    case IMPORT_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        importLeadScoringModelError: true,
        importLeadScoringModelLoading: false,
      };
    }
    case CLEAR_IMPORT_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        importLeadScoringModelError: false,
        importLeadScoringModelSuccess: false,
        importLeadScoringModelLoading: false,
      };
    }

    case DELETE_LEAD_SCORING_MODEL_CONTACTS_REQUEST: {
      return {
        ...state,
        deleteContactsLoading: true,
      };
    }
    case DELETE_LEAD_SCORING_MODEL_CONTACTS_SUCCESS: {
      return {
        ...state,
        deleteContactsLoading: false,
        deleteLeadScoringModelContactsSuccess: true,
      };
    }
    case DELETE_LEAD_SCORING_MODEL_CONTACTS_ERROR: {
      return {
        ...state,
        deleteContactsLoading: false,
      };
    }

    case UPDATE_LEAD_SCORING_MODEL: {
      return {
        ...state,
        updateLeadScoringModelSuccess: true,
        updateLeadScoringModelLoading: true,
      };
    }
    case UPDATE_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        updateLeadScoringModelLoading: false,
      };
    }
    case UPDATE_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        updateLeadScoringModelLoading: false,
        updateLeadScoringModelError: true,
      };
    }
    case DELETE_LEAD_SCORING_MODEL: {
      return {
        ...state,
        deleteLeadScoringModelLoading: true,
      };
    }
    case DELETE_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        deleteLeadScoringModelSuccess: true,
        deleteLeadScoringModelLoading: false,
      };
    }
    case DELETE_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        deleteLeadScoringModelLoading: false,
        deleteLeadScoringModelError: true,
      };
    }
    case GET_LEAD_SCORING_MODELS: {
      return {
        ...state,
        getLeadScoringModelLoading: true,
      };
    }
    case GET_LEAD_SCORING_MODELS_SUCCESS: {
      return {
        ...state,
        getLeadScoringModelLoading: false,
        models: action.payload,
      };
    }
    case GET_LEAD_SCORING_MODELS_ERROR: {
      return {
        ...state,
        getLeadScoringModelLoading: false,
        getLeadScoringModelError: true,
      };
    }
    case GET_LEAD_SCORING_MODEL: {
      return {
        ...state,
        getSingleLeadScoringModelLoading: true,
      };
    }
    case GET_LEAD_SCORING_MODEL_SUCCESS: {
      return {
        ...state,
        getSingleLeadScoringModelLoading: false,
        singleLeadScoringModel: action.payload,
      };
    }
    case GET_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        getSingleLeadScoringModelLoading: false,
        getSingleLeadScoringModelError: true,
      };
    }
    case CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR: {
      return {
        ...state,
        savedLeadScoringModelData: false,
        saveLeadScoringModelSuccess: false,
        saveLeadScoringModelError: false,
        saveLeadScoringModelLoading: false,
      };
    }

    default:
      return state;
  }
};

export default LeadScoringModel;
