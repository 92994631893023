import {
  LOADING_ONBOARDING,
  POST_ONBOARDING_DETAILS,
  ONBOARDING_SUCCESS,
  CLEAR_ONBOARDING_ERRORS,
  ONBOARDING_FAILED,
  SET_SUCCESS_ONBOARDING_FALSE,
  SEND_DEVELOPER_MAIL,
  SEND_DEVELOPER_MAIL_SUCCESS,
  SEND_DEVELOPER_MAIL_CLOSE,
  SEND_ONBOARDING_CREDENTIALS,
  SEND_ONBOARDING_CREDENTIALS_CLOSE,
  SEND_ONBOARDING_CREDENTIALS_SUCCESS,
  UPDATE_ACCOUNT_STATUS,
} from '../../constants/ActionTypes'

export const clearErrorsOnboarding = () => {
  return {
    type: CLEAR_ONBOARDING_ERRORS,
  }
}

export const onboardingFailed = (data) => {
  return {
    type: ONBOARDING_FAILED,
    payload: data,
  }
}

export const loadingOnboarding = () => {
  return {
    type: LOADING_ONBOARDING,
  }
}

export const startOnboarding = (data) => {
  return {
    type: POST_ONBOARDING_DETAILS,
    payload: data,
  }
}

export const onboardingSuccess = () => {
  return {
    type: ONBOARDING_SUCCESS,
  }
}

export const setSuccessOnboardingFalse = () => {
  return {
    type: SET_SUCCESS_ONBOARDING_FALSE,
  }
}

export const sendDeveloperEmail = (data) => {
  return {
    type: SEND_DEVELOPER_MAIL,
    payload: data,
  }
}

export const sendDeveloperEmailSuccess = () => {
  return {
    type: SEND_DEVELOPER_MAIL_SUCCESS,
  }
}

export const sendDeveloperEmailClose = () => {
  return {
    type: SEND_DEVELOPER_MAIL_CLOSE,
  }
}

export const sendOnboardingCredentials = (data) => {
  return {
    type: SEND_ONBOARDING_CREDENTIALS,
    payload: data,
  }
}

export const sendOnboardingCredentialsSuccess = () => {
  return {
    type: SEND_ONBOARDING_CREDENTIALS_SUCCESS,
  }
}

export const sendOnboardingCredentialsClose = () => {
  return {
    type: SEND_ONBOARDING_CREDENTIALS_CLOSE,
  }
}
export const updateAccountStatus = (data) => {
  return {
    type: UPDATE_ACCOUNT_STATUS,
    payload: data,
  }
}
