import {
  FORWARD_CSV,
  FORWARD_CSV_SUCCESS,
  FORWARD_CSV_ERROR,
  GET_CSV_BLACKLIST,
  GET_CSV_BLACKLIST_SUCCESS,
  GET_CSV_BLACKLIST_ERROR,
  DELETE_CSV_BLACKLIST,
  DELETE_CSV_BLACKLIST_SUCCESS,
  DELETE_CSV_BLACKLIST_ERROR,
  CLEAR_FORWARD_CSV,
  GET_EMAIL_CSV_BLACKLIST,
  GET_EMAIL_CSV_BLACKLIST_SUCCESS,
  GET_EMAIL_CSV_BLACKLIST_ERROR,
  DELETE_EMAIL_CSV_BLACKLIST,
  DELETE_EMAIL_CSV_BLACKLIST_SUCCESS,
  DELETE_EMAIL_CSV_BLACKLIST_ERROR,
  FORWARD_CSV_EMAIL,
  FORWARD_CSV_EMAIL_SUCCESS,
  FORWARD_CSV_EMAIL_ERROR,
  CLEAR_FORWARD_CSV_EMAIL,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  forwardCSVLoading: false,
  forwardSuccess: false,
  forwardEmailCSVLoading: false,
  forwardEmailSuccess: false,
  getCSVBlacklistLoading: false,
  getCSVEmailBlacklistLoading: false,
  deleteCSVBlacklistLoading: false,
  deleteCSVEmailBlacklistLoading: false,
  deletedCSV: '',
  deletedCSVEmail: '',
  CSVBlacklist: {},
  CSVBlacklistError: null,
  CSVEmailBlacklist: {},
  CSVEmailBlacklistError: null,
  CSVEmailBlacklistSuccess: null,
}

const Blacklist = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORWARD_CSV: {
      return {
        ...state,
        forwardCSVLoading: true,
      }
    }

    case FORWARD_CSV_SUCCESS: {
      return {
        ...state,
        forwardCSVLoading: false,
        forwardSuccess: true,
      }
    }

    case FORWARD_CSV_ERROR: {
      return {
        ...state,
        forwardCSVLoading: false,
      }
    }
    case CLEAR_FORWARD_CSV: {
      return {
        ...state,
        forwardCSVLoading: false,
        forwardSuccess: false,
      }
    }
    case FORWARD_CSV_EMAIL: {
      return {
        ...state,
        forwardEmailCSVLoading: true,
      }
    }

    case FORWARD_CSV_EMAIL_SUCCESS: {
      return {
        ...state,
        forwardEmailCSVLoading: false,
        forwardEmailSuccess: true,
      }
    }

    case FORWARD_CSV_EMAIL_ERROR: {
      return {
        ...state,
        forwardEmailCSVLoading: false,
      }
    }
    case CLEAR_FORWARD_CSV_EMAIL: {
      return {
        ...state,
        forwardEmailCSVLoading: false,
        forwardEmailSuccess: false,
      }
    }

    case GET_CSV_BLACKLIST: {
      return {
        ...state,
        getCSVBlacklistLoading: true,
      }
    }

    case GET_CSV_BLACKLIST_SUCCESS: {
      return {
        ...state,
        getCSVBlacklistLoading: false,
        CSVBlacklist: action.payload,
      }
    }

    case GET_CSV_BLACKLIST_ERROR: {
      return {
        ...state,
        getCSVBlacklistLoading: false,
        CSVBlacklistError: action.payload,
      }
    }
    case GET_EMAIL_CSV_BLACKLIST: {
      return {
        ...state,
        getCSVEmailBlacklistLoading: true,
      }
    }

    case GET_EMAIL_CSV_BLACKLIST_SUCCESS: {
      return {
        ...state,
        getCSVEmailBlacklistLoading: false,
        CSVEmailBlacklist: action.payload,
      }
    }

    case GET_EMAIL_CSV_BLACKLIST_ERROR: {
      return {
        ...state,
        getCSVEmailBlacklistLoading: false,
        CSVEmailBlacklistError: action.payload,
      }
    }

    case DELETE_CSV_BLACKLIST: {
      return {
        ...state,
        deleteCSVBlacklistLoading: true,
        deletedCSV: action.payload.csv,
      }
    }

    case DELETE_CSV_BLACKLIST_SUCCESS: {
      return {
        ...state,
        deleteCSVBlacklistLoading: false,
      }
    }

    case DELETE_CSV_BLACKLIST_ERROR: {
      return {
        ...state,
        deleteCSVBlacklistLoading: false,
      }
    }
    case DELETE_EMAIL_CSV_BLACKLIST: {
      return {
        ...state,
        deleteCSVEmailBlacklistLoading: true,
        deletedCSVEmail: action.payload.csv,
      }
    }

    case DELETE_EMAIL_CSV_BLACKLIST_SUCCESS: {
      return {
        ...state,
        deleteCSVEmailBlacklistLoading: false,
      }
    }

    case DELETE_EMAIL_CSV_BLACKLIST_ERROR: {
      return {
        ...state,
        deleteCSVEmailBlacklistLoading: false,
      }
    }

    default:
      return state
  }
}

export default Blacklist
