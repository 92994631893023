/* eslint-disable @typescript-eslint/no-var-requires */
import { applyMiddleware, createStore } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import createSagaMiddleware from 'redux-saga'
import reducers from '../reducers'
import rootSaga from '../sagas/index'

const sagaMiddleware = createSagaMiddleware()

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const initStore = () => {
  const store = createStore(reducers, bindMiddleware([sagaMiddleware]))
  if (typeof window !== 'undefined') {
    if (window.Cypress) {
      window.store = store
    }
  }

  sagaMiddleware.run(rootSaga)
  return store
}

export const wrapper = createWrapper(initStore)
