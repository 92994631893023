import {
  GET_RECORDED_SESSION,
  GET_RECORDED_SESSION_SUCCESSFUL,
  GET_RECORDED_SESSION_FAILED,
} from '../../constants/ActionTypes'

export const getRecordedSession = (params) => {
  return {
    type: GET_RECORDED_SESSION,
    payload: params,
  }
}

export const getRecordedSessionSuccess = (data) => {
  return {
    type: GET_RECORDED_SESSION_SUCCESSFUL,
    payload: data,
  }
}

export const getRecordedSessionFailed = (error) => {
  return {
    type: GET_RECORDED_SESSION_FAILED,
    payload: error,
  }
}
