import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { baseUrl } from '../../utils/session'

import { GET_TRANSLATION } from '../../constants/ActionTypes'
import { generalErrorMessage } from '../actions/GeneralMessage'
import { getTranslationSuccess } from '../actions/Setting'

const getAppTranslation = async (domain) => {
  return await axios
    .get(`${baseUrl}/admin/app/${domain}`)
    .then((settings) => settings)
    .catch((error) => error)
}

function* getTranslationCall({ payload }) {
  const { domain } = payload

  try {
    const settings = yield call(getAppTranslation, domain)
    yield put(getTranslationSuccess(settings.data))
  } catch (error) {
    yield put(generalErrorMessage(error))
  }
}

export function* getTranslation() {
  yield takeEvery(GET_TRANSLATION, getTranslationCall)
}

export default function* rootSaga() {
  yield all([fork(getTranslation)])
}
