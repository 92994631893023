import {
  GENERAL_MESSAGE_SUCCESS,
  GENERAL_MESSAGE_ERROR,
  GENERAL_MESSAGE_CLEAR,
} from '../../constants/ActionTypes'

export const generalSuccessMessage = (data) => {
  return {
    type: GENERAL_MESSAGE_SUCCESS,
    payload: data,
  }
}

export const generalErrorMessage = (data) => {
  return {
    type: GENERAL_MESSAGE_ERROR,
    payload: data,
  }
}

export const generalMessageClear = () => {
  return {
    type: GENERAL_MESSAGE_CLEAR,
  }
}
