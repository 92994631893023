import {
  GET_TAGS,
  MODIFY_TAGS,
  ADD_TAGS,
  ADD_TAGS_SUCCESS,
  MODIFY_TAGS_SUCCESS,
  GET_TAGS_SUCCESS,
  CLEAR_TAG_MESSAGES,
  TAG_REQUEST_ERROR,
  DELETE_TAGS,
  DELETE_TAGS_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loader: true,
  allTags: [],
  modifySuccess: false,
  deleteSuccess: false,
  websiteId: '',
  tagId: '',
  message: '',
  error: false,
}

const Tags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MODIFY_TAGS: {
      return {
        ...state,
        tagId: action.payload.tagId,
      }
    }

    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loader: false,
        allTags: action.payload,
      }
    }
    case TAG_REQUEST_ERROR: {
      return {
        ...state,
        error: true,
        message: action.payload,
        loader: false,
      }
    }

    case GET_TAGS: {
      return {
        ...state,
        loader: true,
      }
    }

    case CLEAR_TAG_MESSAGES: {
      return {
        ...state,
        loader: false,
        error: false,
        message: '',
        modifySuccess: false,
        deleteSuccess: false,
      }
    }

    case ADD_TAGS: {
      return {
        ...state,
      }
    }

    case ADD_TAGS_SUCCESS: {
      state.allTags.push(action.payload)
      return {
        ...state,
      }
    }

    case DELETE_TAGS: {
      return {
        ...state,
      }
    }

    case MODIFY_TAGS_SUCCESS: {
      const tagIndex = state.allTags.findIndex(
        (tag) => tag._id === action.payload.tagId
      )
      state.allTags[tagIndex].colour = action.payload.colour
      state.allTags[tagIndex].text = action.payload.text

      return {
        ...state,
        modifySuccess: true,
      }
    }

    case DELETE_TAGS_SUCCESS: {
      const indexOfDelete = state.allTags.findIndex(
        (tag) => tag._id === action.payload.tagId
      )
      state.allTags.splice(indexOfDelete, 1)

      return {
        ...state,
        deleteSuccess: true,
      }
    }

    default:
      return state
  }
}

export default Tags
