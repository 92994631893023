import {
  DOWNLOAD_PROSPECTOR_CSV,
  DOWNLOAD_PROSPECTOR_CSV_SUCCESS,
  DOWNLOAD_PROSPECTOR_CSV_ERROR,
  CLEAR_PROSPECTOR_CSV_ERROR,
  SET_PROSPECTOR_CSV_PARAMS,
} from '../../constants/ActionTypes'

export const downloadProspectorCSV = (data) => {
  return {
    type: DOWNLOAD_PROSPECTOR_CSV,
    payload: data,
  }
}

export const downloadProspectorCSVSuccess = (data) => {
  return {
    type: DOWNLOAD_PROSPECTOR_CSV_SUCCESS,
    payload: data,
  }
}

export const downloadProspectorCSVError = () => {
  return {
    type: DOWNLOAD_PROSPECTOR_CSV_ERROR,
  }
}

export const clearProspectorCSVError = () => {
  return {
    type: CLEAR_PROSPECTOR_CSV_ERROR,
  }
}

export const setProspectorCSVParams = (queryString) => {
  return {
    type: SET_PROSPECTOR_CSV_PARAMS,
    payload: queryString,
  }
}
