import {
  SAVE_LIST,
  SAVE_LIST_SUCCESS,
  SAVE_LIST_ERROR,
  DELETE_LIST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_ERROR,
  GET_LISTS,
  GET_LISTS_SUCCESS,
  GET_LISTS_STATS,
  GET_LISTS_STATS_SUCCESS,
  GET_LISTS_ERROR,
  UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_ERROR,
  UPDATE_LIST_CONTACT,
  UPDATE_LIST_CONTACT_SUCCESS,
  UPDATE_LIST_CONTACT_ERROR,
  GET_CONTACT_INSIGHT_INFO,
  GET_CONTACT_INSIGHT_INFO_SUCCESS,
  GET_CONTACT_INSIGHT_INFO_ERROR,
  EXPORT_LIST,
  EXPORT_LIST_SUCCESS,
  EXPORT_LIST_ERROR,
  GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_ERROR,
  IMPORT_LIST,
  IMPORT_LIST_SUCCESS,
  IMPORT_LIST_ERROR,
  CLEAR_IMPORT_LIST_ERROR,
  DELETE_LIST_CONTACTS_REQUEST,
  DELETE_LIST_CONTACTS_SUCCESS,
  DELETE_LIST_CONTACTS_ERROR,
  CLEAR_CREATE_LIST_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  saveListLoading: false,
  saveListSuccess: false,
  saveListError: false,
  savedListData: {},
  contactInsightInfo: null,
  getListLoading: false,
  getSingleListLoading: false,
  singleList: {},
  getListError: false,
  getSingleListError: false,
  deleteListError: false,
  deleteListLoading: false,
  updateListLoading: false,
  updateListError: false,
  updateListSuccess: false,
  updateListContactLoading: false,
  updateListContactError: false,
  updateListContactSuccess: false,
  getContactInsightInfoLoading: false,
  getContactInsightInfoError: false,
  getContactInsightInfoSuccess: false,
  deleteListSuccess: false,
  exportListError: false,
  exportListSuccess: false,
  exportListLoading: false,
  importListError: false,
  importListSuccess: false,
  importListLoading: false,
  deleteContactsLoading: false,
  deleteListContactsSuccess: false,
  list: {},
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_LIST: {
      return {
        ...state,
        saveListLoading: true,
      };
    }
    case SAVE_LIST_SUCCESS: {
      return {
        ...state,
        saveListSuccess: true,
        savedListData: action.payload,
        saveListLoading: false,
      };
    }
    case SAVE_LIST_ERROR: {
      return {
        ...state,
        saveListLoading: false,
        saveListSuccess: false,
        saveListError: true,
      };
    }
    case EXPORT_LIST: {
      return {
        ...state,
        exportListLoading: true,
      };
    }
    case EXPORT_LIST_SUCCESS: {
      return {
        ...state,
        exportListSuccess: true,
        exportListLoading: false,
      };
    }
    case EXPORT_LIST_ERROR: {
      return {
        ...state,
        exportListError: true,
        exportListLoading: false,
      };
    }

    case IMPORT_LIST: {
      return {
        ...state,
        importListLoading: true,
      };
    }
    case IMPORT_LIST_SUCCESS: {
      return {
        ...state,
        importListSuccess: true,
        importListLoading: false,
      };
    }
    case IMPORT_LIST_ERROR: {
      return {
        ...state,
        importListError: true,
        importListLoading: false,
      };
    }
    case CLEAR_IMPORT_LIST_ERROR: {
      return {
        ...state,
        importListError: false,
        importListSuccess: false,
        importListLoading: false,
      };
    }

    case DELETE_LIST_CONTACTS_REQUEST: {
      return {
        ...state,
        deleteContactsLoading: true,
      };
    }
    case DELETE_LIST_CONTACTS_SUCCESS: {
      return {
        ...state,
        deleteContactsLoading: false,
        deleteListContactsSuccess: true,
      };
    }
    case DELETE_LIST_CONTACTS_ERROR: {
      return {
        ...state,
        deleteContactsLoading: false,
      };
    }

    case UPDATE_LIST: {
      return {
        ...state,
        updateListSuccess: true,
        updateListLoading: true,
      };
    }
    case UPDATE_LIST_SUCCESS: {
      return {
        ...state,
        updateListLoading: false,
      };
    }
    case UPDATE_LIST_ERROR: {
      return {
        ...state,
        updateListLoading: false,
        updateListError: true,
      };
    }
    case UPDATE_LIST_CONTACT: {
      return {
        ...state,
        updateListContactSuccess: true,
        updateListContactLoading: true,
      };
    }
    case UPDATE_LIST_CONTACT_SUCCESS: {
      return {
        ...state,
        updateListContactLoading: false,
      };
    }
    case UPDATE_LIST_CONTACT_ERROR: {
      return {
        ...state,
        updateListContactLoading: false,
        updateListContactError: true,
      };
    }
    case DELETE_LIST: {
      return {
        ...state,
        deleteListLoading: true,
      };
    }
    case DELETE_LIST_SUCCESS: {
      return {
        ...state,
        deleteListSuccess: true,
        deleteListLoading: false,
      };
    }
    case DELETE_LIST_ERROR: {
      return {
        ...state,
        deleteListLoading: false,
        deleteListError: true,
      };
    }
    case GET_LISTS_STATS: {
      return {
        ...state,
      };
    }
    case GET_LISTS_STATS_SUCCESS: {
      return {
        ...state,
        listStats: action.payload,
      };
    }
    case GET_LISTS: {
      return {
        ...state,
        getListLoading: true,
      };
    }
    case GET_LISTS_SUCCESS: {
      return {
        ...state,
        getListLoading: false,
        list: action.payload,
      };
    }
    case GET_LISTS_ERROR: {
      return {
        ...state,
        getListLoading: false,
        getListError: true,
      };
    }
    case GET_LIST: {
      return {
        ...state,
        getSingleListLoading: true,
      };
    }
    case GET_LIST_SUCCESS: {
      return {
        ...state,
        getSingleListLoading: false,
        singleList: action.payload,
      };
    }
    case GET_LIST_ERROR: {
      return {
        ...state,
        getSingleListLoading: false,
        getSingleListError: true,
      };
    }
    case GET_CONTACT_INSIGHT_INFO: {
      return {
        ...state,
        getContactInsightInfoLoading: true,
      };
    }

    case GET_CONTACT_INSIGHT_INFO_SUCCESS: {
      return {
        ...state,
        getContactInsightInfoLoading: false,
        contactInsightInfo: action.payload,
        getContactInsightInfoSuccess: true,
      };
    }
    case GET_CONTACT_INSIGHT_INFO_ERROR: {
      return {
        ...state,
        getContactInsightInfoError: true,
        getContactInsightInfoLoading: false,
      };
    }
    case CLEAR_CREATE_LIST_ERROR: {
      return {
        ...state,
        savedListData: false,
        saveListSuccess: false,
        saveListError: false,
        saveListLoading: false,
      };
    }

    default:
      return state;
  }
};

export default List;
