import {
  GET_SALESFORCE_OWNERS,
  GET_SALESFORCE_OWNERS_SUCCESS,
  GET_SALESFORCE_OWNERS_ERROR,
  SAVE_NEW_SALESFORCE_COMPANY,
  SAVE_NEW_SALESFORCE_COMPANY_SUCCESS,
  SAVE_NEW_SALESFORCE_COMPANY_ERROR,
  SEARCH_SALESFORCE_COMPANY,
  SEARCH_SALESFORCE_COMPANY_SUCCESS,
  SEARCH_SALESFORCE_COMPANY_ERROR,
  CONNECT_EXISTING_SALESFORCE_COMPANY,
  CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS,
  CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR,
  CONNECT_SALESFORCE_CONTACTS,
  CONNECT_SALESFORCE_CONTACTS_SUCCESS,
  CONNECT_SALESFORCE_CONTACTS_ERROR,
  CLEAR_SALESFORCE_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

export const getSalesforceOwners = (id) => {
  return {
    type: GET_SALESFORCE_OWNERS,
    payload: id,
  }
}

export const getSalesforceOwnersSuccess = (data) => {
  return {
    type: GET_SALESFORCE_OWNERS_SUCCESS,
    payload: data,
  }
}

export const getSalesforceOwnersError = () => {
  return {
    type: GET_SALESFORCE_OWNERS_ERROR,
  }
}

export const saveNewSalesforceCompany = (data) => {
  return {
    type: SAVE_NEW_SALESFORCE_COMPANY,
    payload: data,
  }
}

export const saveNewSalesforceCompanySuccess = (data) => {
  return {
    type: SAVE_NEW_SALESFORCE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const saveNewSalesforceCompanyError = () => {
  return {
    type: SAVE_NEW_SALESFORCE_COMPANY_ERROR,
  }
}

export const searchSalesforceCompany = (data) => {
  return {
    type: SEARCH_SALESFORCE_COMPANY,
    payload: data,
  }
}

export const searchSalesforceCompanySuccess = (data) => {
  return {
    type: SEARCH_SALESFORCE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const searchSalesforceCompanyError = () => {
  return {
    type: SEARCH_SALESFORCE_COMPANY_ERROR,
  }
}

export const connectExistingSalesforceCompany = (data) => {
  return {
    type: CONNECT_EXISTING_SALESFORCE_COMPANY,
    payload: data,
  }
}

export const connectExistingSalesforceCompanySuccess = (data) => {
  return {
    type: CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const connectExistingSalesforceCompanyError = () => {
  return {
    type: CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR,
  }
}

export const connectSalesforceContacts = (data) => {
  return {
    type: CONNECT_SALESFORCE_CONTACTS,
    payload: data,
  }
}

export const connectSalesforceContactsSuccess = (data) => {
  return {
    type: CONNECT_SALESFORCE_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const connectSalesforceContactsError = () => {
  return {
    type: CONNECT_SALESFORCE_CONTACTS_ERROR,
  }
}

export const clearSalesforceContactsError = () => {
  return {
    type: CLEAR_SALESFORCE_CONTACTS_ERROR,
  }
}
