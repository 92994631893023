import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { CONNECT_GTM_CONTAINERS } from '../../constants/ActionTypes';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';
import { connectGtmContainersError, connectGtmContainersSuccess } from '../actions';

const connectContainerRequest = async (data) =>
  await axios
    .post(`${baseUrl}/admin/gtm/tag`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

function* connectContainers({ payload }) {
  const { data } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectContainerRequest, data);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(generalErrorMessage('There is an issue with gtm connection, try again later'));
        yield put(connectGtmContainersError());
      } else {
        yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectGtmContainersError());
      }
    } else {
      yield put(connectGtmContainersSuccess());
      yield put(generalSuccessMessage('Connected succesfully'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Container not connect...'));
    yield put(connectGtmContainersError());
  }
}

export function* putRequestConnectGtmContainers() {
  yield takeEvery(CONNECT_GTM_CONTAINERS, connectContainers);
}

export default function* rootSaga() {
  yield all([fork(putRequestConnectGtmContainers)]);
}
