import {
  CONNECT_GTM_CONTAINERS,
  CONNECT_GTM_CONTAINERS_SUCCESS,
  CONNECT_GTM_CONTAINERS_ERROR,
  CLEAR_GTM_CONTAINER,
} from '../../constants/ActionTypes';

export const connectGtmContainers = (data) => {
  return {
    type: CONNECT_GTM_CONTAINERS,
    payload: data,
  };
};

export const connectGtmContainersSuccess = (data) => {
  return {
    type: CONNECT_GTM_CONTAINERS_SUCCESS,
    payload: data,
  };
};

export const connectGtmContainersError = () => {
  return {
    type: CONNECT_GTM_CONTAINERS_ERROR,
  };
};
export const clearGtmConnect = () => {
  return {
    type: CLEAR_GTM_CONTAINER,
  };
};
