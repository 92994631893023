import {
  GET_COMMENTS,
  CREATE_COMMENT,
  MODIFY_COMMENT,
  DELETE_COMMENT,
  GET_COMMENTS_SUCCESS,
} from '../../constants/ActionTypes'

export const getAllComments = (leadId) => {
  return {
    type: GET_COMMENTS,
    payload: { leadId },
  }
}

export const createComment = (leadId, content) => {
  return {
    type: CREATE_COMMENT,
    payload: { leadId, content },
  }
}

export const modifyComment = (commentId, content, leadId) => {
  return {
    type: MODIFY_COMMENT,
    payload: { commentId, content, leadId },
  }
}

export const deleteComment = (commentId, leadId) => {
  return {
    type: DELETE_COMMENT,
    payload: { commentId, leadId },
  }
}

export const getCommentsSuccess = (data) => {
  return {
    type: GET_COMMENTS_SUCCESS,
    payload: data,
  }
}
