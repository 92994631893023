import {
  CONNECT_GTM_CONTAINERS,
  CONNECT_GTM_CONTAINERS_SUCCESS,
  CONNECT_GTM_CONTAINERS_ERROR,
  CLEAR_GTM_CONTAINER,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  connectLoading: false,
  connectError: false,
  connectSuccess: false,
};

const Gtm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONNECT_GTM_CONTAINERS: {
      return {
        ...state,
        connectLoading: true,
      };
    }
    case CONNECT_GTM_CONTAINERS_SUCCESS: {
      return {
        ...state,
        connectLoading: false,
        connectSuccess: true,
      };
    }
    case CONNECT_GTM_CONTAINERS_ERROR: {
      return {
        ...state,
        connectLoading: false,
        connectError: true,
      };
    }
    case CLEAR_GTM_CONTAINER: {
      return {
        ...state,
        connectLoading: false,
        connectError: false,
        connectSuccess: true,
      };
    }

    default:
      return state;
  }
};

export default Gtm;
