import {
  GET_PIPEDRIVE_OWNERS,
  GET_PIPEDRIVE_OWNERS_SUCCESS,
  GET_PIPEDRIVE_OWNERS_ERROR,
  SAVE_NEW_PIPEDRIVE_COMPANY,
  SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS,
  SAVE_NEW_PIPEDRIVE_COMPANY_ERROR,
  SEARCH_PIPEDRIVE_COMPANY,
  SEARCH_PIPEDRIVE_COMPANY_SUCCESS,
  SEARCH_PIPEDRIVE_COMPANY_ERROR,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR,
  CONNECT_PIPEDRIVE_CONTACTS,
  CONNECT_PIPEDRIVE_CONTACTS_SUCCESS,
  CONNECT_PIPEDRIVE_CONTACTS_ERROR,
  CLEAR_PIPEDRIVE_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

export const getPipedriveOwners = (id) => {
  return {
    type: GET_PIPEDRIVE_OWNERS,
    payload: id,
  }
}

export const getPipedriveOwnersSuccess = (data) => {
  return {
    type: GET_PIPEDRIVE_OWNERS_SUCCESS,
    payload: data,
  }
}

export const getPipedriveOwnersError = () => {
  return {
    type: GET_PIPEDRIVE_OWNERS_ERROR,
  }
}

export const saveNewPipedriveCompany = (data) => {
  return {
    type: SAVE_NEW_PIPEDRIVE_COMPANY,
    payload: data,
  }
}

export const saveNewPipedriveCompanySuccess = (data) => {
  return {
    type: SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const saveNewPipedriveCompanyError = () => {
  return {
    type: SAVE_NEW_PIPEDRIVE_COMPANY_ERROR,
  }
}

export const searchPipedriveCompany = (data) => {
  return {
    type: SEARCH_PIPEDRIVE_COMPANY,
    payload: data,
  }
}

export const searchPipedriveCompanySuccess = (data) => {
  return {
    type: SEARCH_PIPEDRIVE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const searchPipedriveCompanyError = () => {
  return {
    type: SEARCH_PIPEDRIVE_COMPANY_ERROR,
  }
}

export const connectExistingPipedriveCompany = (data) => {
  return {
    type: CONNECT_EXISTING_PIPEDRIVE_COMPANY,
    payload: data,
  }
}

export const connectExistingPipedriveCompanySuccess = (data) => {
  return {
    type: CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS,
    payload: data,
  }
}

export const connectExistingPipedriveCompanyError = () => {
  return {
    type: CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR,
  }
}

export const connectPipedriveContacts = (data) => {
  return {
    type: CONNECT_PIPEDRIVE_CONTACTS,
    payload: data,
  }
}

export const connectPipedriveContactsSuccess = (data) => {
  return {
    type: CONNECT_PIPEDRIVE_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const connectPipedriveContactsError = () => {
  return {
    type: CONNECT_PIPEDRIVE_CONTACTS_ERROR,
  }
}
export const clearPipedriveContactsError = () => {
  return {
    type: CLEAR_PIPEDRIVE_CONTACTS_ERROR,
  }
}
