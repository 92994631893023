import {
  SAVE_NEW_PIPEDRIVE_COMPANY,
  SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS,
  SAVE_NEW_PIPEDRIVE_COMPANY_ERROR,
  SEARCH_PIPEDRIVE_COMPANY,
  SEARCH_PIPEDRIVE_COMPANY_SUCCESS,
  SEARCH_PIPEDRIVE_COMPANY_ERROR,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS,
  CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR,
  CLEAR_PIPEDRIVE_CONTACTS_ERROR,
  GET_PIPEDRIVE_OWNERS,
  GET_PIPEDRIVE_OWNERS_SUCCESS,
  GET_PIPEDRIVE_OWNERS_ERROR,
  CONNECT_PIPEDRIVE_CONTACTS_SUCCESS,
  CONNECT_PIPEDRIVE_CONTACTS,
  CONNECT_PIPEDRIVE_CONTACTS_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  error: false,
  savePipedriveCompanyLoading: false,
  savePipedriveCompanyError: false,
  savePipedriveCompanySuccess: false,
  searchPipedriveCompanyLoading: false,
  searchPipedriveCompanyError: false,
  connectPipedriveCompanyLoading: false,
  connectPipedriveCompanyError: false,
  connectPipedriveCompanySuccess: false,
  connectPipedriveContactsSuccess: false,
  connectPipedriveContactsLoading: false,
  connectPipedriveContactsError: false,
  pipedriveContactEmail: '',
  pipedriveOwners: [],
  pipedriveContactsArr: [],
  existingPipedriveCompanies: [],
};

const Pipedrive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PIPEDRIVE_OWNERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PIPEDRIVE_OWNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        pipedriveOwners: action.payload.data.results,
      };
    }
    case GET_PIPEDRIVE_OWNERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case SAVE_NEW_PIPEDRIVE_COMPANY: {
      return {
        ...state,
        savePipedriveCompanyLoading: true,
      };
    }
    case SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS: {
      return {
        ...state,
        savePipedriveCompanyLoading: false,
        savePipedriveCompanySuccess: true,
      };
    }
    case SAVE_NEW_PIPEDRIVE_COMPANY_ERROR: {
      return {
        ...state,
        savePipedriveCompanyLoading: false,
        savePipedriveCompanyError: true,
      };
    }

    case SEARCH_PIPEDRIVE_COMPANY: {
      return {
        ...state,
        searchPipedriveCompanyLoading: true,
      };
    }
    case SEARCH_PIPEDRIVE_COMPANY_SUCCESS: {
      return {
        ...state,
        searchPipedriveCompanyLoading: false,
        existingPipedriveCompanies: action.payload.data.results,
      };
    }
    case SEARCH_PIPEDRIVE_COMPANY_ERROR: {
      return {
        ...state,
        searchPipedriveCompanyLoading: false,
        searchPipedriveCompanyError: true,
      };
    }

    case CONNECT_EXISTING_PIPEDRIVE_COMPANY: {
      return {
        ...state,
        connectPipedriveCompanyLoading: true,
      };
    }
    case CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS: {
      return {
        ...state,
        connectPipedriveCompanyLoading: false,
        connectPipedriveCompanySuccess: true,
      };
    }
    case CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR: {
      return {
        ...state,
        connectPipedriveCompanyLoading: false,
        connectPipedriveCompanyError: true,
      };
    }

    case CONNECT_PIPEDRIVE_CONTACTS: {
      return {
        ...state,
        connectPipedriveContactsLoading: true,
        pipedriveContactEmail: action.payload.email,
        connectPipedriveContactsSuccess: false,
        connectPipedriveContactsError: false,
        pipedriveContactsArr: [...state.pipedriveContactsArr, action.payload.email],
      };
    }
    case CONNECT_PIPEDRIVE_CONTACTS_SUCCESS: {
      return {
        ...state,
        connectPipedriveContactsLoading: false,
        connectPipedriveContactsSuccess: true,
        connectPipedriveContactsError: false,
      };
    }
    case CONNECT_PIPEDRIVE_CONTACTS_ERROR: {
      return {
        ...state,
        connectPipedriveContactsLoading: false,
        connectPipedriveContactsError: true,
        connectPipedriveContactsSuccess: false,
      };
    }
    case CLEAR_PIPEDRIVE_CONTACTS_ERROR: {
      return {
        ...state,
        connectPipedriveContactsLoading: false,
        connectPipedriveContactsError: false,
        // connectPipedriveContactsSuccess: false,
      };
    }

    default:
      return state;
  }
};

export default Pipedrive;
