import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';
import {
  SAVE_LIST,
  GET_LISTS,
  GET_LISTS_STATS,
  DELETE_LIST,
  UPDATE_LIST,
  UPDATE_LIST_CONTACT,
  GET_CONTACT_INSIGHT_INFO,
  EXPORT_LIST,
  GET_LIST,
  IMPORT_LIST,
  DELETE_LIST_CONTACTS_REQUEST,
} from '../../constants/ActionTypes';

import {
  saveListError,
  saveListSuccess,
  getListsSuccess,
  getListsError,
  getLists,
  getListsStatsSuccess,
  deleteListSuccess,
  deleteListError,
  updateListError,
  updateListSuccess,
  updateListContactError,
  getContactInsightInfoSuccess,
  getContactInsightInfoError,
  exportListError,
  exportListSuccess,
  getListError,
  getListSuccess,
  importListError,
  importListSuccess,
  deleteListContactsError,
  deleteListContactsSuccess,
  getList,
} from '../actions/List';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { getUserDetails, userSignOut } from '../actions/Auth';

const saveListRequest = async (name, type, websiteId, ids) =>
  await axios
    .post(`${baseUrl}/admin/lists?listType=${type}`, {
      name,
      websiteId,
      contacts: type == 'contact' ? ids : [],
      companies: type == 'company' ? ids : [],
    })
    .then((response) => response)
    .catch((error) => error);
const exportListRequest = async (listId, type) =>
  await axios
    .post(`${baseUrl}/admin/lists/export-csv/${listId}?listType=${type}`, {
      listId,
    })
    .then((response) => response)
    .catch((error) => error);
const importListRequest = async (listId, type, file, websiteId, enrichmentRequired) =>
  await axios
    .post(
      `${baseUrl}/admin/lists/import-csv/${listId}?listType=${type}&enrichmentRequired=${enrichmentRequired}&websiteId=${websiteId}`,
      file
    )
    .then((response) => response)
    .catch((error) => error);

const deleteListContactRequest = async (contactIds, listId, listType, listName) =>
  await axios
    .delete(`${baseUrl}/admin/lists/${listId}/items?listType=${listType}&${listName}=${contactIds},`)
    .then((response) => response)
    .catch((error) => error);

const updateListRequest = async (id, name, type, ids, websiteId) =>
  await axios
    .put(`${baseUrl}/admin/lists/${id}?listType=${type}`, {
      name,
      contacts: type == 'contact' ? ids : [],
      companies: type == 'company' ? ids : [],
      websiteId,
    })
    .then((response) => response)
    .catch((error) => error);

const updateListContactRequest = async (id, contact) =>
  await axios
    .put(`${baseUrl}/admin/lists/contact/${id}`, {
      contact,
    })
    .then((response) => response)
    .catch((error) => error);

const getContactInsightInfoRequest = async (listType, firstName, lastName, email, domain) =>
  await axios
    .get(
      `${baseUrl}/admin/lists/contact/insight?listType=${listType}&firstName=${firstName}&lastName=${lastName}&email=${email}&domain=${domain}`
    )
    .then((response) => response)
    .catch((error) => error);

const deleteListRequest = async (name, type) =>
  await axios
    .delete(`${baseUrl}/admin/lists/${name}?listType=${type}`, {
      id: name,
    })
    .then((response) => response)
    .catch((error) => error);

const fetchListsRequest = async (listType, websiteId, offset, limit) =>
  await axios
    .get(`${baseUrl}/admin/lists?listType=${listType}&websiteId=${websiteId}&page=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch((error) => error);
const fetchListsStatsRequest = async (listType, websiteId, offset, limit) =>
  await axios
    .get(`${baseUrl}/admin/lists-stats?listType=${listType}&websiteId=${websiteId}&page=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch((error) => error);
const fetchSingleListRequest = async (listId, listType, offset, limit) =>
  await axios
    .get(`${baseUrl}/admin/lists/${listId}?listType=${listType}&page=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch((error) => error);

function* fetchSaveList({ payload }) {
  const { name, type, websiteId, ids } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveListRequest, name, type, websiteId, ids);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(saveListError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(saveListError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getLists({ listType: type, websiteId }));
      yield put(saveListSuccess(callResponse.data.list));
      yield put(generalSuccessMessage('List saved successfully'));
    }
  } catch (error) {
    yield put(saveListError(error));
    yield put(generalErrorMessage(error));
  }
}

function* deleteListContacts({ payload }) {
  const { listId, listType, contactIds, offset, limit } = payload;
  setAuthorizationHeader();
  const listName = listType == 'company' ? 'companies' : 'contacts';

  try {
    const callResponse = yield call(deleteListContactRequest, contactIds, listId, listType, listName);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteListContactsError(callResponse.response.data.status));
      } else {
        yield put(deleteListContactsError('Something went wrong please check your network'));
      }
    } else {
      yield put(getList({ listId, listType, offset, limit }));
      yield put(deleteListContactsSuccess());
      yield put(generalSuccessMessage('Contacts deleted Successfully'));
    }
  } catch (error) {
    yield put(deleteListContactsError(error));
  }
}
function* fetchExportList({ payload }) {
  const { listId, type } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(exportListRequest, listId, type);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(exportListError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(exportListError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(exportListSuccess());
      yield put(
        generalSuccessMessage(
          callResponse?.data?.status ?? 'List exported successfully, You will receive it shortly on your email'
        )
      );
    }
  } catch (error) {
    yield put(exportListError(error));
    yield put(generalErrorMessage(error));
  }
}
function* fetchImportList({ payload }) {
  const { listId, listType, file, websiteId, enrichmentRequired } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(importListRequest, listId, listType, file, websiteId, enrichmentRequired);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(importListError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.error));
      } else {
        yield put(importListError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(importListSuccess());
      yield put(generalSuccessMessage('List is being imported successfully'));
      yield put(getLists({ listType, websiteId }));
    }
  } catch (error) {
    yield put(importListError(error));
    yield put(generalErrorMessage('An error occured, Try again later'));
  }
}
function* fetchUpdateList({ payload }) {
  const { id, name, type, ids, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateListRequest, id, name, type, ids, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(updateListError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(updateListError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(updateListSuccess());
      yield put(generalSuccessMessage('List is being updated'));
      yield put(getLists({ listType: type, websiteId: websiteId }));
    }
  } catch (error) {
    yield put(updateListError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchContactInsightInfoData({ payload }) {
  const { listType, firstName, lastName, email, domain } = payload;
  // const csvquery = queryString.stringify(query.query)
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getContactInsightInfoRequest, listType, firstName, lastName, email, domain);
    // yield put(setProspectsCSVParams(csvquery))
    // getContactInsightInfoSuccess,
    //   getContactInsightInfoError,
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getContactInsightInfoError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getContactInsightInfoError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospects data gotten successfully"));
      yield put(getContactInsightInfoSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getContactInsightInfoError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchUpdateListContact({ payload }) {
  const { id, contact, listType, listId, offset, limit } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(updateListContactRequest, id, contact);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(updateListContactError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(updateListContactError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getList({ listId, listType, offset, limit }));
      yield put(updateListSuccess());
      yield put(generalSuccessMessage('List Contact is being updated'));
    }
  } catch (error) {
    yield put(updateListError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchDeleteList({ payload }) {
  const { id, type, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteListRequest, id, type, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(deleteListError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(deleteListError('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(deleteListSuccess());
      yield put(generalSuccessMessage('List deleted successfully'));
      yield put(getLists({ listType: type, websiteId }));
    }
  } catch (error) {
    yield put(deleteListError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchLists({ payload }) {
  setAuthorizationHeader();
  const { listType, websiteId, offset, limit } = payload;
  try {
    const callResponse = yield call(fetchListsRequest, listType, websiteId, offset, limit);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getListsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getListsError());
      }
    } else {
      yield put(getListsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getListsError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchListsStats({ payload }) {
  setAuthorizationHeader();
  const { listType, websiteId, offset, limit } = payload;
  try {
    const callResponse = yield call(fetchListsStatsRequest, listType, websiteId, offset, limit);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        // yield put(getListsError())
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        // yield put(getListsError())
      }
    } else {
      yield put(getListsStatsSuccess(callResponse.data));
    }
  } catch (error) {
    // yield put(getListsError())
    yield put(generalErrorMessage(error));
  }
}
function* fetchSingleList({ payload }) {
  const { listId, listType, offset, limit } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchSingleListRequest, listId, listType, offset, limit);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getListError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getListError());
      }
    } else {
      yield put(getListSuccess(callResponse.data.response));
    }
  } catch (error) {
    yield put(getListError());
    yield put(generalErrorMessage(error));
  }
}

export function* postRequestSaveList() {
  yield takeEvery(SAVE_LIST, fetchSaveList);
}
export function* postRequestExportList() {
  yield takeEvery(EXPORT_LIST, fetchExportList);
}
export function* postRequestImportList() {
  yield takeEvery(IMPORT_LIST, fetchImportList);
}
export function* putRequestUpdateList() {
  yield takeEvery(UPDATE_LIST, fetchUpdateList);
}

export function* getRequestFetchContactInsightInfoData() {
  yield takeEvery(GET_CONTACT_INSIGHT_INFO, fetchContactInsightInfoData);
}

export function* putRequestUpdateListContact() {
  yield takeEvery(UPDATE_LIST_CONTACT, fetchUpdateListContact);
}
export function* deleteRequestDeleteList() {
  yield takeEvery(DELETE_LIST, fetchDeleteList);
}

export function* getRequestFetchLists() {
  yield takeEvery(GET_LISTS, fetchLists);
}
export function* getRequestFetchListsStats() {
  yield takeEvery(GET_LISTS_STATS, fetchListsStats);
}
export function* getRequestFetchSingleList() {
  yield takeEvery(GET_LIST, fetchSingleList);
}
export function* deleteListContactsCall() {
  yield takeEvery(DELETE_LIST_CONTACTS_REQUEST, deleteListContacts);
}

export default function* rootSaga() {
  yield all([
    fork(postRequestSaveList),
    fork(postRequestExportList),
    fork(postRequestImportList),
    fork(getRequestFetchLists),
    fork(getRequestFetchListsStats),
    fork(getRequestFetchSingleList),
    fork(putRequestUpdateList),
    fork(putRequestUpdateListContact),
    fork(getRequestFetchContactInsightInfoData),
    fork(deleteRequestDeleteList),
    fork(deleteListContactsCall),
  ]);
}
