import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import queryString from 'query-string'
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { MANAGE_SERVICES_REQUEST, UPDATE_WEBSITE_LIMIT_REQUEST } from '../../constants/ActionTypes';

import { generalErrorMessage, generalSuccessMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';
import {
  updateServicesError,
  updateServicesSuccess,
  updateWebsiteLimitsError,
  updateWebsiteLimitsSuccess,
} from '../actions';

const updateServices = async (websiteId, credits, emailAccounts) => {
  return await axios
    .put(`${baseUrl}/admin/websites/set-limits/${websiteId}`, {
      credits,
      emailAccounts,
    })
    .then((response) => response)
    .catch((error) => error);
};
const updateWebsiteLimits = async (
  trialExpireDate,
  refreshOnDate,
  activeWebsite,
  enableWebsiteLimits,
  trialEnabled,
  websiteId
) => {
  return await axios
    .put(`${baseUrl}/admin/websites/toggle-limits/${websiteId}`, {
      trialExpireDate,
      refreshOnDate,
      activeWebsite,
      enableWebsiteLimits,
      trialEnabled,
    })
    .then((response) => response)
    .catch((error) => error);
};

function* updateServicesData({ payload }) {
  const { websiteId, credits, emailAccounts } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(updateServices, websiteId, credits, emailAccounts);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(updateServicesError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(updateServicesError());
      }
    } else {
      // yield put(generalSuccessMessage('Services updated successfully'));
      yield put(updateServicesSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(updateServicesError());
    yield put(generalErrorMessage(error));
  }
}
function* updateWebsiteLimitsQuery({ payload }) {
  const { trialExpireDate, refreshOnDate, activeWebsite, enableWebsiteLimits, trialEnabled, websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      updateWebsiteLimits,
      trialExpireDate,
      refreshOnDate,
      activeWebsite,
      enableWebsiteLimits,
      trialEnabled,
      websiteId
    );
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(updateWebsiteLimitsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(updateWebsiteLimitsError());
      }
    } else {
      yield put(generalSuccessMessage('Limits set successfully'));
      yield put(updateWebsiteLimitsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(updateWebsiteLimitsError());
    yield put(generalErrorMessage(error));
  }
}

export function* updateServicesRequest() {
  yield takeEvery(MANAGE_SERVICES_REQUEST, updateServicesData);
}
export function* updateWebsitesLimitsRequest() {
  yield takeEvery(UPDATE_WEBSITE_LIMIT_REQUEST, updateWebsiteLimitsQuery);
}

export default function* rootSaga() {
  yield all([fork(updateServicesRequest), fork(updateWebsitesLimitsRequest)]);
}
