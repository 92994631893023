import {
  GET_TAGS,
  MODIFY_TAGS,
  ADD_TAGS,
  ADD_TAGS_SUCCESS,
  MODIFY_TAGS_SUCCESS,
  GET_TAGS_SUCCESS,
  CLEAR_TAG_MESSAGES,
  TAG_REQUEST_ERROR,
  DELETE_TAGS,
  DELETE_TAGS_SUCCESS,
} from '../../constants/ActionTypes'

export const getTags = (websiteId) => {
  return {
    type: GET_TAGS,
    payload: websiteId,
  }
}

export const getTagsSuccess = (data) => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: data,
  }
}

export const tagErrors = (message) => {
  return {
    type: TAG_REQUEST_ERROR,
    payload: message,
  }
}

export const modifyTagSuccess = (data) => {
  return {
    type: MODIFY_TAGS_SUCCESS,
    payload: data,
  }
}

export const deleteTagSuccess = (data) => {
  return {
    type: DELETE_TAGS_SUCCESS,
    payload: data,
  }
}

export const addTags = (data) => {
  return {
    type: ADD_TAGS,
    payload: data,
  }
}

export const addTagsSuccess = (data) => {
  return {
    type: ADD_TAGS_SUCCESS,
    payload: data,
  }
}

export const modifyTags = (data) => {
  return {
    type: MODIFY_TAGS,
    payload: data,
  }
}

export const deleteTags = (data) => {
  return {
    type: DELETE_TAGS,
    payload: data,
  }
}

export const clearTagMessages = () => {
  return {
    type: CLEAR_TAG_MESSAGES,
  }
}
