import {
  GET_SLACK_CHANNELS,
  GET_SLACK_CHANNELS_SUCCESS,
  GET_SLACK_CHANNELS_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  error: false,
  slackChannels: [],
}

const Slack = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SLACK_CHANNELS: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_SLACK_CHANNELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        slackChannels: action.payload.channels,
      }
    }
    case GET_SLACK_CHANNELS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    default:
      return state
  }
}

export default Slack
