import {
  ENRICH_FETCH_DATA_REQUEST,
  ENRICH_FETCH_DATA_SUCCESS,
  ENRICH_FETCH_DATA_ERROR,
  ENRICH_CLEAR_MESSAGES,
  VERIFY_EMAIL_ENRICH,
  SUCCESS_VERIFY_EMAIL_ENRICH,
  CLEAR_ENRICH,
  VERIFY_EMAIL_ENRICH_POST,
  SUCCESS_VERIFY_EMAIL_ENRICH_POST,
  ERROR_VERIFY_EMAIL_ENRICH_POST,
} from '../../constants/ActionTypes';

export const enrichFetchDataRequest = (data) => {
  return {
    type: ENRICH_FETCH_DATA_REQUEST,
    payload: data,
  };
};

export const enrichFetchDataSuccess = (data) => {
  return {
    type: ENRICH_FETCH_DATA_SUCCESS,
    payload: data,
  };
};

export const enrichFetchDataError = (error) => {
  return {
    type: ENRICH_FETCH_DATA_ERROR,
    payload: error,
  };
};

export const enrichClearMessages = () => {
  return {
    type: ENRICH_CLEAR_MESSAGES,
  };
};

export const verifyEmailEnrich = (data) => {
  return {
    type: VERIFY_EMAIL_ENRICH,
    payload: data,
  };
};

export const successverifyEmailEnrich = (data) => {
  return {
    type: SUCCESS_VERIFY_EMAIL_ENRICH,
    payload: data,
  };
};
export const verifyEmailPost = (data) => {
  return {
    type: VERIFY_EMAIL_ENRICH_POST,
    payload: data,
  };
};

export const successverifyEmailPost = (data) => {
  return {
    type: SUCCESS_VERIFY_EMAIL_ENRICH_POST,
    payload: data,
  };
};
export const errorverifyEmailPost = () => {
  return {
    type: ERROR_VERIFY_EMAIL_ENRICH_POST,
  };
};

export const clearEnrichData = () => {
  return {
    type: CLEAR_ENRICH,
  };
};
