import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { GET_COMMENTS, CREATE_COMMENT, MODIFY_COMMENT, DELETE_COMMENT } from '../../constants/ActionTypes';
import { userSignOut } from '../actions/Auth';
import { generalErrorMessage } from '../actions/GeneralMessage';

import { getCommentsSuccess, getAllComments } from '../actions/Comments';

const getRequestComments = async (leadId) =>
  await axios
    .get(`${baseUrl}/admin/comments?leadId=${leadId}`)
    .then((response) => response)
    .catch((error) => error);
const createRequestComments = async (leadId, content) =>
  await axios.post(`${baseUrl}/admin/comments`, {
    leadId,
    content,
  });
const updateRequestComments = async (commentId, content) =>
  await axios
    .put(`${baseUrl}/admin/comments`, {
      commentId,
      content,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteCommentRequest = async (commentId) =>
  await axios
    .delete(`${baseUrl}/admin/comments?commentId=${commentId}`)
    .then((response) => response)
    .catch((error) => error);

function* callComments({ payload }) {
  const { leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getRequestComments, leadId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
      }
    } else {
      yield put(getCommentsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

function* createCommentRequest({ payload }) {
  const { content, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(createRequestComments, leadId, content);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
      }
    } else {
      yield put(getAllComments(leadId));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

function* commentUpdateRequest({ payload }) {
  const { commentId, content, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateRequestComments, commentId, content);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
      }
    } else {
      yield put(getAllComments(leadId));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

function* deleteCommentCall({ payload }) {
  const { commentId, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteCommentRequest, commentId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(
        //   generalErrorMessage('Something went wrong please check your network')
        // )
      }
    } else {
      yield put(getAllComments(leadId));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

export function* get_request_comments() {
  yield takeEvery(GET_COMMENTS, callComments);
}

export function* createComment() {
  yield takeEvery(CREATE_COMMENT, createCommentRequest);
}

export function* updateComment() {
  yield takeEvery(MODIFY_COMMENT, commentUpdateRequest);
}

export function* deleteComment() {
  yield takeEvery(DELETE_COMMENT, deleteCommentCall);
}

export default function* rootSaga() {
  yield all([fork(get_request_comments), fork(createComment), fork(updateComment), fork(deleteComment)]);
}
