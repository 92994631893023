import {
  SAVE_ICP_FILTERS,
  SAVE_ICP_FILTERS_SUCCESS,
  SAVE_ICP_FILTERS_ERROR,
  GET_ICP_FILTERS,
  GET_ICP_FILTERS_SUCCESS,
  GET_ICP_FILTERS_ERROR,
} from '../../constants/ActionTypes'

export const saveIcpFilters = (data) => {
  return {
    type: SAVE_ICP_FILTERS,
    payload: data,
  }
}

export const saveIcpFiltersSuccess = (data) => {
  return {
    type: SAVE_ICP_FILTERS_SUCCESS,
    payload: data,
  }
}

export const saveIcpFiltersError = () => {
  return {
    type: SAVE_ICP_FILTERS_ERROR,
  }
}

export const getIcpFilters = (id) => {
  return {
    type: GET_ICP_FILTERS,
    payload: id,
  }
}

export const getIcpFiltersSuccess = (data) => {
  return {
    type: GET_ICP_FILTERS_SUCCESS,
    payload: data,
  }
}

export const getIcpFiltersError = () => {
  return {
    type: GET_ICP_FILTERS_ERROR,
  }
}
