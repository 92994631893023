import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  USER_DETAILS_HAPPIER_LEADS,
  SEND_RECOVERY_EMAIL,
  SEND_RESET_PASSWORD,
  VERIFY_EMAIL_REQUEST,
  DELETE_MY_ACCOUNT,
} from '../../constants/ActionTypes';
import {
  showAuthMessage,
  userGoogleSignInSuccess,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  setuserInfo,
  successRecoveryEmail,
  moveUserToHome,
  successResetPassword,
  userSignOut,
  verifyEmailSuccess,
  verifyEmailError,
  deleteAccountError,
  deleteAccountSuccess,
} from '../actions/Auth';
import {
  removeCookie,
  setCookie,
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
  getGaCookie,
} from '../../utils/session';
import axios from 'axios';

import { clearAllStatesLeads } from '../actions/Leads';
import { clearAllStatesLead } from '../actions/Lead';
import { generalErrorMessage, generalSuccessMessage } from '../actions/GeneralMessage';
const VERSION = '0.0.1';
const createUserWithEmailPasswordRequest = async (
  email,
  password,
  couponCode,
  appsumoCode,
  session,
  accountFocus,
  last_utm,
  original_utm,
  cookie,
  gclid
) =>
  await axios
    .post(`${baseUrl}/admin/register`, {
      email,
      password,
      couponCode,
      appsumoCode,
      session,
      accountFocus,
      last_utm,
      original_utm,
      ga_clientId: cookie,
      gclid,
    })
    .then((authUser) => authUser)
    .catch((error) => error);
const verifyUserEmailRequest = async (token) =>
  await axios
    .post(`${baseUrl}/admin/user-email-verification`, {
      uniqueCode: token,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteAccountRequest = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/account/${id}`)
    .then((response) => response)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await axios
    .post(`${baseUrl}/admin/login`, { email, password })
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGoogleRequest = async (tokenId, appsumo, session, last_utm, original_utm, cookie, gclid) =>
  await axios
    .post(`${baseUrl}/admin/google-oauth`, {
      tokenId,
      appsumo,
      session,
      last_utm,
      original_utm,
      ga_clientId: cookie,
      gclid,
    })
    .then((authUser) => authUser)
    .catch((error) => error);

const fetchuserInfo = async (gaCookie) =>
  await axios
    .get(`${baseUrl}/admin/account?clientId=${gaCookie}&version=${VERSION}`)
    .then((authUser) => authUser)
    .catch((error) => error);

const recoverPasswordRequest = async (email) =>
  await axios
    .post(`${baseUrl}/admin/recover-password`, { email })
    .then((response) => response)
    .catch((error) => error);

const resetPasswordRequest = async (resetId, newPass) =>
  await axios
    .post(`${baseUrl}/admin/reset-password`, { resetId, newPass })
    .then((response) => response)
    .catch((error) => error);

const changeGTMStatus = async (params) =>
  await axios
    .post(`${baseUrl}/admin/gtm`, params)
    .then((result) => result)
    .catch((error) => error);

function* signInUserWithGoogle({ payload }) {
  const { tokenId, appsumo, email, session, last_utm, original_utm, gclid } = payload;
  const cookie = getGaCookie();
  try {
    const signUpUser = yield call(
      signInUserWithGoogleRequest,
      tokenId,
      appsumo,
      session,
      last_utm,
      original_utm,
      cookie,
      gclid
    );

    if (window.fpr) {
      window.fpr('referral', { email: email });
    }

    if (window.po) {
      window.po('customers', 'signup', {
        data: {
          key: email,
          email: email,
        },
      });
    }

    if (signUpUser.message) {
      if (signUpUser.response) {
        const tokenStatus = isTokenExpired(signUpUser.response.data.status);

        if (tokenStatus) {
          yield put(userSignOut());
        }

        yield put(showAuthMessage(signUpUser.response.data.status));
      } else {
        yield put(showAuthMessage('Something went wrong please, check settings and connections'));
      }
    } else {
      setCookie('happierleads_admin_token', signUpUser.data.jwt);
      yield put(userGoogleSignInSuccess(signUpUser.data.jwt));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* CallDeleteAccount({ payload }) {
  setAuthorizationHeader();
  const id = payload;

  try {
    const callResponse = yield call(deleteAccountRequest, id);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(deleteAccountError(callResponse.response.data.status));
      } else {
        yield put(deleteAccountError('Something went wrong please check your network'));
      }
    } else {
      yield put(deleteAccountSuccess(callResponse.data.status));
      yield put(generalSuccessMessage(callResponse.data.status));
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(deleteAccountError(error));
  }
}
function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      if (signInUser.response) {
        const tokenStatus = isTokenExpired(signInUser.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(showAuthMessage(signInUser.response.data.status));
      } else {
        yield put(showAuthMessage('Something went wrong please, check settings and connections'));
      }
    } else {
      setCookie('happierleads_admin_token', signInUser.data.jwt);

      if (signInUser.data.magic === undefined) {
        setCookie('happierleads_special_token', '');
      } else {

        setCookie('happierleads_special_token', signInUser.data.magic);

      }
      yield put(userSignInSuccess(signInUser.data.jwt));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password, couponCode, appsumoCode, last_utm, original_utm, session, accountFocus, gclid } = payload;
  const cookie = getGaCookie();
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password,
      couponCode,
      appsumoCode,
      session,
      accountFocus,
      last_utm,
      original_utm,
      cookie,
      gclid
    );

    if (window.fpr) {
      window.fpr('referral', { email: email });
    }

    if (window.po) {
      window.po('customers', 'signup', {
        data: {
          key: email,
          email: email,
        },
      });
    }

    if (signUpUser.message) {
      if (signUpUser.response) {
        const tokenStatus = isTokenExpired(signUpUser.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(showAuthMessage(signUpUser.response.data.status));
      } else {
        yield put(showAuthMessage('Something went wrong please, check settings and connections'));
      }
    } else {
      setCookie('happierleads_admin_token', signUpUser.data.jwt);
      if (signUpUser.data.magic === undefined) {
        setCookie('happierleads_special_token', '');
      } else {
        setCookie('happierleads_special_token', signUpUser.data.magic);
      }

      yield put(userSignUpSuccess(signUpUser.data.jwt));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* verifyUserEmail({ payload }) {
  const { token } = payload;
  try {
    const callResponse = yield call(verifyUserEmailRequest, token);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(verifyEmailError());
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(verifyEmailError());
      }
    } else {
      setCookie('happierleads_admin_token', callResponse.data.jwt);
      if (callResponse.data.magic === undefined) {
        setCookie('happierleads_special_token', '');
      } else {
        setCookie('happierleads_special_token', callResponse.data.magic);
      }
      yield put(generalSuccessMessage('Email verified successfully'));
      yield put(verifyEmailSuccess());
    }
  } catch (error) {
    yield put(verifyEmailError());
  }
}

function* currentuserInfo() {
  if (setAuthorizationHeader()) {
    const cookie = getGaCookie();
    try {
      const userDetails = yield call(fetchuserInfo, cookie);

      if (userDetails.data) {
        yield put(setuserInfo(userDetails.data));

        if (userDetails.data.user._id) {
          window.gtag('set', 'user_id', userDetails.data.user._id);

          window.gtag('event', 'user_identified', {
            user_id: userDetails.data.user._id,
          });
        }

        if (userDetails.data.user.email.indexOf('happierleads.com') === -1) {
          // After Register
          if (
            userDetails.data.status === 'onboarding' &&
            userDetails.data.gtm.registered === false &&
            localStorage.getItem('gtm.registered') === null
          ) {
            window.fbq('track', 'StartTrial', {
              value: '69.00',
              currency: 'USD',
              predicted_ltv: '495.00',
            });
            window.dataLayer.push({
              event: 'Registered',
              eventCategory: 'User',
              eventAction: 'Conversion',
              eventLabel: 'Registered',
              eventValue: 0,
            });

            window.gtag('event', 'conversion', {
              send_to: 'AW-623011304/iKSSCNqjmsICEOjLiakC',
              value: 0.2,
              currency: 'USD',
            });

            yield call(changeGTMStatus({ registered: true }));
            localStorage.setItem('gtm.registered', true);
          }
          // After onboarding was successful
          if (
            userDetails.data.status === 'setup-script' &&
            userDetails.data.gtm.onboarding === false &&
            localStorage.getItem('gtm.onboarding') === null
          ) {
            window.fbq('track', 'CompleteRegistration');
            window.dataLayer.push({
              event: 'Onboarding',
              eventCategory: 'User',
              eventAction: 'Conversion',
              eventLabel: 'Onboarding',
              eventValue: 0,
            });

            window.gtag('event', 'conversion', {
              send_to: 'AW-623011304/Fl2oCMeiwsICEOjLiakC',
              value: 0.3,
              currency: 'GBP',
            });

            yield call(changeGTMStatus({ onboarding: true }));
            localStorage.setItem('gtm.onboarding', true);
          }
          // After Activation
          if (
            userDetails.data.status === 'ready' &&
            userDetails.data.gtm.activated === false &&
            localStorage.getItem('gtm.activated') === null
          ) {
            window.fbq('track', 'SubmitApplication');
            window.dataLayer.push({
              event: 'Activated',
              eventCategory: 'User',
              eventAction: 'Conversion',
              eventLabel: 'Activated',
              eventValue: 0,
            });

            window.gtag('event', 'conversion', {
              send_to: 'AW-623011304/9H3PCIuowsICEOjLiakC',
              value: 0.7,
              currency: 'USD',
            });

            yield call(changeGTMStatus({ activated: true }));
            localStorage.setItem('gtm.activated', true);
          }

          if (
            userDetails.data.plan !== 'Trial' &&
            userDetails.data.plan !== 'LTD' &&
            userDetails.data.plan !== 'YEARLY' &&
            userDetails.data.gtm.paidCustomer === false &&
            localStorage.getItem('gtm.paidCustomer') === null
          ) {
            window.fbq('track', 'Purchase', {
              currency: 'USD',
              value: 69.0,
            });
            window.dataLayer.push({
              event: 'Paid Customer',
              eventCategory: 'User',
              eventAction: 'Conversion',
              eventLabel: 'Paid Customer',
              eventValue: 69,
            });

            window.gtag('event', 'conversion', {
              send_to: 'AW-623011304/-qG8CMKTwsICEOjLiakC',
              value: 1.0,
              currency: 'USD',
              transaction_id: '',
            });

            yield call(changeGTMStatus({ paidCustomer: true }));
            localStorage.setItem('gtm.paidCustomer', true);
          }
        }
      }

      if (userDetails.message) {
        if (userDetails.response) {
          const tokenStatus = isTokenExpired(userDetails.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(showAuthMessage(userDetails.response.data.status));
        } else {
          yield put(showAuthMessage('Something went wrong please, check settings and connections'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
}

function* passwordRecoveryAccount({ payload }) {
  const { email } = payload;
  try {
    const recoveryResponse = yield call(recoverPasswordRequest, email);

    if (recoveryResponse.message) {
      if (recoveryResponse.response) {
        const tokenStatus = isTokenExpired(recoveryResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(showAuthMessage(recoveryResponse.response.data.status));
      } else {
        yield put(showAuthMessage('Something went wrong please, check settings and connections'));
      }
    } else {
      yield put(successRecoveryEmail());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* sendResetPasswordAccount({ payload }) {
  const { resetId, newPass } = payload;
  try {
    const resetResponse = yield call(resetPasswordRequest, resetId, newPass);

    if (resetResponse.message) {
      if (resetResponse.response) {
        const tokenStatus = isTokenExpired(resetResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(showAuthMessage(resetResponse.response.data.status));
      } else {
        yield put(showAuthMessage('Something went wrong please, check settings and connections'));
      }
    } else {
      setCookie('happierleads_admin_token', resetResponse.data.jwt);
      yield put(successResetPassword(resetResponse.data.jwt));
      yield put(moveUserToHome());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem('setup-script-url');
    localStorage.removeItem('setup-script-clientId');
    localStorage.removeItem('currentWebsiteId');
    localStorage.removeItem('currentWebsiteUrl');
    localStorage.removeItem('prospectquotaStatus');
    localStorage.clear();
    removeCookie('happierleads_admin_token');
    removeCookie('happierleads_special_token');
    removeCookie('geoLocation');
    yield put(clearAllStatesLead());
    yield put(clearAllStatesLeads());
    yield put(userSignOutSuccess());
    if (window.location.host == 'admin.happierleads.com') {
      window.Intercom('update');
    }

    if (window.location.pathname === '/logout') {
      window.location.replace('/signin');
    } else if (window.location.pathname === '/signin' || window.location.pathname === '/') {
      console.log('');
    } else window.location.replace('/signin');
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}
export function* verifyUser() {
  yield takeEvery(VERIFY_EMAIL_REQUEST, verifyUserEmail);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* userDetails() {
  yield takeEvery(USER_DETAILS_HAPPIER_LEADS, currentuserInfo);
}

export function* sendRecoveryEmailAccount() {
  yield takeEvery(SEND_RECOVERY_EMAIL, passwordRecoveryAccount);
}

export function* sendResetAccount() {
  yield takeEvery(SEND_RESET_PASSWORD, sendResetPasswordAccount);
}
export function* deleteAccount() {
  yield takeEvery(DELETE_MY_ACCOUNT, CallDeleteAccount);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signOutUser),
    fork(verifyUser),
    fork(userDetails),
    fork(sendRecoveryEmailAccount),
    fork(sendResetAccount),
    fork(deleteAccount),
  ]);
}
