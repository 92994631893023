import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired, getGaCookie } from '../../utils/session';

import {
  ADD_NEW_WEBSITES_REQUEST,
  DELETE_WEBSITE_REQUEST,
  WEBSITES_UPDATE_REQUEST,
  UPDATE_WEBSITE_INFORMATION,
  WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY,
  ADD_BLACKLIST_WEBSITE,
  FETCH_BLACKLIST_WEBSITES,
  RENAME_WORKSPACE_REQUEST,
  ADD_NEW_EXCLUDED_PAGES_REQUEST,
  ADD_FAVORITE_WEBSITE_REQUEST,
  REMOVE_FAVORITE_WEBSITE_REQUEST,
  REDEEM_CODE_REQUEST,
} from '../../constants/ActionTypes';

import {
  AddNewWebsiteSuccess,
  AddNewWebsiteError,
  fetchBlacklistWebsitesError,
  fetchBlacklistWebsitesSuccess,
  AddNewExcludedPagesSuccess,
  AddNewExcludedPagesError,
  AddFavoriteWebsiteError,
  AddFavoriteWebsiteSuccess,
  removeFavoriteWebsiteError,
  removeFavoriteWebsiteSuccess,
  RedeemCodeSuccess,
  RedeemCodeError,
  putRequestUpdateWebsiteError,
  putRequestUpdateWebsiteSuccess,
  deleteWebsiteError,
  deleteWebsiteSuccess,
} from '../actions/Websites';
import { getUserDetails, userSignOut } from '../actions/Auth';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

const AddWebsiteRequest = async (name) =>
  await axios
    .post(`${baseUrl}/admin/websites`, { name })
    .then((response) => response)
    .catch((error) => error);

const AddFavoriteWebsiteRequest = async (id) =>
  await axios
    .post(`${baseUrl}/admin/websites/favorite`, { websiteId: id })
    .then((response) => response)
    .catch((error) => error);

const removeFavoriteWebsiteRequest = async (id) =>
  await axios
    .post(`${baseUrl}/admin/websites/remove-favorite`, { websiteId: id })
    .then((response) => response)
    .catch((error) => error);

const deleteWebsiteRequest = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/websites/${id}`)
    .then((response) => response)
    .catch((error) => error);

const updateWebsitesRequest = async (websiteURL, websiteId, cookie, used_platform) =>
  await axios
    .post(`${baseUrl}/utilities/verify-website`, { id: websiteId, websiteURL, ga_clientId: cookie, used_platform })
    .then((response) => response)
    .catch((error) => error);

const updateWebsiteRequest = async (websiteId, data) =>
  await axios
    .put(`${baseUrl}/admin/websites/${websiteId}`, { ...data })
    .then((response) => response)
    .catch((error) => error);

const emailDigestFrequencyRequest = async (websiteId, emailDigestFrequency) =>
  await axios
    .put(`${baseUrl}/admin/websites/${websiteId}`, { emailDigestFrequency })
    .then((response) => response)
    .catch((error) => error);

const changeBlacklistedWebsites = async (websiteId, countryCode) =>
  await axios
    .post(`${baseUrl}/admin/websites/blacklist`, { websiteId, countryCode })
    .then((response) => response)
    .catch((error) => error);

const fetchBlacklistedWebsites = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/websites/blacklist?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const updateWorkspaceRequest = async (websiteId, name) =>
  await axios
    .patch(`${baseUrl}/admin/websites/${websiteId}/rename`, { name })
    .then((response) => response)
    .catch((error) => error);

const AddExcludedPagesRequest = async (websiteId, rules) =>
  await axios
    .post(`${baseUrl}/admin/websites/exclude-pages/${websiteId}`, { rules })
    .then((response) => response)
    .catch((error) => error);

const RedeemCodesRequest = async (accountId, code) =>
  await axios
    .post(`${baseUrl}/admin/appsumo-redeem`, { accountId, code })
    .then((response) => response)
    .catch((error) => error);

// eslint-disable-next-line no-unused-vars
function* CallAddWebsites({ payload }) {
  setAuthorizationHeader();
  const { name } = payload;

  try {
    const callResponse = yield call(AddWebsiteRequest, name);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(AddNewWebsiteError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(AddNewWebsiteSuccess('Workspace successfully added'));
      yield put(generalSuccessMessage('Workspace successfully added'));
    }
  } catch (error) {
    yield put(AddNewWebsiteError());
    yield put(generalErrorMessage(error));
  }
}

function* CallAddFavoriteWebsite({ payload }) {
  setAuthorizationHeader();
  const { id } = payload;

  try {
    const callResponse = yield call(AddFavoriteWebsiteRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(AddFavoriteWebsiteError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(AddFavoriteWebsiteSuccess('Added favorite website successfully...'));
      yield put(generalSuccessMessage('Added favorite website successfully...'));
    }
  } catch (error) {
    yield put(AddFavoriteWebsiteError());
    yield put(generalErrorMessage(error));
  }
}

function* CallRemoveFavoriteWebsite({ payload }) {
  setAuthorizationHeader();
  const { id } = payload;

  try {
    const callResponse = yield call(removeFavoriteWebsiteRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(removeFavoriteWebsiteError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(removeFavoriteWebsiteSuccess('Removed favorite website successfully...'));
      yield put(generalSuccessMessage('Removed favorite website successfully...'));
    }
  } catch (error) {
    yield put(removeFavoriteWebsiteError());
    yield put(generalErrorMessage(error));
  }
}

function* CallDeleteWebsites({ payload }) {
  setAuthorizationHeader();
  const id = payload;

  try {
    const callResponse = yield call(deleteWebsiteRequest, id);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(deleteWebsiteError(callResponse.response.data.status));
      } else {
        yield put(deleteWebsiteError('Something went wrong please check your network'));
      }
    } else {
      yield put(deleteWebsiteSuccess(callResponse.data.status));
      // yield put(generalSuccessMessage(callResponse.data.status));
      yield put(getUserDetails());
    }
  } catch (error) {
    yield put(deleteWebsiteError(error));
  }
}

function* updateCallWebsites({ payload }) {
  const { websiteURL, websiteId, used_platform } = payload;
  setAuthorizationHeader();
  const cookie = getGaCookie();

  try {
    const callResponse = yield call(updateWebsitesRequest, websiteURL, websiteId, cookie, used_platform);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        // yield put(generalErrorMessage(callResponse.response.data?.reason));
        yield put(putRequestUpdateWebsiteError(callResponse.response.data?.reason));
      } else {
        yield put(putRequestUpdateWebsiteError('Something went wrong please check your network'));
      }
    } else {
      if (callResponse?.data?.verified) {
        yield put(getUserDetails());
        yield put(putRequestUpdateWebsiteSuccess('website verified successfully...'));
        yield put(generalSuccessMessage('website verified successfully...'));
      } else {
        yield put(putRequestUpdateWebsiteError(callResponse?.data?.reason));
        yield put(generalErrorMessage(callResponse?.data?.reason));
      }
    }
  } catch (error) {
    //
    yield put(putRequestUpdateWebsiteError(error));
  }
}

function* updateWebsiteInfo({ payload }) {
  const { websiteId, GDPRSuppression, leadScoreData } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateWebsiteRequest, websiteId, { GDPRSuppression, ...leadScoreData });

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(putRequestUpdateWebsiteError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(putRequestUpdateWebsiteError('Something went wrong please check your network'));
        yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(putRequestUpdateWebsiteSuccess('Updated successfully...'));
      yield put(generalSuccessMessage('Info saved successfully...'));
    }
  } catch (error) {
    //
  }
}

function* updateEmailFrequency({ payload }) {
  const { websiteId, emailDigestFrequency } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(emailDigestFrequencyRequest, websiteId, emailDigestFrequency);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        //
      } else {
        //
      }
    } else {
      yield put(getUserDetails());
      yield put(generalSuccessMessage('Email frequency updated successfully...'));
    }
  } catch (error) {
    //
  }
}

function* updateWebsiteBlacklist({ payload }) {
  const { websiteId, countryCode } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(changeBlacklistedWebsites, websiteId, countryCode);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        //
      } else {
        //
      }
    } else {
      yield put(getUserDetails());
      yield put(generalSuccessMessage('Country blacklisted successfully...'));
    }
  } catch (error) {
    //
  }
}

function* fetchBlacklistWebsites({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(fetchBlacklistedWebsites, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(fetchBlacklistWebsitesError(callResponse.response.data.status));
      } else {
        yield put(fetchBlacklistWebsitesError('Something went wrong please check your network'));
      }
    } else {
      yield put(fetchBlacklistWebsitesSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(fetchBlacklistWebsitesError(error));
  }
}

function* updateWorkspace({ payload }) {
  setAuthorizationHeader();
  const { websiteId, name } = payload;

  try {
    const callResponse = yield call(updateWorkspaceRequest, websiteId, name);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
      } else {
        // yield put(
        //   RenameWorkspaceError('Something went wrong please check your network')
        // )
      }
    } else {
      yield put(getUserDetails());
      yield put(generalSuccessMessage('Workspace renamed successfully...'));
    }
  } catch (error) {
    // yield put(RenameWorkspaceError())
    // yield put(generalErrorMessage(error))
  }
}

function* CallAddExcludedPages({ payload }) {
  setAuthorizationHeader();
  const { websiteId, rules } = payload;

  try {
    const callResponse = yield call(AddExcludedPagesRequest, websiteId, rules);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(AddNewExcludedPagesError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(AddNewExcludedPagesSuccess('Added excluded url paths successfully...'));
      yield put(generalSuccessMessage('Added excluded rules successfully...'));
    }
  } catch (error) {
    yield put(AddNewExcludedPagesError());
    yield put(generalErrorMessage(error));
  }
}

function* CallRedeemCode({ payload }) {
  setAuthorizationHeader();
  const { accountId, code } = payload;

  try {
    const callResponse = yield call(RedeemCodesRequest, accountId, code);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(RedeemCodeError(callResponse.response.data.error));
      } else {
        yield put(RedeemCodeError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(RedeemCodeSuccess('Code is successfully redeemed'));
      yield put(generalSuccessMessage('Code is successfully redeemed'));
    }
  } catch (error) {
    yield put(RedeemCodeError());
    yield put(generalErrorMessage(error));
  }
}

export function* postRequestWebsites() {
  yield takeEvery(ADD_NEW_WEBSITES_REQUEST, CallAddWebsites);
}

export function* postFavoriteWebsite() {
  yield takeEvery(ADD_FAVORITE_WEBSITE_REQUEST, CallAddFavoriteWebsite);
}
export function* postRemoveFavoriteWebsite() {
  yield takeEvery(REMOVE_FAVORITE_WEBSITE_REQUEST, CallRemoveFavoriteWebsite);
}

export function* deleteRequestWebsites() {
  yield takeEvery(DELETE_WEBSITE_REQUEST, CallDeleteWebsites);
}

export function* putRequestWebsites() {
  yield takeEvery(WEBSITES_UPDATE_REQUEST, updateCallWebsites);
}

export function* putRequestWebsiteInformation() {
  yield takeEvery(UPDATE_WEBSITE_INFORMATION, updateWebsiteInfo);
}

export function* putRequestEmailDigestFrequency() {
  yield takeEvery(WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY, updateEmailFrequency);
}

export function* putRequestAddBlacklistWebsite() {
  yield takeEvery(ADD_BLACKLIST_WEBSITE, updateWebsiteBlacklist);
}

export function* putRequestfetchBlacklistWebsites() {
  yield takeEvery(FETCH_BLACKLIST_WEBSITES, fetchBlacklistWebsites);
}

export function* putRequestRenameWorkspace() {
  yield takeEvery(RENAME_WORKSPACE_REQUEST, updateWorkspace);
}

export function* postRequestExcludedPages() {
  yield takeEvery(ADD_NEW_EXCLUDED_PAGES_REQUEST, CallAddExcludedPages);
}

export function* postRequestRedeemCode() {
  yield takeEvery(REDEEM_CODE_REQUEST, CallRedeemCode);
}

export default function* rootSaga() {
  yield all([
    fork(postRequestWebsites),
    fork(postFavoriteWebsite),
    fork(postRemoveFavoriteWebsite),
    fork(deleteRequestWebsites),
    fork(putRequestWebsites),
    fork(putRequestWebsiteInformation),
    fork(putRequestEmailDigestFrequency),
    fork(putRequestAddBlacklistWebsite),
    fork(putRequestfetchBlacklistWebsites),
    fork(putRequestRenameWorkspace),
    fork(postRequestExcludedPages),
    fork(postRequestRedeemCode),
  ]);
}
