import {
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_SUCCESS,
  INVITE_MEMBERS_ERROR,
  INVITE_MEMBERS_CLEAR_MESSAGES,
} from '../../constants/ActionTypes'

export const inviteMembersRequest = (params) => {
  return {
    type: INVITE_MEMBERS_REQUEST,
    payload: params,
  }
}

export const inviteMembersSuccess = (data) => {
  return {
    type: INVITE_MEMBERS_SUCCESS,
    payload: data,
  }
}

export const inviteMembersError = (error) => {
  return {
    type: INVITE_MEMBERS_ERROR,
    payload: error,
  }
}

export const inviteMembersClearMessages = () => {
  return {
    type: INVITE_MEMBERS_CLEAR_MESSAGES,
  }
}
