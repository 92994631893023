import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import { GET_COUNTRY } from '../../constants/ActionTypes'

import { getCountrySuccess } from '../actions/Utilities'

import { userSignOut } from '../actions/Auth'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
  setCookie,
} from '../../utils/session'

const getCountryCall = async () =>
  await axios
    .get(`${baseUrl}/utilities/get-country`)
    .then((response) => response)
    .catch((error) => error)

function* getCountryRequest() {
  setAuthorizationHeader()
  try {
    const callResponse = yield call(getCountryCall)

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(getCountrySuccess({ country_code: 'gb' }))
      } else {
        yield put(getCountrySuccess({ country_code: 'gb' }))
      }
    } else {
      yield put(getCountrySuccess(callResponse.data))
      const userLocation =
        callResponse.data !== null ? callResponse.data.country_code : ''
      setCookie('geoLocation', userLocation)
    }
  } catch (error) {}
}

export function* getCountryHook() {
  yield takeEvery(GET_COUNTRY, getCountryRequest)
}

export default function* rootSaga() {
  yield all([fork(getCountryHook)])
}
