import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { generalMessageClear } from '../../redux/actions/GeneralMessage'
import { toast } from 'react-toastify'

const GeneralMessage = () => {
  const dispatch = useDispatch()
  const { generalSuccess, generalError, generalMessage } = useSelector(
    ({ generalMessage }) => generalMessage
  )

  const notifyError = useCallback(() => {
    toast.error(generalMessage, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    })
  }, [generalMessage])

  const notifySuccess = useCallback(() => {
    toast.success(generalMessage, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    })
  }, [generalMessage])

  useEffect(() => {
    if (generalError) {
      notifyError()
      dispatch(generalMessageClear())
    }
  }, [generalError, notifyError, dispatch])

  useEffect(() => {
    if (generalSuccess) {
      notifySuccess()
      dispatch(generalMessageClear())
    }
  }, [generalSuccess, notifySuccess, dispatch])

  return <div></div>
}

export default GeneralMessage
