import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import {
  GET_SALESFORCE_OWNERS,
  SAVE_NEW_SALESFORCE_COMPANY,
  SEARCH_SALESFORCE_COMPANY,
  CONNECT_EXISTING_SALESFORCE_COMPANY,
  CONNECT_SALESFORCE_CONTACTS,
} from '../../constants/ActionTypes';

import {
  getSalesforceOwnersError,
  getSalesforceOwnersSuccess,
  saveNewSalesforceCompanyError,
  saveNewSalesforceCompanySuccess,
  searchSalesforceCompanyError,
  searchSalesforceCompanySuccess,
  connectExistingSalesforceCompanyError,
  connectExistingSalesforceCompanySuccess,
  connectSalesforceContactsError,
  connectSalesforceContactsSuccess,
} from '../actions/Salesforce';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { updateCountLeads, updateCurrentPage } from '../actions/Leads';

const getSalesforceOwnersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/integrations/salesforce/owner?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const searchSalesforceCompanyRequest = async (websiteId, searchTerm) =>
  await axios
    .get(`${baseUrl}/admin/integrations/salesforce/company?websiteId=${websiteId}&searchTerm=${searchTerm}`)
    .then((response) => response)
    .catch((error) => error);

const saveNewSalesforceCompanyRequest = async (websiteId, domain, ownerId, leadId, name) =>
  await axios
    .post(`${baseUrl}/admin/integrations/salesforce/company`, {
      websiteId,
      domain,
      ownerId,
      leadId,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const connectSalesforceContactsRequest = async (websiteId, ownerId, email) =>
  await axios
    .post(`${baseUrl}/admin/integrations/salesforce/contact`, {
      websiteId,
      ownerId,
      email,
    })
    .then((response) => response)
    .catch((error) => error);

const connectExistingSalesforceCompanyRequest = async (websiteId, salesforceCompanyId, leadId) =>
  await axios
    .post(`${baseUrl}/admin/integrations/salesforce/connect`, {
      websiteId,
      salesforceCompanyId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

function* getOwners({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getSalesforceOwnersRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getSalesforceOwnersError());
        // yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(getSalesforceOwnersError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getSalesforceOwnersSuccess(callResponse.data));
      // yield put(generalSuccessMessage(callResponse.data));
    }
  } catch (error) {
    yield put(getSalesforceOwnersError(error));
  }
}

function* searchSalesforceCompany({ payload }) {
  const { websiteId, searchTerm } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(searchSalesforceCompanyRequest, websiteId, searchTerm);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(searchSalesforceCompanyError());
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(searchSalesforceCompanyError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(searchSalesforceCompanySuccess(callResponse.data));
    }
  } catch (error) {
    yield put(searchSalesforceCompanyError(error));
  }
}

function* saveNewSalesforceCompany({ payload }) {
  const { websiteId, domain, ownerId, leadId, name } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveNewSalesforceCompanyRequest, websiteId, domain, ownerId, leadId, name);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(saveNewSalesforceCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(saveNewSalesforceCompanyError());
      }
    } else {
      yield put(saveNewSalesforceCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company save Failed...'));
    yield put(saveNewSalesforceCompanyError());
  }
}

function* connectSalesforceContacts({ payload }) {
  const { websiteId, ownerId, email } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectSalesforceContactsRequest, websiteId, ownerId, email);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectSalesforceContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectSalesforceContactsError());
      }
    } else {
      yield put(connectSalesforceContactsSuccess());
      yield put(generalSuccessMessage('Contact saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(connectSalesforceContactsError());
  }
}

function* connectExistingSalesforceCompany({ payload }) {
  const { websiteId, salesforceCompanyId, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectExistingSalesforceCompanyRequest, websiteId, salesforceCompanyId, leadId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectExistingSalesforceCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectExistingSalesforceCompanyError());
      }
    } else {
      yield put(connectExistingSalesforceCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company connected successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company connection Failed...'));
    yield put(connectExistingSalesforceCompanyError());
  }
}

export function* fetchSalesforceOwners() {
  yield takeEvery(GET_SALESFORCE_OWNERS, getOwners);
}

export function* postSaveNewSalesforceCompany() {
  yield takeEvery(SAVE_NEW_SALESFORCE_COMPANY, saveNewSalesforceCompany);
}

export function* postConnectSalesforceContacts() {
  yield takeEvery(CONNECT_SALESFORCE_CONTACTS, connectSalesforceContacts);
}

export function* postSearchSalesforceCompany() {
  yield takeEvery(SEARCH_SALESFORCE_COMPANY, searchSalesforceCompany);
}

export function* postConnectExistingSalesforceCompany() {
  yield takeEvery(CONNECT_EXISTING_SALESFORCE_COMPANY, connectExistingSalesforceCompany);
}

export default function* rootSaga() {
  yield all([
    fork(fetchSalesforceOwners),
    fork(postSaveNewSalesforceCompany),
    fork(postConnectSalesforceContacts),
    fork(postSearchSalesforceCompany),
    fork(postConnectExistingSalesforceCompany),
  ]);
}
