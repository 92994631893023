import {
  GET_HUBSPOT_OWNERS,
  GET_HUBSPOT_OWNERS_SUCCESS,
  GET_HUBSPOT_OWNERS_ERROR,
  SAVE_NEW_COMPANY,
  SAVE_NEW_COMPANY_SUCCESS,
  SAVE_NEW_COMPANY_ERROR,
  SEARCH_COMPANY,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_ERROR,
  CONNECT_EXISTING_COMPANY,
  CONNECT_EXISTING_COMPANY_SUCCESS,
  CONNECT_EXISTING_COMPANY_ERROR,
  CONNECT_CONTACTS,
  CONNECT_CONTACTS_SUCCESS,
  CONNECT_CONTACTS_ERROR,
  CLEAR_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

export const getHubSpotOwners = (id) => {
  return {
    type: GET_HUBSPOT_OWNERS,
    payload: id,
  }
}

export const getHubSpotOwnersSuccess = (data) => {
  return {
    type: GET_HUBSPOT_OWNERS_SUCCESS,
    payload: data,
  }
}

export const getHubSpotOwnersError = () => {
  return {
    type: GET_HUBSPOT_OWNERS_ERROR,
  }
}

export const saveNewCompany = (data) => {
  return {
    type: SAVE_NEW_COMPANY,
    payload: data,
  }
}

export const saveNewCompanySuccess = (data) => {
  return {
    type: SAVE_NEW_COMPANY_SUCCESS,
    payload: data,
  }
}

export const saveNewCompanyError = () => {
  return {
    type: SAVE_NEW_COMPANY_ERROR,
  }
}

export const searchCompany = (data) => {
  return {
    type: SEARCH_COMPANY,
    payload: data,
  }
}

export const searchCompanySuccess = (data) => {
  return {
    type: SEARCH_COMPANY_SUCCESS,
    payload: data,
  }
}

export const searchCompanyError = () => {
  return {
    type: SEARCH_COMPANY_ERROR,
  }
}

export const connectExistingCompany = (data) => {
  return {
    type: CONNECT_EXISTING_COMPANY,
    payload: data,
  }
}

export const connectExistingCompanySuccess = (data) => {
  return {
    type: CONNECT_EXISTING_COMPANY_SUCCESS,
    payload: data,
  }
}

export const connectExistingCompanyError = () => {
  return {
    type: CONNECT_EXISTING_COMPANY_ERROR,
  }
}

export const connectContacts = (data) => {
  return {
    type: CONNECT_CONTACTS,
    payload: data,
  }
}

export const connectContactsSuccess = (data) => {
  return {
    type: CONNECT_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const connectContactsError = () => {
  return {
    type: CONNECT_CONTACTS_ERROR,
  }
}
export const clearContactsError = () => {
  return {
    type: CLEAR_CONTACTS_ERROR,
  }
}
