import {
  WEBHOOK_UPDATE_REQUEST,
  WEBHOOK_UPDATE_SUCCESS,
  WEBHOOK_UPDATE_ERROR,
  SET_WEBHOOK_URL,
  WEBHOOK_CLEAR_MESSAGES,
  PERMIT_SETUP_SCRIPTS,
  REJECT_SETUP_SCRIPTS,
  SEND_TEST_URL,
  SET_WEBHOOK_WEBSITE_ID,
  SUCCESS_SEND_TEST_WEBHOOK,
  CLEAR_SEND_TEST_WEBHOOK,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  webhookUpdateDetails: {},
  success: false,
  message: '',
  error: false,
  webhookURL: '',
  websiteId: '',
  allowSetUpScript: false,
  sendTestURl: '',
  sendTestSuccess: false,
};

const Webhooks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEBHOOK_UPDATE_REQUEST: {
      return {
        ...state,
        loader: true,
        webhookUpdateDetails: action.payload,
      };
    }

    case WEBHOOK_UPDATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        success: true,
      };
    }
    case WEBHOOK_UPDATE_ERROR: {
      return {
        ...state,
        error: true,
        message: action.payload,
        loader: false,
      };
    }

    case SET_WEBHOOK_URL: {
      return {
        ...state,
        webhookURL: action.payload.webhook,
        websiteId: action.payload.websiteId,
      };
    }

    case SET_WEBHOOK_WEBSITE_ID: {
      return {
        ...state,
        websiteId: action.payload,
      };
    }

    case WEBHOOK_CLEAR_MESSAGES: {
      return {
        ...state,
        loader: false,
        success: false,
        error: false,
        message: '',
      };
    }

    case PERMIT_SETUP_SCRIPTS: {
      return {
        ...state,
        allowSetUpScript: true,
      };
    }

    case REJECT_SETUP_SCRIPTS: {
      return {
        ...state,
        allowSetUpScript: false,
      };
    }

    case SEND_TEST_URL: {
      return {
        ...state,
        loader: true,
        sendTestURl: action.payload,
      };
    }

    case SUCCESS_SEND_TEST_WEBHOOK: {
      return {
        ...state,
        loader: false,
        sendTestSuccess: true,
      };
    }
    case CLEAR_SEND_TEST_WEBHOOK: {
      return {
        ...state,
        sendTestSuccess: false,
        sendTestURl: '',
        loader: false,
      };
    }

    default:
      return state;
  }
};

export default Webhooks;
