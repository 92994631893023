import {
  DOWNLOAD_PROSPECTOR_CSV,
  DOWNLOAD_PROSPECTOR_CSV_SUCCESS,
  DOWNLOAD_PROSPECTOR_CSV_ERROR,
  CLEAR_PROSPECTOR_CSV_ERROR,
  SET_PROSPECTOR_CSV_PARAMS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  prospectorCsvLoading: false,
  error: false,
  prospectorCsvSuccess: false,
  prospector: [],
  prospectorCSVParams: {},
}

const ProspectorCSVfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_PROSPECTOR_CSV: {
      return {
        ...state,
        prospector: action.payload,
        prospectorCsvSuccess: false,
        prospectorCsvLoading: true,
      }
    }
    case DOWNLOAD_PROSPECTOR_CSV_SUCCESS: {
      return {
        ...state,
        prospectorCsvLoading: false,
        prospectorCsvSuccess: true,
        error: false,
      }
    }
    case DOWNLOAD_PROSPECTOR_CSV_ERROR: {
      return {
        ...state,
        prospectorCsvLoading: false,
        prospectorCsvSuccess: false,
      }
    }

    case CLEAR_PROSPECTOR_CSV_ERROR: {
      return {
        ...state,
        prospectorCsvLoading: false,
        prospectorCsvSuccess: false,
        error: false,
      }
    }

    case SET_PROSPECTOR_CSV_PARAMS: {
      return {
        ...state,
        prospectorCSVParams: action.payload,
      }
    }
    default:
      return state
  }
}

export default ProspectorCSVfile
