import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';
import {
  SAVE_LEAD_SCORING_MODEL,
  GET_LEAD_SCORING_MODELS,
  DELETE_LEAD_SCORING_MODEL,
  UPDATE_LEAD_SCORING_MODEL,
  GET_LEAD_SCORING_MODEL,
  SAVE_LEAD_JOB_TITLE_SCORING_MODEL,
} from '../../constants/ActionTypes';

import {
  saveLeadScoringModelError,
  saveLeadScoringModelSuccess,
  getLeadScoringModelsSuccess,
  getLeadScoringModelsError,
  getLeadScoringModels,
  deleteLeadScoringModelSuccess,
  deleteLeadScoringModelError,
  updateLeadScoringModelError,
  updateLeadScoringModelSuccess,
  getLeadScoringModelError,
  getLeadScoringModelSuccess,
  saveLeadScoringModelJobTitleError,
  saveLeadScoringModelJobTitleSuccess,
} from '../actions/LeadScoring';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';

const saveLeadScoringModelRequest = async (name, websiteId) =>
  await axios
    .post(`${baseUrl}/admin/lead-scoring`, {
      name,
      websiteId,
    })
    .then((response) => response)
    .catch((error) => error);

    const saveJobTitleToLeadScoringModelRequest = async (jobTitle,leadscorings) =>
      await axios
        .post(`${baseUrl}/admin/lead-scoring/add-jobtitle`, {
          jobTitle,
          leadscorings,
        })
        .then((response) => response)
        .catch((error) => error);

const updateLeadScoringModelRequest = async (id, data) =>
  await axios
    .put(`${baseUrl}/admin/lead-scoring/${id}`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteLeadScoringModelRequest = async (name) =>
  await axios
    .delete(`${baseUrl}/admin/lead-scoring/${name}`, {
      id: name,
    })
    .then((response) => response)
    .catch((error) => error);

const fetchLeadScoringModelsRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/lead-scoring?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const fetchSingleLeadScoringModelRequest = async (id) =>
  await axios
    .get(`${baseUrl}/admin/lead-scoring/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* fetchSaveLeadScoringModel({ payload }) {
  const { name, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveLeadScoringModelRequest, name, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(saveLeadScoringModelError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(saveLeadScoringModelError('Something went wrong please check your network'));
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getLeadScoringModels({ websiteId }));

      yield put(saveLeadScoringModelSuccess(callResponse.data.result));
      yield put(generalSuccessMessage('Model saved successfully'));
    }
  } catch (error) {
    yield put(saveLeadScoringModelError(error));
    yield put(generalErrorMessage(error));
  }
}
function* fetchSaveJobTitleLeadScoringModel({ payload }) {
  const { jobTitle,leadscorings } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveJobTitleToLeadScoringModelRequest, jobTitle, leadscorings);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(saveLeadScoringModelJobTitleError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(saveLeadScoringModelJobTitleError('Something went wrong please check your network'));
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {

      yield put(saveLeadScoringModelJobTitleSuccess(callResponse.data.result));
      yield put(generalSuccessMessage('Job title(s) added to lead scoring models successfully'));
    }
  } catch (error) {
    yield put(saveLeadScoringModelJobTitleError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchUpdateLeadScoringModel({ payload }) {
  const { id, data, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateLeadScoringModelRequest, id, data);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(updateLeadScoringModelError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(updateLeadScoringModelError('Something went wrong please check your network'));
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(updateLeadScoringModelSuccess());
      yield put(generalSuccessMessage('Model is being updated'));
      yield put(getLeadScoringModels({ websiteId }));
    }
  } catch (error) {
    yield put(updateLeadScoringModelError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchDeleteLeadScoringModel({ payload }) {
  const { id, type, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteLeadScoringModelRequest, id, type, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(deleteLeadScoringModelError(callResponse.response.data.status));
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(deleteLeadScoringModelError('Something went wrong please check your network'));
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(deleteLeadScoringModelSuccess());
      yield put(generalSuccessMessage('LeadScoringModel deleted successfully'));
      yield put(getLeadScoringModels({ websiteId }));
    }
  } catch (error) {
    yield put(deleteLeadScoringModelError(error));
    yield put(generalErrorMessage(error));
  }
}

function* fetchLeadScoringModels({ payload }) {
  setAuthorizationHeader();
  const { websiteId } = payload;
  try {
    const callResponse = yield call(fetchLeadScoringModelsRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getLeadScoringModelsError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getLeadScoringModelsError());
      }
    } else {
      yield put(getLeadScoringModelsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getLeadScoringModelsError());
    yield put(generalErrorMessage(error));
  }
}

function* fetchSingleLeadScoringModel({ payload }) {
  const { id } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchSingleLeadScoringModelRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getLeadScoringModelError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getLeadScoringModelError());
      }
    } else {
      yield put(getLeadScoringModelSuccess(callResponse.data.response));
    }
  } catch (error) {
    yield put(getLeadScoringModelError());
    yield put(generalErrorMessage(error));
  }
}

export function* postRequestSaveLeadScoringModel() {
  yield takeEvery(SAVE_LEAD_SCORING_MODEL, fetchSaveLeadScoringModel);
}
export function* postRequestSaveJobTitleToLeadScoring() {
  yield takeEvery(SAVE_LEAD_JOB_TITLE_SCORING_MODEL, fetchSaveJobTitleLeadScoringModel);
}
export function* putRequestUpdateLeadScoringModel() {
  yield takeEvery(UPDATE_LEAD_SCORING_MODEL, fetchUpdateLeadScoringModel);
}
export function* deleteRequestDeleteLeadScoringModel() {
  yield takeEvery(DELETE_LEAD_SCORING_MODEL, fetchDeleteLeadScoringModel);
}

export function* getRequestFetchLeadScoringModels() {
  yield takeEvery(GET_LEAD_SCORING_MODELS, fetchLeadScoringModels);
}

export function* getRequestFetchSingleLeadScoringModel() {
  yield takeEvery(GET_LEAD_SCORING_MODEL, fetchSingleLeadScoringModel);
}

export default function* rootSaga() {
  yield all([
    fork(postRequestSaveLeadScoringModel),
    fork(postRequestSaveJobTitleToLeadScoring),
    fork(getRequestFetchLeadScoringModels),
    fork(getRequestFetchSingleLeadScoringModel),
    fork(putRequestUpdateLeadScoringModel),
    fork(deleteRequestDeleteLeadScoringModel),
  ]);
}
