import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  LEAD_FETCH_DATA_REQUEST,
  ADD_TAGS_LEAD_PROFILE,
  MODIFY_TAGS_LEAD_PROFILE,
  DELETE_TAGS_LEAD_PROFILE,
  HIDE_LEAD_PROFILE,
  DELETE_LEADS_REQUEST,
  GET_LEAD_AI_SUMMARY,
} from '../../constants/ActionTypes';
import { userSignOut, getUserDetails } from '../actions/Auth';
import {
  leadFetchDataError,
  leadFetchDataSuccess,
  modifyTagsLeadProfile,
  successModifyTagsLeadProfile,
  successDeleteTagsLeadProfile,
  hideLeadProfileFailed,
  hideLeadProfileSuccess,
  deleteLeadsError,
  deleteLeadsSuccess,
  getLeadAiSummaryFailed,
  getLeadAiSummarySuccess,
} from '../actions/Lead';

import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';
import { updateExistingTags, updateCountLeads } from '../actions/Leads';

const fetchLeadHttpCall = async (leadId) =>
  await axios
    .get(`${baseUrl}/admin/lead/${leadId}`)
    .then((lead) => lead)
    .catch((error) => error);
const fetchLeadSummaryRequest = async (leadId) =>
  await axios
    .get(`${baseUrl}/admin/lead/${leadId}/summary`)
    .then((summary) => summary)
    .catch((error) => error);
const deleteLeadRequest = async (leadIds) =>
  await axios
    .delete(`${baseUrl}/admin/lead/multiple?leadIds=${leadIds}`)
    .then((response) => response)
    .catch((error) => error);

const addProfileTagAsync = async (websiteId, colour, text) =>
  await axios
    .post(`${baseUrl}/admin/tags`, {
      websiteId,
      colour,
      text: text.toUpperCase(),
    })
    .then((response) => response)
    .catch((error) => error);

const modifyProfileTagAsync = async (tagId, leadId) =>
  await axios
    .put(`${baseUrl}/admin/lead-tags`, {
      tagId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteProfileTagAsync = async (tagId, leadId) =>
  await axios
    .delete(`${baseUrl}/admin/lead-tags?tagId=${tagId}&leadId=${leadId}`)
    .then((response) => response)
    .catch((error) => error);

const hideLeadProfileAsync = async (leadId, report) =>
  await axios
    .put(`${baseUrl}/admin/hide-leads`, {
      leadId,
      report,
    })
    .then((response) => response)
    .catch((error) => error);

function* fetchLead({ payload }) {
  if(setAuthorizationHeader()){
    const leadId = payload;
    try {
      const lead = yield call(fetchLeadHttpCall, leadId);
  
      if (lead.message) {
        if (lead.response) {
          const tokenStatus = isTokenExpired(lead.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(leadFetchDataError(lead.response.data.status));
        } else {
          yield put(leadFetchDataError('Something went wrong please check your network'));
        }
      } else {
        yield put(leadFetchDataSuccess(lead.data));
      }
    } catch (error) {
      yield put(leadFetchDataError(error));
    }
  }

}
function* fetchLeadSummary({ payload }) {
  setAuthorizationHeader();
  const leadId = payload;
  try {
    const response = yield call(fetchLeadSummaryRequest, leadId);

    if (response.message) {
      if (response.response) {
        const tokenStatus = isTokenExpired(response.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getLeadAiSummaryFailed(response.response.data.status));
      } else {
        yield put(getLeadAiSummaryFailed('Something went wrong please check your network'));
      }
    } else {
      yield put(getLeadAiSummarySuccess(response.data));
    }
  } catch (error) {
    yield put(getLeadAiSummaryFailed(error));
  }
}

function* addRequestProfileTag({ payload }) {
  const { websiteId, colour, text, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(addProfileTagAsync, websiteId, colour, text);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(leadFetchDataError(callResponse.response.data.status));
      } else {
        yield put(leadFetchDataError('Something went wrong please check your network'));
      }
    } else {
      const tagId = callResponse.data.tagId;
      const textUpperCase = text.toUpperCase();
      yield put(
        modifyTagsLeadProfile({
          websiteId,
          tagId,
          leadId,
          _id: tagId,
          colour,
          text: textUpperCase,
        })
      );
      yield put(
        updateExistingTags({
          websiteId,
          _id: tagId,
          tagId,
          leadId,
          colour,
          text: textUpperCase,
        })
      );
    }
  } catch (error) {
    yield put(leadFetchDataError(error));
  }
}

function* modifyRequestProfileTag({ payload }) {
  const { tagId, colour, text, leadId, websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(modifyProfileTagAsync, tagId, leadId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(leadFetchDataError(callResponse.response.data.status));
      } else {
        yield put(leadFetchDataError('Something went wrong please check your network'));
      }
    } else {
      const textUpperCase = text.toUpperCase();
      yield put(
        successModifyTagsLeadProfile({
          _id: tagId,
          text: textUpperCase,
          colour,
          leadId,
          websiteId,
        })
      );
      yield put(generalSuccessMessage('Tag Added'));
    }
  } catch (error) {
    yield put(leadFetchDataError(error));
  }
}

function* deleteRequestProfileTag({ payload }) {
  const { tagId, leadId, text } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteProfileTagAsync, tagId, leadId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(leadFetchDataError(callResponse.response.data.status));
      } else {
        yield put(leadFetchDataError('Something went wrong please check your network'));
      }
    } else {
      const textUpperCase = text.toUpperCase();
      yield put(
        successDeleteTagsLeadProfile({
          leadId,
          tagId,
          text: textUpperCase,
        })
      );
      yield put(generalSuccessMessage('Tag Removed'));
    }
  } catch (error) {
    yield put(leadFetchDataError(error));
  }
}
function* deleteLeads({ payload }) {
  const { leadIds } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(deleteLeadRequest, leadIds);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(deleteLeadsError(callResponse.response.data.status));
      } else {
        yield put(deleteLeadsError('Something went wrong please check your network'));
      }
    } else {
      yield put(deleteLeadsSuccess());
      yield put(generalSuccessMessage('Leads deleted Successfully'));
    }
  } catch (error) {
    yield put(deleteLeadsError(error));
  }
}

function* hideRequestProfile({ payload }) {
  const { leadId, report, onPrductPage } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(hideLeadProfileAsync, leadId, report);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(hideLeadProfileFailed());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(hideLeadProfileFailed());
      }
    } else {
      yield put(generalSuccessMessage('Lead was removed successfully'));

      yield put(getUserDetails());

      yield put(hideLeadProfileSuccess());
      if (onPrductPage) {
        yield put(updateCountLeads());
      } else {
        window.location.href('/reveal/[workspace]');
      }
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

export function* fetchLeadHook() {
  yield takeEvery(LEAD_FETCH_DATA_REQUEST, fetchLead);
}
export function* fetchLeadSummaryHook() {
  yield takeEvery(GET_LEAD_AI_SUMMARY, fetchLeadSummary);
}

export function* addProfileTagCall() {
  yield takeEvery(ADD_TAGS_LEAD_PROFILE, addRequestProfileTag);
}

export function* modifyProfileTagCall() {
  yield takeEvery(MODIFY_TAGS_LEAD_PROFILE, modifyRequestProfileTag);
}

export function* deleteProfileTagCall() {
  yield takeEvery(DELETE_TAGS_LEAD_PROFILE, deleteRequestProfileTag);
}

export function* hideProfileCall() {
  yield takeEvery(HIDE_LEAD_PROFILE, hideRequestProfile);
}
export function* deleteLeadsCall() {
  yield takeEvery(DELETE_LEADS_REQUEST, deleteLeads);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLeadHook),
    fork(addProfileTagCall),
    fork(modifyProfileTagCall),
    fork(deleteProfileTagCall),
    fork(hideProfileCall),
    fork(deleteLeadsCall),
    fork(fetchLeadSummaryHook),
  ]);
}
