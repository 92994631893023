import {
  SET_PERSONALIZED_DATA,
  SET_PERSONALIZED_DATA_SUCCESS,
  SET_PERSONALIZED_DATA_ERROR,
} from '../../constants/ActionTypes'

export const setPersonalizedData = (query) => {
  return {
    type: SET_PERSONALIZED_DATA,
    payload: query,
  }
}

export const setPersonalizedDataSuccess = (data) => {
  return {
    type: SET_PERSONALIZED_DATA_SUCCESS,
    payload: data,
  }
}

export const setPersonalizedDataError = () => {
  return {
    type: SET_PERSONALIZED_DATA_ERROR,
  }
}
