import {
  GET_ZOHO_OWNERS,
  GET_ZOHO_OWNERS_SUCCESS,
  GET_ZOHO_OWNERS_ERROR,
  SAVE_NEW_ZOHO_COMPANY,
  SAVE_NEW_ZOHO_COMPANY_SUCCESS,
  SAVE_NEW_ZOHO_COMPANY_ERROR,
  SEARCH_ZOHO_COMPANY,
  SEARCH_ZOHO_COMPANY_SUCCESS,
  SEARCH_ZOHO_COMPANY_ERROR,
  CONNECT_EXISTING_ZOHO_COMPANY,
  CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS,
  CONNECT_EXISTING_ZOHO_COMPANY_ERROR,
  CONNECT_ZOHO_CONTACTS,
  CONNECT_ZOHO_CONTACTS_SUCCESS,
  CONNECT_ZOHO_CONTACTS_ERROR,
  CLEAR_ZOHO_CONTACTS_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  error: false,
  saveZohoCompanyLoading: false,
  saveZohoCompanyError: false,
  saveZohoCompanySuccess: false,
  searchZohoCompanyLoading: false,
  searchZohoCompanyError: false,
  connectCompanyZohoLoading: false,
  connectCompanyZohoError: false,
  connectCompanyZohoSuccess: false,
  connectZohoContactsSuccess: false,
  connectZohoContactsLoading: false,
  connectZohoContactsError: false,
  zohoOwners: [],
  zohoContactEmail: '',
  zohoContactsArr: [],
  existingZohoCompanies: [],
};

const Zoho = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ZOHO_OWNERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ZOHO_OWNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        zohoOwners: action.payload,
      };
    }
    case GET_ZOHO_OWNERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case SAVE_NEW_ZOHO_COMPANY: {
      return {
        ...state,
        saveZohoCompanyLoading: true,
      };
    }
    case SAVE_NEW_ZOHO_COMPANY_SUCCESS: {
      return {
        ...state,
        saveZohoCompanyLoading: false,
        saveZohoCompanySuccess: true,
      };
    }
    case SAVE_NEW_ZOHO_COMPANY_ERROR: {
      return {
        ...state,
        saveZohoCompanyLoading: false,
        saveZohoCompanyError: true,
      };
    }

    case SEARCH_ZOHO_COMPANY: {
      return {
        ...state,
        searchZohoCompanyLoading: true,
      };
    }
    case SEARCH_ZOHO_COMPANY_SUCCESS: {
      return {
        ...state,
        searchZohoCompanyLoading: false,
        existingZohoCompanies: action.payload.data.results,
      };
    }
    case SEARCH_ZOHO_COMPANY_ERROR: {
      return {
        ...state,
        searchZohoCompanyLoading: false,
        searchZohoCompanyError: true,
      };
    }

    case CONNECT_EXISTING_ZOHO_COMPANY: {
      return {
        ...state,
        connectCompanyZohoLoading: true,
      };
    }
    case CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS: {
      return {
        ...state,
        connectCompanyZohoLoading: false,
        connectCompanyZohoSuccess: true,
      };
    }
    case CONNECT_EXISTING_ZOHO_COMPANY_ERROR: {
      return {
        ...state,
        connectCompanyZohoLoading: false,
        connectCompanyZohoError: true,
      };
    }

    case CONNECT_ZOHO_CONTACTS: {
      return {
        ...state,
        connectZohoContactsLoading: true,
        zohoContactEmail: action.payload.email,
        connectZohoContactsSuccess: false,
        connectZohoContactsError: false,
        zohoContactsArr: [...state.zohoContactsArr, action.payload.email],
      };
    }
    case CONNECT_ZOHO_CONTACTS_SUCCESS: {
      return {
        ...state,
        connectZohoContactsLoading: false,
        connectZohoContactsSuccess: true,
        connectZohoContactsError: false,
      };
    }
    case CONNECT_ZOHO_CONTACTS_ERROR: {
      return {
        ...state,
        connectZohoContactsLoading: false,
        connectZohoContactsError: true,
        connectZohoContactsSuccess: false,
      };
    }
    case CLEAR_ZOHO_CONTACTS_ERROR: {
      return {
        ...state,
        connectZohoContactsLoading: false,
        connectZohoContactsError: false,
        // connectZohoContactsSuccess: false,
      };
    }

    default:
      return state;
  }
};

export default Zoho;
