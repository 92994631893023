import {
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_ERROR,
  GET_AUTOMATIONS,
  GET_AUTOMATIONS_SUCCESS,
  GET_AUTOMATIONS_ERROR,
  GET_AUTOMATIONS_HISTORY,
  GET_AUTOMATIONS_HISTORY_SUCCESS,
  GET_AUTOMATIONS_HISTORY_ERROR,
  DISABLE_AUTOMATION,
  DISABLE_AUTOMATION_SUCCESS,
  DISABLE_AUTOMATION_ERROR,
  DELETE_AUTOMATION,
  DELETE_AUTOMATION_SUCCESS,
  DELETE_AUTOMATION_ERROR,
  EDIT_AUTOMATION,
  EDIT_AUTOMATION_DATA,
  CLEAR_AUTOMATION_DATA,
  EDIT_AUTOMATION_SUCCESS,
  EDIT_AUTOMATION_ERROR,
  GET_EMAILCAMPAIGN,
  GET_EMAILCAMPAIGN_SUCCESS,
  GET_EMAILCAMPAIGN_ERROR,
  SEND_CONTACTS_TO_CAMPAIGN,
  SEND_CONTACTS_TO_CAMPAIGN_SUCCESS,
  SEND_CONTACTS_TO_CAMPAIGN_ERROR,
  RE_TRIGGER_AUTOMATION,
  RE_TRIGGER_AUTOMATION_SUCCESS,
  RE_TRIGGER_AUTOMATION_ERROR,
} from '../../constants/ActionTypes';

export const createAutomation = (data) => {
  return {
    type: CREATE_AUTOMATION,
    payload: data,
  };
};
export const createAutomationSuccess = () => {
  return {
    type: CREATE_AUTOMATION_SUCCESS,
  };
};
export const createAutomationError = () => {
  return {
    type: CREATE_AUTOMATION_ERROR,
  };
};
export const reTriggerAutomation = (data) => {
  return {
    type: RE_TRIGGER_AUTOMATION,
    payload: data,
  };
};
export const reTriggerAutomationSuccess = () => {
  return {
    type: RE_TRIGGER_AUTOMATION_SUCCESS,
  };
};
export const reTriggerAutomationError = () => {
  return {
    type: RE_TRIGGER_AUTOMATION_ERROR,
  };
};

export const getAutomations = (id) => {
  return {
    type: GET_AUTOMATIONS,
    payload: id,
  };
};
export const getAutomationsSuccess = (data) => {
  return {
    type: GET_AUTOMATIONS_SUCCESS,
    payload: data,
  };
};
export const getAutomationsError = () => {
  return {
    type: GET_AUTOMATIONS_ERROR,
  };
};
export const getAutomationsHistory = (id) => {
  return {
    type: GET_AUTOMATIONS_HISTORY,
    payload: id,
  };
};
export const getAutomationsHistorySuccess = (data) => {
  return {
    type: GET_AUTOMATIONS_HISTORY_SUCCESS,
    payload: data,
  };
};
export const getAutomationsHistoryError = () => {
  return {
    type: GET_AUTOMATIONS_HISTORY_ERROR,
  };
};

export const disableAutomation = (data) => {
  return {
    type: DISABLE_AUTOMATION,
    payload: data,
  };
};
export const disableAutomationSuccess = () => {
  return {
    type: DISABLE_AUTOMATION_SUCCESS,
  };
};
export const disableAutomationError = () => {
  return {
    type: DISABLE_AUTOMATION_ERROR,
  };
};

export const deleteAutomation = (id) => {
  return {
    type: DELETE_AUTOMATION,
    payload: id,
  };
};
export const deleteAutomationSuccess = () => {
  return {
    type: DELETE_AUTOMATION_SUCCESS,
  };
};
export const deleteAutomationError = () => {
  return {
    type: DELETE_AUTOMATION_ERROR,
  };
};

export const editAutomation = (data) => {
  return {
    type: EDIT_AUTOMATION,
    payload: data,
  };
};
export const editAutomationData = (data) => {
  return {
    type: EDIT_AUTOMATION_DATA,
    payload: data,
  };
};
export const clearAutomationData = () => {
  return {
    type: CLEAR_AUTOMATION_DATA,
  };
};
export const editAutomationSuccess = () => {
  return {
    type: EDIT_AUTOMATION_SUCCESS,
  };
};
export const editAutomationError = () => {
  return {
    type: EDIT_AUTOMATION_ERROR,
  };
};

export const getEmailCampaign = (id) => {
  return {
    type: GET_EMAILCAMPAIGN,
    payload: id,
  };
};
export const getEmailCampaignSuccess = (data) => {
  return {
    type: GET_EMAILCAMPAIGN_SUCCESS,
    payload: data,
  };
};
export const getEmailCampaignError = () => {
  return {
    type: GET_EMAILCAMPAIGN_ERROR,
  };
};

export const sendContactsToCampaign = (data) => {
  return {
    type: SEND_CONTACTS_TO_CAMPAIGN,
    payload: data,
  };
};
export const sendContactsToCampaignSuccess = () => {
  return {
    type: SEND_CONTACTS_TO_CAMPAIGN_SUCCESS,
  };
};
export const sendContactsToCampaignError = () => {
  return {
    type: SEND_CONTACTS_TO_CAMPAIGN_ERROR,
  };
};
