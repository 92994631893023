import {
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_ERROR,
  GET_AUTOMATIONS,
  GET_AUTOMATIONS_SUCCESS,
  GET_AUTOMATIONS_ERROR,
  GET_AUTOMATIONS_HISTORY,
  GET_AUTOMATIONS_HISTORY_SUCCESS,
  GET_AUTOMATIONS_HISTORY_ERROR,
  DISABLE_AUTOMATION,
  DISABLE_AUTOMATION_SUCCESS,
  DISABLE_AUTOMATION_ERROR,
  DELETE_AUTOMATION,
  DELETE_AUTOMATION_SUCCESS,
  DELETE_AUTOMATION_ERROR,
  EDIT_AUTOMATION,
  EDIT_AUTOMATION_DATA,
  CLEAR_AUTOMATION_DATA,
  EDIT_AUTOMATION_SUCCESS,
  EDIT_AUTOMATION_ERROR,
  GET_EMAILCAMPAIGN,
  GET_EMAILCAMPAIGN_SUCCESS,
  GET_EMAILCAMPAIGN_ERROR,
  SEND_CONTACTS_TO_CAMPAIGN,
  SEND_CONTACTS_TO_CAMPAIGN_SUCCESS,
  SEND_CONTACTS_TO_CAMPAIGN_ERROR,
  RE_TRIGGER_AUTOMATION,
  RE_TRIGGER_AUTOMATION_SUCCESS,
  RE_TRIGGER_AUTOMATION_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  createAutomationLoading: false,
  createAutomationError: false,
  createAutomationSuccess: false,
  getEmailCampaignLoading: false,
  getEmailCampaignError: false,
  getEmailCampaignSuccess: false,
  getAutomationsLoading: false,
  getAutomationsError: false,
  disableAutomationLoading: false,
  disableAutomationError: false,
  deleteAutomationLoading: false,
  deleteAutomationError: false,
  editAutomationLoading: false,
  editAutomationError: false,
  editAutomationSuccess: false,
  automationData: [],
  emailCampaignData: [],
  editAutomationItem: {},
  sendContactsToCampaignLoading: false,
  sendContactsToCampaignError: false,
  sendContactsToCampaignSuccess: false,
  reTrigAutomationSuccess: false,
  reTrigAutomationLoading: false,
  reTrigAutomationError: false,
  getAutomationsHistoryLoading: false,
  getAutomationsHistoryError: false,
  automationsHistory: [],
};

const Hubspot = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_AUTOMATION: {
      return {
        ...state,
        createAutomationLoading: true,
      };
    }
    case CREATE_AUTOMATION_SUCCESS: {
      return {
        ...state,
        createAutomationLoading: false,
        createAutomationSuccess: true,
      };
    }
    case CREATE_AUTOMATION_ERROR: {
      return {
        ...state,
        createAutomationLoading: false,
        createAutomationSuccess: false,
        createAutomationError: true,
      };
    }

    case RE_TRIGGER_AUTOMATION: {
      return {
        ...state,
        retrigAutomationLoading: true,
      };
    }
    case RE_TRIGGER_AUTOMATION_SUCCESS: {
      return {
        ...state,
        retrigAutomationLoading: false,
        reTrigAutomationSuccess: true,
      };
    }
    case RE_TRIGGER_AUTOMATION_ERROR: {
      return {
        ...state,
        retrigAutomationLoading: false,
        reTrigAutomationSuccess: false,
        reTrigAutomationError: true,
      };
    }
    case GET_AUTOMATIONS: {
      return {
        ...state,
        getAutomationsLoading: true,
      };
    }
    case GET_AUTOMATIONS_SUCCESS: {
      return {
        ...state,
        getAutomationsLoading: false,
        automationData: action.payload,
      };
    }
    case GET_AUTOMATIONS_ERROR: {
      return {
        ...state,
        getAutomationsLoading: false,
        getAutomationsError: true,
      };
    }
    case GET_AUTOMATIONS_HISTORY: {
      return {
        ...state,
        getAutomationsHistoryLoading: true,
      };
    }
    case GET_AUTOMATIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        getAutomationsHistoryLoading: false,
        automationsHistory: action.payload?.automationLogs,
      };
    }
    case GET_AUTOMATIONS_HISTORY_ERROR: {
      return {
        ...state,
        getAutomationsHistoryLoading: false,
        getAutomationsHistoryError: true,
      };
    }

    case DISABLE_AUTOMATION: {
      return {
        ...state,
        disableAutomationLoading: true,
        disableAutomationError: false,
      };
    }
    case DISABLE_AUTOMATION_SUCCESS: {
      return {
        ...state,
        disableAutomationLoading: false,
        disableAutomationError: false,
      };
    }
    case DISABLE_AUTOMATION_ERROR: {
      return {
        ...state,
        disableAutomationLoading: false,
        disableAutomationError: true,
      };
    }

    case DELETE_AUTOMATION: {
      return {
        ...state,
        deleteAutomationLoading: true,
        deleteAutomationError: false,
      };
    }
    case DELETE_AUTOMATION_SUCCESS: {
      return {
        ...state,
        deleteAutomationLoading: false,
        deleteAutomationError: false,
      };
    }
    case DELETE_AUTOMATION_ERROR: {
      return {
        ...state,
        deleteAutomationLoading: false,
        deleteAutomationError: true,
      };
    }

    case EDIT_AUTOMATION: {
      return {
        ...state,
        editAutomationLoading: true,
        editAutomationError: false,
        editAutomationSuccess: false,
      };
    }
    case EDIT_AUTOMATION_DATA: {
      return {
        ...state,
        editAutomationItem: action.payload,
        editAutomationSuccess: false,
        createAutomationSuccess: false,
      };
    }
    case CLEAR_AUTOMATION_DATA: {
      return {
        ...state,
        editAutomationItem: {},
        editAutomationSuccess: false,
        createAutomationSuccess: false,
      };
    }
    case EDIT_AUTOMATION_SUCCESS: {
      return {
        ...state,
        editAutomationLoading: false,
        editAutomationError: false,
        editAutomationSuccess: true,
      };
    }
    case EDIT_AUTOMATION_ERROR: {
      return {
        ...state,
        editAutomationLoading: false,
        editAutomationError: true,
        editAutomationSuccess: false,
      };
    }

    case GET_EMAILCAMPAIGN: {
      return {
        ...state,
        getEmailCampaignLoading: true,
      };
    }
    case GET_EMAILCAMPAIGN_SUCCESS: {
      return {
        ...state,
        emailCampaignData: action.payload,
        getEmailCampaignLoading: false,
        getEmailCampaignSuccess: true,
      };
    }
    case GET_EMAILCAMPAIGN_ERROR: {
      return {
        ...state,
        getEmailCampaignLoading: false,
        getEmailCampaignSuccess: false,
        getEmailCampaignError: true,
      };
    }

    case SEND_CONTACTS_TO_CAMPAIGN: {
      return {
        ...state,
        sendContactsToCampaignLoading: true,
      };
    }
    case SEND_CONTACTS_TO_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        sendContactsToCampaignLoading: false,
        sendContactsToCampaignSuccess: true,
      };
    }
    case SEND_CONTACTS_TO_CAMPAIGN_ERROR: {
      return {
        ...state,
        sendContactsToCampaignLoading: false,
        sendContactsToCampaignSuccess: false,
        sendContactsToCampaignError: true,
      };
    }

    default:
      return state;
  }
};

export default Hubspot;
