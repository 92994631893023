import {
  ADD_FILTER_HAPPIER_LEADS,
  FETCH_ALL_FILTERS_HAPPIERLEADS,
  CALL_FETCH_ALL_FILTERS_HAPPIERLEADS,
  GET_LEADS_DATA,
  GET_START_AND_END_DATE,
  LOADING_LEADS_TABLE,
  ERROR,
  CLEAR_ERROR,
  DELETE_SAVED_FITER,
  CREATE_SEARCHABLE_LEADS_DATA,
  UPDATE_DISPLAY_LEADS_DATA,
  GET_FILTERED_LEADS_DATA,
  GET_INFO_OF_CURRENT_ROW_ON_LEADS_TABLE,
  GET_ORIGIN_HAPPIERLEARS,
  REMOVE_FILTER_HAPPIERLEAD_LEADS,
  UPDATE_FILTER_HAPPIERLEAD_LEADS,
  CLEAR_ALL_STATES_LEADS,
  SET_NOTFOUND,
  CANCEL_NOTFOUND,
  ADD_LEADS_TAG,
  DELETE_LEADS_TAG,
  MODIFY_LEADS_TAG,
  SUCCESS_DELETE_TAG,
  SUCCESS_MODIFY_TAG,
  GET_TAGS_LEAD,
  SUCCESS_GET_TAGS_LEADS,
  RESET_LEADS_TAGS,
  CONTROL_RELOAD_SIDEBAR_LEADS,
  CONTROL_RESET_SIDEBAR_LEADS,
  UPDATE_EXISTING_TAGS,
  UPDATE_CURRENT_LEADS_PAGE,
  UPDATE_COUNT_LEADS,
  REFRESH_LEADS_PAGE,
  CREATE_SELECTED_COLUMNS_LEADS,
  UNLOCK_LEADS,
  SUCCESS_UNLOCK_LEADS,
  CREATE_SELECTED_COLUMNS_LEADS_SUCCESS,
  CREATE_SELECTED_COLUMNS_LEADS_ERROR,
  SET_SELECTED_ROW_IDS,
  RESET_LEAD_SCORES,
  RESET_LEAD_SCORES_SUCCESS,
  RESET_LEAD_SCORES_ERROR,
  SET_ALL_PAGES_SELECTED,
} from '../../constants/ActionTypes';

export const addFilter = (data) => {
  return {
    type: ADD_FILTER_HAPPIER_LEADS,
    payload: data,
  };
};

export const fetchAllFilters = (data) => {
  return {
    type: FETCH_ALL_FILTERS_HAPPIERLEADS,
    payload: data,
  };
};

export const getFilters = (websiteId) => {
  return {
    type: CALL_FETCH_ALL_FILTERS_HAPPIERLEADS,
    payload: websiteId,
  };
};

export const getLeadsData = (data) => {
  return {
    type: GET_LEADS_DATA,
    payload: data,
  };
};

export const getDateRange = (dates) => {
  return {
    type: GET_START_AND_END_DATE,
    payload: dates,
  };
};

export const tableDataLoading = () => {
  return {
    type: LOADING_LEADS_TABLE,
  };
};

export const error = (message) => {
  return {
    type: ERROR,
    payload: message,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const deleteFilter = (id) => {
  return {
    type: DELETE_SAVED_FITER,
    payload: id,
  };
};

export const createSearchableData = (data) => {
  return {
    type: CREATE_SEARCHABLE_LEADS_DATA,
    payload: data,
  };
};

export const updateDisplayLeadsData = (data) => {
  return {
    type: UPDATE_DISPLAY_LEADS_DATA,
    payload: data,
  };
};

export const getFilteredLeadsData = (data) => {
  return {
    type: GET_FILTERED_LEADS_DATA,
    payload: data,
  };
};

export const resetLeadScores = (data) => {
  return {
    type: RESET_LEAD_SCORES,
    payload: data,
  };
};

export const resetLeadScoresSuccess = () => {
  return {
    type: RESET_LEAD_SCORES_SUCCESS,
  };
};
export const resetLeadScoresError = () => {
  return {
    type: RESET_LEAD_SCORES_ERROR,
  };
};
export const clearResetLeadScores = () => {
  return {
    type: RESET_LEAD_SCORES_ERROR,
  };
};

export const getLeadsTableRowInfo = (id) => {
  return {
    type: GET_INFO_OF_CURRENT_ROW_ON_LEADS_TABLE,
    payload: id,
  };
};

export const getUrlOrigin = (url) => {
  return {
    type: GET_ORIGIN_HAPPIERLEARS,
    payload: url,
  };
};

export const removeFilterLeads = (id) => {
  return {
    type: REMOVE_FILTER_HAPPIERLEAD_LEADS,
    payload: id,
  };
};

export const updateFilterLeads = (data) => {
  return {
    type: UPDATE_FILTER_HAPPIERLEAD_LEADS,
    payload: data,
  };
};

export const clearAllStatesLeads = () => {
  return {
    type: CLEAR_ALL_STATES_LEADS,
  };
};

export const setNothingFound = () => {
  return {
    type: SET_NOTFOUND,
  };
};

export const cancelNothingFound = () => {
  return {
    type: CANCEL_NOTFOUND,
  };
};

export const addLeadsTag = (data) => {
  return {
    type: ADD_LEADS_TAG,
    payload: data,
  };
};

export const modifyLeadsTag = (data) => {
  return {
    type: MODIFY_LEADS_TAG,
    payload: data,
  };
};

export const deleteLeadsTag = (data) => {
  return {
    type: DELETE_LEADS_TAG,
    payload: data,
  };
};

export const successModifyLeadsTag = (data) => {
  return {
    type: SUCCESS_MODIFY_TAG,
    payload: data,
  };
};

export const successDeleteLeadsTag = (data) => {
  return {
    type: SUCCESS_DELETE_TAG,
    payload: data,
  };
};

export const getLeadsTag = (websiteId) => {
  return {
    type: GET_TAGS_LEAD,
    payload: websiteId,
  };
};

export const successGetTagsLeads = (data) => {
  return {
    type: SUCCESS_GET_TAGS_LEADS,
    payload: data,
  };
};

export const resetLeadsTags = () => {
  return {
    type: RESET_LEADS_TAGS,
  };
};

export const controlReloadSideFilters = () => {
  return {
    type: CONTROL_RELOAD_SIDEBAR_LEADS,
  };
};

export const controlResetReloadFilters = () => {
  return {
    type: CONTROL_RESET_SIDEBAR_LEADS,
  };
};

export const updateExistingTags = (data) => {
  return {
    type: UPDATE_EXISTING_TAGS,
    payload: data,
  };
};

export const updateCurrentPage = (page) => {
  return {
    type: UPDATE_CURRENT_LEADS_PAGE,
    payload: page,
  };
};
export const setSelectedRowIds = (selectedRowIds) => {
  return {
    type: SET_SELECTED_ROW_IDS,
    payload: selectedRowIds,
  };
};
export const setAllPagesSelected = (value) => {
  return {
    type: SET_ALL_PAGES_SELECTED,
    payload: value,
  };
};

export const updateCountLeads = () => {
  return {
    type: UPDATE_COUNT_LEADS,
  };
};

export const refreshLeadsPage = (num) => {
  return {
    type: REFRESH_LEADS_PAGE,
    payload: num,
  };
};

export const addSelectedFieldsLeads = (data) => {
  return {
    type: CREATE_SELECTED_COLUMNS_LEADS,
    payload: data,
  };
};
export const addSelectedFieldsLeadsSuccess = (data) => {
  return {
    type: CREATE_SELECTED_COLUMNS_LEADS_SUCCESS,
    payload: data,
  };
};
export const addSelectedFieldsLeadsError = (data) => {
  return {
    type: CREATE_SELECTED_COLUMNS_LEADS_ERROR,
    payload: data,
  };
};

export const unlockLeads = (data) => {
  return {
    type: UNLOCK_LEADS,
    payload: data,
  };
};

export const successUlockLeads = (data) => {
  return {
    type: SUCCESS_UNLOCK_LEADS,
    payload: data,
  };
};
