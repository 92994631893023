import {
  GET_PROSPECTOR,
  GET_PROSPECTOR_SUCCESS,
  GET_PROSPECTOR_ERROR,
  UPDATE_PROSPECTOR_FILTERS,
  UPDATE_CURRENT_PROSPECTOR_PAGE,
  GET_FILTERED_PROSPECTOR_DATA,
  CREATE_SEARCHABLE_PROSPECTOR_DATA,
  SELECTED_PROSPECTOR,
  SWITCH_PROSPECTOR_TABS,
  GET_PEOPLE_PROSPECTOR,
  GET_PEOPLE_PROSPECTOR_SUCCESS,
  GET_PEOPLE_PROSPECTOR_ERROR,
  UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE,
  SELECTED_LIST_PROSPECTOR,
  GET_PROSPECTOR_COMPANY_INSIGHTS,
  GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS,
  GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR,
  GET_PROSPECTOR_STATUS,
  GET_PROSPECTOR_STATUS_SUCCESS,
  GET_PROSPECTOR_STATUS_ERROR,
  GET_PROSPECTOR_FILTERS,
  GET_PROSPECTOR_FILTERS_SUCCESS,
  GET_PROSPECTOR_FILTERS_ERROR,
  CREATE_PROSPECTOR_FILTER,
  CREATE_PROSPECTOR_FILTER_SUCCESS,
  CREATE_PROSPECTOR_FILTER_ERROR,
  DELETE_PROSPECTOR_FILTER_ERROR,
  DELETE_PROSPECTOR_FILTER_SUCCESS,
  DELETE_PROSPECTOR_FILTER,
  CLEAR_PROSPECTOR_ERROR,
} from '../../constants/ActionTypes';

export const switchProspectorTabs = (tab) => {
  return {
    type: SWITCH_PROSPECTOR_TABS,
    payload: tab,
  };
};

export const getProspector = (query) => {
  return {
    type: GET_PROSPECTOR,
    payload: query,
  };
};

export const getProspectorSuccess = (data) => {
  return {
    type: GET_PROSPECTOR_SUCCESS,
    payload: data,
  };
};

export const getProspectorError = () => {
  return {
    type: GET_PROSPECTOR_ERROR,
  };
};
export const getProspectorFilters = (query) => {
  return {
    type: GET_PROSPECTOR_FILTERS,
    payload: query,
  };
};

export const getProspectorFiltersSuccess = (data) => {
  return {
    type: GET_PROSPECTOR_FILTERS_SUCCESS,
    payload: data,
  };
};

export const getProspectorFiltersError = () => {
  return {
    type: GET_PROSPECTOR_FILTERS_ERROR,
  };
};

export const addProspectorFilter = (data) => {
  return {
    type: CREATE_PROSPECTOR_FILTER,
    payload: data,
  };
};

export const addProspectorFilterSuccess = (message) => {
  return {
    type: CREATE_PROSPECTOR_FILTER_SUCCESS,
    payload: message,
  };
};

export const addProspectorFilterError = (message) => {
  return {
    type: CREATE_PROSPECTOR_FILTER_ERROR,
    payload: message,
  };
};
export const removeProspectorFilter = (data) => {
  return {
    type: DELETE_PROSPECTOR_FILTER,
    payload: data,
  };
};

export const removeProspectorFilterSuccess = (message) => {
  return {
    type: DELETE_PROSPECTOR_FILTER_SUCCESS,
    payload: message,
  };
};

export const removeProspectorFilterError = (message) => {
  return {
    type: DELETE_PROSPECTOR_FILTER_ERROR,
    payload: message,
  };
};

export const getPeopleProspector = (query) => {
  return {
    type: GET_PEOPLE_PROSPECTOR,
    payload: query,
  };
};

export const getPeopleProspectorSuccess = (data) => {
  return {
    type: GET_PEOPLE_PROSPECTOR_SUCCESS,
    payload: data,
  };
};

export const getPeopleProspectorError = () => {
  return {
    type: GET_PEOPLE_PROSPECTOR_ERROR,
  };
};

export const setSelectedProspector = (selected) => {
  return {
    type: SELECTED_PROSPECTOR,
    payload: selected,
  };
};

export const setSelectedListProspector = (selected) => {
  return {
    type: SELECTED_LIST_PROSPECTOR,
    payload: selected,
  };
};

export const updateProspectorFilters = (query) => {
  return {
    type: UPDATE_PROSPECTOR_FILTERS,
    payload: query,
  };
};

export const updateCurrentPage = (query) => {
  return {
    type: UPDATE_CURRENT_PROSPECTOR_PAGE,
    payload: query,
  };
};

export const updateCurrentPeoplePage = (query) => {
  return {
    type: UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE,
    payload: query,
  };
};

export const getFilteredProspectorData = (data) => {
  return {
    type: GET_FILTERED_PROSPECTOR_DATA,
    payload: data,
  };
};

export const createSearchProspectorData = (data) => {
  return {
    type: CREATE_SEARCHABLE_PROSPECTOR_DATA,
    payload: data,
  };
};

export const getProspectorCompanyInsights = (query) => {
  return {
    type: GET_PROSPECTOR_COMPANY_INSIGHTS,
    payload: query,
  };
};

export const getProspectorCompanyInsightsSuccess = (data) => {
  return {
    type: GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS,
    payload: data,
  };
};
export const getProspectorStatus = (data) => {
  return {
    type: GET_PROSPECTOR_STATUS,
    payload: data,
  };
};

export const getProspectorCompanyInsightsError = () => {
  return {
    type: GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR,
  };
};
export const getProspectorStatusSuccess = (data) => {
  return {
    type: GET_PROSPECTOR_STATUS_SUCCESS,
    payload: data,
  };
};

export const getProspectorStatusError = () => {
  return {
    type: GET_PROSPECTOR_STATUS_ERROR,
  };
};
export const clearCompanyProspectorError = () => {
  return {
    type: CLEAR_PROSPECTOR_ERROR,
  };
};
