import {
  POST_RATING_REQUEST,
  POST_RATING_SUCCESS,
  POST_RATING_ERROR,
} from '../../constants/ActionTypes'

export const addRating = (data) => {
  return {
    type: POST_RATING_REQUEST,
    payload: data,
  }
}

export const addRatingSuccess = (message) => {
  return {
    type: POST_RATING_SUCCESS,
    payload: message,
  }
}

export const addRatingError = (message) => {
  return {
    type: POST_RATING_ERROR,
    payload: message,
  }
}
