import {
  POST_RATING_REQUEST,
  POST_RATING_SUCCESS,
  POST_RATING_ERROR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  success: false,
  message: '',
  error: false,
  ratingLoading: false,
}

const Rating = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_RATING_REQUEST: {
      return {
        ...state,
        ratingLoading: true,
      }
    }

    case POST_RATING_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        ratingLoading: false,
      }
    }

    case POST_RATING_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        ratingLoading: false,
      }
    }

    default:
      return state
  }
}

export default Rating
