import {
  GET_ZOHO_OWNERS,
  GET_ZOHO_OWNERS_SUCCESS,
  GET_ZOHO_OWNERS_ERROR,
  SAVE_NEW_ZOHO_COMPANY,
  SAVE_NEW_ZOHO_COMPANY_SUCCESS,
  SAVE_NEW_ZOHO_COMPANY_ERROR,
  SEARCH_ZOHO_COMPANY,
  SEARCH_ZOHO_COMPANY_SUCCESS,
  SEARCH_ZOHO_COMPANY_ERROR,
  CONNECT_EXISTING_ZOHO_COMPANY,
  CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS,
  CONNECT_EXISTING_ZOHO_COMPANY_ERROR,
  CONNECT_ZOHO_CONTACTS,
  CONNECT_ZOHO_CONTACTS_SUCCESS,
  CONNECT_ZOHO_CONTACTS_ERROR,
  CLEAR_ZOHO_CONTACTS_ERROR,
} from '../../constants/ActionTypes'

export const getZohoOwners = (id) => {
  return {
    type: GET_ZOHO_OWNERS,
    payload: id,
  }
}

export const getZohoOwnersSuccess = (data) => {
  return {
    type: GET_ZOHO_OWNERS_SUCCESS,
    payload: data,
  }
}

export const getZohoOwnersError = () => {
  return {
    type: GET_ZOHO_OWNERS_ERROR,
  }
}

export const saveNewZohoCompany = (data) => {
  return {
    type: SAVE_NEW_ZOHO_COMPANY,
    payload: data,
  }
}

export const saveNewZohoCompanySuccess = (data) => {
  return {
    type: SAVE_NEW_ZOHO_COMPANY_SUCCESS,
    payload: data,
  }
}

export const saveNewZohoCompanyError = () => {
  return {
    type: SAVE_NEW_ZOHO_COMPANY_ERROR,
  }
}

export const searchZohoCompany = (data) => {
  return {
    type: SEARCH_ZOHO_COMPANY,
    payload: data,
  }
}

export const searchZohoCompanySuccess = (data) => {
  return {
    type: SEARCH_ZOHO_COMPANY_SUCCESS,
    payload: data,
  }
}

export const searchZohoCompanyError = () => {
  return {
    type: SEARCH_ZOHO_COMPANY_ERROR,
  }
}

export const connectExistingZohoCompany = (data) => {
  return {
    type: CONNECT_EXISTING_ZOHO_COMPANY,
    payload: data,
  }
}

export const connectExistingZohoCompanySuccess = (data) => {
  return {
    type: CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS,
    payload: data,
  }
}

export const connectExistingZohoCompanyError = () => {
  return {
    type: CONNECT_EXISTING_ZOHO_COMPANY_ERROR,
  }
}

export const connectZohoContacts = (data) => {
  return {
    type: CONNECT_ZOHO_CONTACTS,
    payload: data,
  }
}

export const connectZohoContactsSuccess = (data) => {
  return {
    type: CONNECT_ZOHO_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const connectZohoContactsError = () => {
  return {
    type: CONNECT_ZOHO_CONTACTS_ERROR,
  }
}
export const clearZohoContactsError = () => {
  return {
    type: CLEAR_ZOHO_CONTACTS_ERROR,
  }
}
