import { GET_COUNTRY, GET_COUNTRY_SUCCESS } from '../../constants/ActionTypes'

const INIT_STATE = {
  geoLocation: null,
}

const Utilities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRY: {
      return {
        ...state,
      }
    }

    case GET_COUNTRY_SUCCESS: {
      return {
        ...state,
        geoLocation: action.payload,
      }
    }

    default:
      return state
  }
}

export default Utilities
