import {
  DOWNLOAD_CSV,
  ERROR_DOWNLOAD_CSV,
  SUCCESS_DOWNLOAD_CSV,
  CLEAR_EXPORT_CSV_ERROR,
  SET_CSV_GET_PARAMS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  error: false,
  leads: [],
  success: false,
  csvParams: '',
  response: '',
}

const CSVfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_CSV: {
      return {
        ...state,
        leads: action.payload.leads,
        loading: true,
      }
    }
    case SUCCESS_DOWNLOAD_CSV: {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        response: action.payload,
      }
    }
    case ERROR_DOWNLOAD_CSV: {
      return {
        ...state,
        loading: false,
      }
    }

    case CLEAR_EXPORT_CSV_ERROR: {
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
      }
    }

    case SET_CSV_GET_PARAMS: {
      return {
        ...state,
        csvParams: action.payload,
      }
    }

    default:
      return state
  }
}

export default CSVfile
