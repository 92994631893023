import {
  FORWARD_CSV,
  FORWARD_CSV_SUCCESS,
  FORWARD_CSV_ERROR,
  GET_CSV_BLACKLIST,
  GET_CSV_BLACKLIST_SUCCESS,
  GET_CSV_BLACKLIST_ERROR,
  DELETE_CSV_BLACKLIST,
  DELETE_CSV_BLACKLIST_SUCCESS,
  DELETE_CSV_BLACKLIST_ERROR,
  CLEAR_FORWARD_CSV,
  GET_EMAIL_CSV_BLACKLIST,
  GET_EMAIL_CSV_BLACKLIST_ERROR,
  GET_EMAIL_CSV_BLACKLIST_SUCCESS,
  DELETE_EMAIL_CSV_BLACKLIST,
  DELETE_EMAIL_CSV_BLACKLIST_ERROR,
  DELETE_EMAIL_CSV_BLACKLIST_SUCCESS,
  FORWARD_CSV_EMAIL,
  FORWARD_CSV_EMAIL_ERROR,
  FORWARD_CSV_EMAIL_SUCCESS,
  CLEAR_FORWARD_CSV_EMAIL,
} from '../../constants/ActionTypes'

export const forwardCSV = (data) => {
  return {
    type: FORWARD_CSV,
    payload: data,
  }
}

export const forwardCSVError = () => {
  return {
    type: FORWARD_CSV_ERROR,
  }
}

export const forwardCSVSuccess = () => {
  return {
    type: FORWARD_CSV_SUCCESS,
  }
}
export const clearForwardCsv = () => {
  return {
    type: CLEAR_FORWARD_CSV,
  }
}
export const forwardCSVEmail = (data) => {
  return {
    type: FORWARD_CSV_EMAIL,
    payload: data,
  }
}

export const forwardCSVEmailError = () => {
  return {
    type: FORWARD_CSV_EMAIL_ERROR,
  }
}

export const forwardCSVEmailSuccess = () => {
  return {
    type: FORWARD_CSV_EMAIL_SUCCESS,
  }
}
export const clearForwardCsvEmail = () => {
  return {
    type: CLEAR_FORWARD_CSV_EMAIL,
  }
}

export const getCSVBlacklist = (id) => {
  return {
    type: GET_CSV_BLACKLIST,
    payload: id,
  }
}

export const getCSVBlacklistError = (error) => {
  return {
    type: GET_CSV_BLACKLIST_ERROR,
    payload: error,
  }
}

export const getCSVBlacklistSuccess = (data) => {
  return {
    type: GET_CSV_BLACKLIST_SUCCESS,
    payload: data,
  }
}
export const getEmailCSVBlacklist = (id) => {
  return {
    type: GET_EMAIL_CSV_BLACKLIST,
    payload: id,
  }
}

export const getEmailCSVBlacklistError = (error) => {
  return {
    type: GET_EMAIL_CSV_BLACKLIST_ERROR,
    payload: error,
  }
}

export const getEmailCSVBlacklistSuccess = (data) => {
  return {
    type: GET_EMAIL_CSV_BLACKLIST_SUCCESS,
    payload: data,
  }
}

export const deleteCSVBlacklist = (data) => {
  return {
    type: DELETE_CSV_BLACKLIST,
    payload: data,
  }
}

export const deleteCSVBlacklistError = () => {
  return {
    type: DELETE_CSV_BLACKLIST_ERROR,
  }
}

export const deleteCSVBlacklistSuccess = () => {
  return {
    type: DELETE_CSV_BLACKLIST_SUCCESS,
  }
}
export const deleteEmailCSVBlacklist = (data) => {
  return {
    type: DELETE_EMAIL_CSV_BLACKLIST,
    payload: data,
  }
}

export const deleteEmailCSVBlacklistError = () => {
  return {
    type: DELETE_EMAIL_CSV_BLACKLIST_ERROR,
  }
}

export const deleteEmailCSVBlacklistSuccess = () => {
  return {
    type: DELETE_EMAIL_CSV_BLACKLIST_SUCCESS,
  }
}
