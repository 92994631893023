import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import {
  CREATE_PROSPECTOR_FILTER,
  DELETE_PROSPECTOR_FILTER,
  GET_PEOPLE_PROSPECTOR,
  GET_PROSPECTOR,
  GET_PROSPECTOR_COMPANY_INSIGHTS,
  GET_PROSPECTOR_FILTERS,
  GET_PROSPECTOR_STATUS,
} from '../../constants/ActionTypes';

import {
  getProspectorSuccess,
  getProspectorError,
  getPeopleProspectorError,
  getPeopleProspectorSuccess,
  getProspectorCompanyInsightsSuccess,
  getProspectorCompanyInsightsError,
  getProspectorStatusSuccess,
  getProspectorStatusError,
  addProspectorFilterError,
  addProspectorFilterSuccess,
  removeProspectorFilterError,
  removeProspectorFilterSuccess,
  getProspectorFiltersError,
  getProspectorFiltersSuccess,
  getProspectorFilters,
} from '../actions/Prospector';

// import { downloadProspectorCSV } from '../actions/ProspectorCSV'

import {
  // generalSuccessMessage,
  generalErrorMessage,
  generalSuccessMessage,
} from '../actions/GeneralMessage';

import { getUserDetails, userSignOut } from '../actions/Auth';
import { setProspectorCSVParams } from '../actions/ProspectorCSV';

const fetchProspectorDataRequest = async (query, websiteId) =>
  await axios
    .post(`${baseUrl}/admin/prospector/${websiteId}`, query)
    .then((response) => response)
    .catch((error) => error);
const fetchPeopleProspectorDataRequest = async (query, websiteId) =>
  await axios
    .post(`${baseUrl}/admin/prospect-people/${websiteId}`, query)
    .then((response) => response)
    .catch((error) => error);
const fetchProspectorCompanyInsightsRequest = async (companyProfileId) =>
  await axios.get(`${baseUrl}/admin/companies/insights/${companyProfileId}`);
const fetchProspectorStatusRequest = async (websiteId, prospectors) =>
  await axios
    .post(`${baseUrl}/admin/prospector-status`, { websiteId, prospectors })
    .then((response) => response)
    .catch((error) => error);

const fetchProspectorFiltersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/prospect-segments?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);
const addProspectorFilter = async (websiteId, name, url) =>
  await axios
    .post(`${baseUrl}/admin/prospect-segments`, { websiteId, name, url })
    .then((response) => response)
    .catch((error) => error);
const deleteProspectorFilter = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/prospect-segments?segmentIds=${id}`)
    .then((response) => response)
    .catch((error) => error);

function* fetchProspectorData({ payload }) {
  const { query, websiteId } = payload;

  yield put(setProspectorCSVParams({ websiteId, query }));
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectorDataRequest, query, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectorError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectorError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospector data gotten successfully"));
      yield put(getProspectorSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectorError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchPeopleProspectorData({ payload }) {
  const { query, websiteId } = payload;

  yield put(setProspectorCSVParams({ websiteId, query }));
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchPeopleProspectorDataRequest, query, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getPeopleProspectorError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getPeopleProspectorError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospector data gotten successfully"));
      yield put(getPeopleProspectorSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getPeopleProspectorError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchProspectorCompanyInsightsData({ payload }) {
  const { companyProfileId } = payload;

  yield put(setProspectorCSVParams({ companyProfileId }));
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectorCompanyInsightsRequest, companyProfileId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getPeopleProspectorError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectorCompanyInsightsError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospector data gotten successfully"));
      yield put(getProspectorCompanyInsightsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectorCompanyInsightsError());
    yield put(generalErrorMessage(error));
  }
}

function* fetchProspectorStatus({ payload }) {
  const { websiteId, prospectors } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectorStatusRequest, websiteId, prospectors);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getProspectorStatusError(callResponse.response.data.status));
      } else {
        console.log('timeout');
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(getProspectorStatusSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectorStatusError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchProspectorFilters({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectorFiltersRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectorFiltersError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectorFiltersError());
      }
    } else {
      yield put(getProspectorFiltersSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectorFiltersError());
    yield put(generalErrorMessage(error));
  }
}
function* addFilter({ payload }) {
  const { websiteId, url, name } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(addProspectorFilter, websiteId, name, url);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(addProspectorFilterError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(addProspectorFilterError());
      }
    } else {
      yield put(generalSuccessMessage('Filter added successfully'));
      yield put(addProspectorFilterSuccess(callResponse.data));
      yield put(getProspectorFilters({ websiteId }));
    }
  } catch (error) {
    yield put(addProspectorFilterError());
    yield put(generalErrorMessage(error));
  }
}
function* removeProspectorFilter({ payload }) {
  const { id, websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteProspectorFilter, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(removeProspectorFilterError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(removeProspectorFilterError());
      }
    } else {
      yield put(generalSuccessMessage('Filter removed successfully'));
      yield put(removeProspectorFilterSuccess(callResponse.data));
      yield put(getProspectorFilters({ websiteId }));
    }
  } catch (error) {
    yield put(removeProspectorFilterError());
    yield put(generalErrorMessage(error));
  }
}

export function* getRequestfetchProspectorData() {
  yield takeEvery(GET_PROSPECTOR, fetchProspectorData);
}
export function* getProspectorFiltersData() {
  yield takeEvery(GET_PROSPECTOR_FILTERS, fetchProspectorFilters);
}
export function* createProspectorFilters() {
  yield takeEvery(CREATE_PROSPECTOR_FILTER, addFilter);
}
export function* removeProspectorFilterSaga() {
  yield takeEvery(DELETE_PROSPECTOR_FILTER, removeProspectorFilter);
}
export function* getRequestfetchPeopleProspectorData() {
  yield takeEvery(GET_PEOPLE_PROSPECTOR, fetchPeopleProspectorData);
}
export function* getRequestfetchProspectorCompanyInsightsData() {
  yield takeEvery(GET_PROSPECTOR_COMPANY_INSIGHTS, fetchProspectorCompanyInsightsData);
}

export function* getRequestProspectorStatus() {
  yield takeEvery(GET_PROSPECTOR_STATUS, fetchProspectorStatus);
}

export default function* rootSaga() {
  yield all([
    // fork(putRequestGetProspectorFilters),
    fork(getRequestfetchProspectorData),
    fork(getRequestfetchPeopleProspectorData),
    fork(getRequestfetchProspectorCompanyInsightsData),
    fork(getRequestProspectorStatus),
    fork(getProspectorFiltersData),
    fork(createProspectorFilters),
    fork(removeProspectorFilterSaga),
  ]);
}
