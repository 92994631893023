import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { userSignOut } from '../actions/Auth';

import {
  GET_HUBSPOT_OWNERS,
  SAVE_NEW_COMPANY,
  SEARCH_COMPANY,
  CONNECT_EXISTING_COMPANY,
  CONNECT_CONTACTS,
} from '../../constants/ActionTypes';

import {
  getHubSpotOwnersError,
  getHubSpotOwnersSuccess,
  saveNewCompanyError,
  saveNewCompanySuccess,
  searchCompanyError,
  searchCompanySuccess,
  connectExistingCompanyError,
  connectExistingCompanySuccess,
  connectContactsError,
  connectContactsSuccess,
} from '../actions/Hubspot';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { updateCountLeads, updateCurrentPage } from '../actions/Leads';

const getHubspotOwnersRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/integrations/hubspot/owner?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const searchCompanyRequest = async (websiteId, searchTerm) =>
  await axios
    .get(`${baseUrl}/admin/integrations/hubspot/company?websiteId=${websiteId}&searchTerm=${searchTerm}`)
    .then((response) => response)
    .catch((error) => error);

const saveNewCompanyRequest = async (websiteId, domain, ownerId, leadId, name) =>
  await axios
    .post(`${baseUrl}/admin/integrations/hubspot/company`, {
      websiteId,
      domain,
      ownerId,
      leadId,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const connectContactsRequest = async (websiteId, ownerId, email) =>
  await axios
    .post(`${baseUrl}/admin/integrations/hubspot/contact`, {
      websiteId,
      ownerId,
      email,
    })
    .then((response) => response)
    .catch((error) => error);

const connectExistingCompanyRequest = async (websiteId, hubspotCompanyId, leadId) =>
  await axios
    .post(`${baseUrl}/admin/integrations/hubspot/connect`, {
      websiteId,
      hubspotCompanyId,
      leadId,
    })
    .then((response) => response)
    .catch((error) => error);

function* getOwners({ payload }) {
  const { websiteId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(getHubspotOwnersRequest, websiteId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(getHubSpotOwnersError());
        // yield put(generalErrorMessage(callResponse.response.data.status))
      } else {
        yield put(getHubSpotOwnersError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getHubSpotOwnersSuccess(callResponse.data));
      // yield put(generalSuccessMessage(callResponse.data));
    }
  } catch (error) {
    yield put(getHubSpotOwnersError(error));
  }
}

function* searchCompany({ payload }) {
  const { websiteId, searchTerm } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(searchCompanyRequest, websiteId, searchTerm);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(searchCompanyError());
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        yield put(searchCompanyError());
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(searchCompanySuccess(callResponse.data));
    }
  } catch (error) {
    yield put(searchCompanyError(error));
  }
}

function* saveNewCompany({ payload }) {
  const { websiteId, domain, ownerId, leadId, name } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(saveNewCompanyRequest, websiteId, domain, ownerId, leadId, name);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(saveNewCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(saveNewCompanyError());
      }
    } else {
      yield put(saveNewCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company save Failed...'));
    yield put(saveNewCompanyError());
  }
}

function* connectContacts({ payload }) {
  const { websiteId, ownerId, email } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectContactsRequest, websiteId, ownerId, email);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectContactsError());
      }
    } else {
      yield put(connectContactsSuccess());
      yield put(generalSuccessMessage('Contact saved successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(connectContactsError());
  }
}

function* connectExistingCompany({ payload }) {
  const { websiteId, hubspotCompanyId, leadId } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(connectExistingCompanyRequest, websiteId, hubspotCompanyId, leadId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(connectExistingCompanyError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectExistingCompanyError());
      }
    } else {
      yield put(connectExistingCompanySuccess());
      yield put(updateCurrentPage(1));
      yield put(updateCountLeads());
      yield put(generalSuccessMessage('Company connected successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Company connection Failed...'));
    yield put(connectExistingCompanyError());
  }
}

export function* fetchHubspotOwners() {
  yield takeEvery(GET_HUBSPOT_OWNERS, getOwners);
}

export function* postSaveNewCompany() {
  yield takeEvery(SAVE_NEW_COMPANY, saveNewCompany);
}

export function* postConnectContacts() {
  yield takeEvery(CONNECT_CONTACTS, connectContacts);
}

export function* postSearchCompany() {
  yield takeEvery(SEARCH_COMPANY, searchCompany);
}

export function* postConnectExistingCompany() {
  yield takeEvery(CONNECT_EXISTING_COMPANY, connectExistingCompany);
}

export default function* rootSaga() {
  yield all([
    fork(fetchHubspotOwners),
    fork(postSaveNewCompany),
    fork(postConnectContacts),
    fork(postSearchCompany),
    fork(postConnectExistingCompany),
  ]);
}
