import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'
import { userSignOut } from '../actions/Auth'
import { DOWNLOAD_PROSPECTOR_CSV } from '../../constants/ActionTypes'

import {
  downloadProspectorCSVError,
  downloadProspectorCSVSuccess,
} from '../actions/ProspectorCSV'

const downloadProspectorCSVRequest = async (query, websiteId) =>
  await axios
    .post(`${baseUrl}/admin/prospector-csv?websiteId=${websiteId}`, query)
    .then((response) => response)
    .catch((error) => error)

function* downloadProspectorCSVfile({ payload }) {
  setAuthorizationHeader()
  const { query, websiteId } = payload
  try {
    const csvResponse = yield call(
      downloadProspectorCSVRequest,
      query,
      websiteId
    )

    if (csvResponse.message) {
      if (csvResponse.response) {
        const tokenStatus = isTokenExpired(csvResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
      }

      yield put(downloadProspectorCSVError())
    } else {
      yield put(downloadProspectorCSVSuccess())
    }
  } catch (error) {
    yield put(downloadProspectorCSVError())
  }
}
export function* csvDownloadHook() {
  yield takeEvery(DOWNLOAD_PROSPECTOR_CSV, downloadProspectorCSVfile)
}

export default function* rootSaga() {
  yield all([fork(csvDownloadHook)])
}
