import {
  GET_MEMBERS,
  UPDATE_MEMBER_ROLE,
  GET_MEMBER_SUCCESS,
  MEMBERS_ERROR,
  MEMBERS_CLEAR_MESSAGES,
  ACCESS_MEMBERS_PAGE,
  REJECT_MEMBERS_PAGE,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_WEBSITES_MEMBERS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loader: true,
  allmembers: [],
  updateSuccess: false,
  deleteSuccess: false,
  accountId: '',
  userId: '',
  message: '',
  error: false,
  memberId: '',
  allowMemberPage: false,
}

const Members = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_MEMBER_ROLE: {
      return {
        ...state,
        memberRole: action.payload.memberRole,
        userId: action.payload.userId,
      }
    }

    case GET_MEMBER_SUCCESS: {
      return {
        ...state,
        loader: false,
        allmembers: action.payload,
      }
    }
    case MEMBERS_ERROR: {
      return {
        ...state,
        error: true,
        message: action.payload,
        loader: false,
      }
    }

    case GET_MEMBERS: {
      return {
        ...state,
        loader: true,
        accountId: action.payload,
      }
    }

    case MEMBERS_CLEAR_MESSAGES: {
      return {
        ...state,
        loader: false,
        error: false,
        message: '',
        updateSuccess: false,
        deleteSuccess: false,
      }
    }

    case ACCESS_MEMBERS_PAGE: {
      return {
        ...state,
        allowMemberPage: true,
        accountId: action.payload,
      }
    }

    case REJECT_MEMBERS_PAGE: {
      return {
        ...state,
        allowMemberPage: false,
      }
    }

    case DELETE_MEMBER: {
      return {
        ...state,
        memberId: action.payload,
      }
    }

    case UPDATE_MEMBER_SUCCESS: {
      return {
        ...state,
        updateSuccess: true,
      }
    }

    case DELETE_MEMBER_SUCCESS: {
      const indexOfDelete = state.allmembers.findIndex(
        (member) => member.userId === action.payload
      )
      state.allmembers.splice(indexOfDelete, 1)

      return {
        ...state,
      }
    }

    case UPDATE_WEBSITES_MEMBERS: {
      return {
        ...state,
      }
    }

    default:
      return state
  }
}

export default Members
