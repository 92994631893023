import {
  GENERAL_MESSAGE_SUCCESS,
  GENERAL_MESSAGE_ERROR,
  GENERAL_MESSAGE_CLEAR,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  generalSuccess: false,
  generalError: false,
  generalMessage: '',
}

const GeneralMessage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERAL_MESSAGE_SUCCESS: {
      return {
        ...state,
        generalMessage: action.payload,
        generalSuccess: true,
      }
    }
    case GENERAL_MESSAGE_ERROR: {
      return {
        ...state,
        generalError: true,
        generalMessage: action.payload,
      }
    }
    case GENERAL_MESSAGE_CLEAR: {
      return {
        ...state,
        generalSuccess: false,
        generalError: false,
        generalMessage: '',
      }
    }

    default:
      return state
  }
}

export default GeneralMessage
