import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import { getUserDetails, userSignOut } from '../actions/Auth';

import { EDIT_PROFILE_REQUEST, EDIT_PASSWORD_REQUEST } from '../../constants/ActionTypes';

import { editProfileError, editProfileSuccess, editPasswordError, editPasswordSuccess } from '../actions/EditProfile';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

const updateProfileRequest = async (firstName, lastName, email) =>
  await axios
    .post(`${baseUrl}/admin/edit-profile`, { firstName, lastName, email })
    .then((response) => response)
    .catch((error) => error);

const updatePasswordRequest = async (oldPassword, newPassword) =>
  await axios
    .post(`${baseUrl}/admin/change-password`, { oldPassword, newPassword })
    .then((response) => response)
    .catch((error) => error);

function* updateProfileInfo({ payload }) {
  const { firstName, lastName, email } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateProfileRequest, firstName, lastName, email);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(editProfileError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(editProfileError());
      }
    } else {
      yield put(getUserDetails());
      yield put(editProfileSuccess());
      yield put(generalSuccessMessage('Profile Edited Successfully..., Login again to continue'));
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(generalErrorMessage('Profile Edit Failed...'));
    yield put(editProfileError());
  }
}

function* updatePasswordInfo({ payload }) {
  const { oldPassword, newPassword } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updatePasswordRequest, oldPassword, newPassword);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(editPasswordError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(editPasswordError());
      }
    } else {
      yield put(getUserDetails());
      yield put(editPasswordSuccess());
      yield put(generalSuccessMessage('Password Edited Successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Password Edit Failed...'));
    yield put(editPasswordError());
  }
}

export function* putRequestEditProfile() {
  yield takeEvery(EDIT_PROFILE_REQUEST, updateProfileInfo);
}

export function* putRequestEditPassword() {
  yield takeEvery(EDIT_PASSWORD_REQUEST, updatePasswordInfo);
}

export default function* rootSaga() {
  yield all([fork(putRequestEditProfile), fork(putRequestEditPassword)]);
}
