import {
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_SUCCESS,
  INVITE_MEMBERS_ERROR,
  INVITE_MEMBERS_CLEAR_MESSAGES,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: '',
  data: {},
  success: false,
}

const Invite = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INVITE_MEMBERS_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case INVITE_MEMBERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
      }
    }
    case INVITE_MEMBERS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        error: true,
        loading: false,
      }
    }

    case INVITE_MEMBERS_CLEAR_MESSAGES: {
      return {
        ...state,
        errorMessage: '',
        loading: false,
        error: false,
        success: false,
      }
    }

    default:
      return state
  }
}

export default Invite
