import {
  GET_MEMBERS,
  UPDATE_MEMBER_ROLE,
  GET_MEMBER_SUCCESS,
  MEMBERS_ERROR,
  MEMBERS_CLEAR_MESSAGES,
  ACCESS_MEMBERS_PAGE,
  REJECT_MEMBERS_PAGE,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_WEBSITES_MEMBERS,
} from '../../constants/ActionTypes'

export const getMembersRequest = (accountId) => {
  return {
    type: GET_MEMBERS,
    payload: accountId,
  }
}

export const getMembersSuccess = (data) => {
  return {
    type: GET_MEMBER_SUCCESS,
    payload: data,
  }
}

export const membersError = (message) => {
  return {
    type: MEMBERS_ERROR,
    payload: message,
  }
}

export const updateCallSuccess = () => {
  return {
    type: UPDATE_MEMBER_SUCCESS,
  }
}

export const delete_member = (userId) => {
  return {
    type: DELETE_MEMBER,
    payload: userId,
  }
}

export const update_member_role = (data) => {
  return {
    type: UPDATE_MEMBER_ROLE,
    payload: data,
  }
}

export const delete_member_success = (userId) => {
  return {
    type: DELETE_MEMBER_SUCCESS,
    payload: userId,
  }
}

export const clearMembersMessages = () => {
  return {
    type: MEMBERS_CLEAR_MESSAGES,
  }
}

export const accessMembersPage = (accountId) => {
  return {
    type: ACCESS_MEMBERS_PAGE,
    payload: accountId,
  }
}

export const rejectMembersPage = () => {
  return {
    type: REJECT_MEMBERS_PAGE,
  }
}

export const updateWebsitesMembers = (data) => {
  return {
    type: UPDATE_WEBSITES_MEMBERS,
    payload: data,
  }
}
