/* eslint-disable no-prototype-builtins */
import {
  ENRICH_FETCH_DATA_REQUEST,
  ENRICH_FETCH_DATA_SUCCESS,
  ENRICH_FETCH_DATA_ERROR,
  ENRICH_CLEAR_MESSAGES,
  VERIFY_EMAIL_ENRICH,
  SUCCESS_VERIFY_EMAIL_ENRICH,
  CLEAR_ENRICH,
  VERIFY_EMAIL_ENRICH_POST,
  SUCCESS_VERIFY_EMAIL_ENRICH_POST,
  ERROR_VERIFY_EMAIL_ENRICH_POST,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  verifyLoading: false,
  verifyingContacts: [],
  error: false,
  errorMessage: '',
  completed: false,
  data: [],
};

const enrich = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENRICH_FETCH_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        completed: false,
        data: [],
      };
    }
    case ENRICH_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        completed: true,
      };
    }
    case ENRICH_FETCH_DATA_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        error: true,
        loading: false,
        completed: true,
      };
    }

    case ENRICH_CLEAR_MESSAGES: {
      return {
        ...state,
        errorMessage: '',
        error: false,
        loading: false,
      };
    }

    case VERIFY_EMAIL_ENRICH: {
      return {
        ...state,
        verifyingContacts: [...state.verifyingContacts,action.payload.email],
        verifyLoading: true,
      };
    }
    case VERIFY_EMAIL_ENRICH_POST: {    
      return {
        ...state,
        verifyingContacts: [...state.verifyingContacts,action.payload.contact],
        // verifyLoading: true,
      };
    }

    case SUCCESS_VERIFY_EMAIL_ENRICH: {
      // const emailToFind = action.payload.email;

      // Loop through properties of state.data
      for (const key in state.data) {
        if (state.data.hasOwnProperty(key) && Array.isArray(state.data[key])) {
          const targetArray = state.data[key];          

          // Find the index in the target array
          const indexOfContact = targetArray.findIndex((contact) => contact.businessEmail ==  state.verifyingContacts.find(email=> email == contact?.businessEmail));
          

          // Check if the index is found and update the verifiedStatus
          if (indexOfContact !== -1) {
            
            state.data[key][indexOfContact].verifiedStatus = action.payload.status;
            break; // Exit the loop once the update is done
          }
        }
      }

      return {
        ...state,
        verifyLoading: false,
      };
    }
    case SUCCESS_VERIFY_EMAIL_ENRICH_POST: {
      // const contactId = action.payload.contact;

      // Loop through properties of state.data
      for (const key in state.data) {
        if (state.data.hasOwnProperty(key) && Array.isArray(state.data[key])) {
          const targetArray = state.data[key];          
          // Find the index in the target array
          const indexOfContact = targetArray.findIndex((contact) => contact?._id ==  state.verifyingContacts.find(email=> email == contact?._id));

          // Check if the index is found and update the verifiedStatus
          if (indexOfContact !== -1) {
            state.data[key][indexOfContact].verifiedStatus = action.payload.status;
            state.data[key][indexOfContact].businessEmail = action.payload.email;
            state.data[key][indexOfContact].email = action.payload.email;
            state.verifyingContacts.splice(indexOfContact, 1);
            break; // Exit the loop once the update is done
          }else{
            state.verifyingContacts.splice(indexOfContact, 1);
          }
        }
      }
      return {
        ...state,
        verifyLoading: false,
      };
    }
    case ERROR_VERIFY_EMAIL_ENRICH_POST: {
      for (const key in state.data) {
        if (state.data.hasOwnProperty(key) && Array.isArray(state.data[key])) {
          const targetArray = state.data[key];
          const indexOfContact = targetArray.findIndex((contact) => contact.id ==  state.verifyingContacts.find(email=> email == contact?.id));
          state.verifyingContacts.splice(indexOfContact, 1);                  
        }
      }
      return {
        ...state,
        verifyLoading: false,
      };
    }

    case CLEAR_ENRICH: {
      return {
        ...state,
        loading: false,
        verifyLoading: false,
        verifyingContacts: [],
        error: false,
        errorMessage: '',
        completed: false,
        data: [],
      };
    }

    default:
      return state;
  }
};

export default enrich;
