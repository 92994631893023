import { combineReducers } from 'redux';
import Settings from './Settings';
import EditProfile from './EditProfile';
import Blacklist from './Blacklist';
import Auth from './Auth';
import Leads from './Leads';
import Lead from './Lead';
import Enrich from './Enrich';
import Onboarding from './onboarding';
import Webhooks from './Webhooks';
import List from './List';
import LeadScoring from './LeadScoring';
import CSVfile from './CSVfile';
import Invite from './Invite';
import SelectedWebsite from './SelectedWebsite';
import Websites from './Websites';
import Members from './Members';
import Utilities from './Utilities';
import Tags from './Tags';
import GeneralMessage from './GeneralMessage';
import Comments from './Comments';
import SessionPlayer from './SessionPlayer';
import Hubspot from './Hubspot';
import Salesforce from './Salesforce';
import Zoho from './Zoho';
import Icp from './Icp';
import Gtm from './Gtm';
import Prospector from './Prospector';
import ProspectorCSV from './ProspectorCSV';
import Automation from './Automation';
import Integrations from './Integrations';
import EmailCampaign from './EmailCampaign';
import Contacts from './Contacts';
import Rating from './Rating';
import PersonalizeModal from './PersonalizeModal';
import Prospects from './Prospects';
import Services from './Services';
import Pipedrive from './Pipedrive';
import Slack from './Slack';


export default combineReducers({
  settings: Settings,
  auth: Auth,
  leads: Leads,
  slack: Slack,
  lead: Lead,
  enrich: Enrich,
  list: List,
  leadScoring: LeadScoring,
  onboarding: Onboarding,
  webhooks: Webhooks,
  csvfile: CSVfile,
  invite: Invite,
  selectedWebsite: SelectedWebsite,
  websites: Websites,
  members: Members,
  utilities: Utilities,
  tags: Tags,
  generalMessage: GeneralMessage,
  comments: Comments,
  sessionPlayer: SessionPlayer,
  editProfile: EditProfile,
  blacklist: Blacklist,
  hubspot: Hubspot,
  salesforce: Salesforce,
  zoho: Zoho,
  icp: Icp,
  contacts: Contacts,
  prospector: Prospector,
  automation: Automation,
  prospectorCSV: ProspectorCSV,
  integrations: Integrations,
  campaign: EmailCampaign,
  rating: Rating,
  personalizeModal: PersonalizeModal,
  prospects: Prospects,
  services: Services,
  pipedrive: Pipedrive,
  gtm: Gtm,
 
});
