import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'

import { GET_RECORDED_SESSION } from '../../constants/ActionTypes'
import { userSignOut } from '../actions/Auth'
import {
  getRecordedSessionSuccess,
  getRecordedSessionFailed,
} from '../actions/SessionPlayer'

import { generalErrorMessage } from '../actions/GeneralMessage'

const getRecordedSessionRequest = async (recordingId) => {
  return await axios
    .get(`${baseUrl}/admin/recordings?recordingId=${recordingId}`, {
      recordingId,
    })
    .then((recordings) => recordings)
    .catch((error) => error)
}

function* getRecordedSessionCall({ payload }) {
  const { recordingId } = payload
  setAuthorizationHeader()

  try {
    const records = yield call(getRecordedSessionRequest, recordingId)

    if (records.message) {
      if (records.response) {
        const tokenStatus = isTokenExpired(records.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
        yield put(generalErrorMessage(records.response.data.status))
        yield put(getRecordedSessionFailed())
      } else {
        yield put(
          generalErrorMessage('Something went wrong please check your network')
        )
        yield put(getRecordedSessionFailed())
      }
    } else {
      localStorage.setItem(
        'recordedSession',
        JSON.stringify(records.data.record)
      )
      yield put(getRecordedSessionSuccess(records.data))
    }
  } catch (error) {
    yield put(generalErrorMessage(error))
    yield put(getRecordedSessionFailed())
  }
}

export function* getSessionRecord() {
  yield takeEvery(GET_RECORDED_SESSION, getRecordedSessionCall)
}

export default function* rootSaga() {
  yield all([fork(getSessionRecord)])
}
