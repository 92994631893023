import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  POST_ONBOARDING_DETAILS,
  SEND_DEVELOPER_MAIL,
  SEND_ONBOARDING_CREDENTIALS,
  UPDATE_ACCOUNT_STATUS,
} from '../../constants/ActionTypes';
import { getUserDetails, userSignOut } from '../actions/Auth';
import { generalErrorMessage } from '../actions/GeneralMessage';
import {
  onboardingSuccess,
  onboardingFailed,
  sendDeveloperEmailSuccess,
  sendOnboardingCredentialsSuccess,
} from '../actions/Onboarding';

import { generalSuccessMessage } from '../actions/GeneralMessage';

import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import axios from 'axios';

const postOnboardingDetails = async (firstName, lastName, domain, phone) =>
  await axios
    .post(`${baseUrl}/admin/onboarding`, {
      firstName,
      lastName,
      domain,
      phone,
    })
    .then((successDetails) => successDetails)
    .catch((error) => error);

const postDeveloperEmail = async (developerEmail, senderFullName, developerFirstName, optionalMessage, clientId) =>
  await axios
    .post(`${baseUrl}/admin/onboarding/script`, {
      developerEmail,
      senderFullName,
      developerFirstName,
      optionalMessage,
      clientId,
    })
    .then((success) => success)
    .catch((error) => error);

const postCredentials = async (username, password, url) =>
  await axios
    .post(`${baseUrl}/admin/onboarding/install-code`, {
      username,
      password,
      url,
    })
    .then((success) => success)
    .catch((error) => error);

const putAccountStatus = async (data) =>
  await axios
    .put(`${baseUrl}/admin/account-status`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

function* postOnboarding({ payload }) {
  setAuthorizationHeader();
  const { firstName, lastName, domain, phone } = payload;
  try {
    const onboardingResponse = yield call(postOnboardingDetails, firstName, lastName, domain, phone);

    if (onboardingResponse.message) {
      if (onboardingResponse.response) {
        const tokenStatus = isTokenExpired(onboardingResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(onboardingFailed(onboardingResponse.response.data.status));
        yield put(generalErrorMessage(onboardingResponse.response.data.status));
      } else {
        yield put(onboardingFailed('Something went wrong please check your network'));
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(onboardingSuccess());
    }
  } catch (error) {
    yield put(onboardingFailed(error));
  }
}

function* sendDeveloper({ payload }) {
  setAuthorizationHeader();
  const { developerEmail, senderFullName, developerFirstName, optionalMessage, clientId } = payload;

  try {
    const onboardingResponse = yield call(
      postDeveloperEmail,
      developerEmail,
      senderFullName,
      developerFirstName,
      optionalMessage,
      clientId
    );

    if (onboardingResponse.message) {
      if (onboardingResponse.response) {
        const tokenStatus = isTokenExpired(onboardingResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(onboardingResponse.response.data.status));
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(sendDeveloperEmailSuccess());
    }
  } catch (error) {
    yield put(onboardingFailed(error));
  }
}

function* sendOnboardingCredentials({ payload }) {
  setAuthorizationHeader();
  const { username, password, url } = payload;
  try {
    const onboardingResponse = yield call(postCredentials, username, password, url);

    if (onboardingResponse.message) {
      if (onboardingResponse.response) {
        const tokenStatus = isTokenExpired(onboardingResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(onboardingResponse.response.data.status));
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(sendOnboardingCredentialsSuccess());
    }
  } catch (error) {
    yield put(onboardingFailed(error));
  }
}
function* updateAccountStatusData({ payload }) {
  const { data } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(putAccountStatus, data);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(generalSuccessMessage('Account status updated successfully'));
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

export function* newUserOnboarding() {
  yield takeEvery(POST_ONBOARDING_DETAILS, postOnboarding);
}

export function* sendDeveloperMail() {
  yield takeEvery(SEND_DEVELOPER_MAIL, sendDeveloper);
}

export function* sendOnboardingCred() {
  yield takeEvery(SEND_ONBOARDING_CREDENTIALS, sendOnboardingCredentials);
}

export function* addAccountStatus() {
  yield takeEvery(UPDATE_ACCOUNT_STATUS, updateAccountStatusData);
}

export default function* rootSaga() {
  yield all([fork(newUserOnboarding), fork(sendDeveloperMail), fork(sendOnboardingCred), fork(addAccountStatus)]);
}
